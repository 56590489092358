@import '../../styles/global-settings.scss';

.home {
    // background: white;
    height: calc(100vh - 50px);
    // @include flexprob;
    &__dashboardSection {
        .ant-card-body {
            height: 100%;
            padding: 0px;
            border: 0px;
            @include themify($themes) {
                background: themed('nocilGraphbodyColor') !important;
                border: 1px solid themed('borderDarkColor') !important;
                border-radius: 7px;
                color: themed('headingColor') !important;
         } 
        }
        .dashboard-heading {
            margin-bottom: 20px;
            align-self: flex-start;
        }
    }
    &__userDetails {
        width: 100%;
        .ant-card-body {
            padding: 15px !important;
            display: flex;
            padding-bottom: 0 !important;
            @include themify($themes) {
                background: themed('nocilGraphbodyColor') !important;
                border: 1px solid themed('borderDarkColor') !important;
                border-radius: 7px;
                color: themed('headingColor') !important;
         } 
        }
        .userDetails {
            width: 60%;
            display: flex;
            align-items: center;
            .ant-card-meta {
                align-items: center;
            }
            .ant-avatar-circle {
                height: 80px;
                width: 80px;
            }
            span.ant-avatar.ant-avatar-circle.ant-avatar-image {
                height: 80px;
                width: 80px;
            }
            .ant-card-meta-title {
                font-weight: 500;
                font-size: 14px;
                color: $title-85;
                @include themify($themes) {
                    color: themed('headingColor') !important;
             } 
            }
            .name {
                color: $text;
                @include themify($themes) {
                    color: themed('headingColor') !important;
             }    
            }
            .designantion {
                color: $title-85;
            }
        }
        .currentTime {
            width: 40%;
            .date {
                position: absolute;
                right: 30px;
                .currentDateTimeComponent {
                    display: flex;
                    flex-direction: row !important;
                    align-items: center;
                    gap: 10px;
                    .currentDateTimeComponent__date,
                    .currentDateTimeComponent__time {
                        color: $title-35;
                        text-align: right;
                        font-size: 24px !important;
                        font-weight: 400;
                        letter-spacing: 2px;
                        text-transform: uppercase;
                      
                    }
                }
            }
            .homePageImage {
                width: 100%;
                height: 88%;
                img {
                    width: 100%;
                }
            }
        }
    }

    .header {
        @include flexprob;
        gap: 10px;
        &__title {
            line-height: 48px;
            color: $black-3;
        }
    }
    .subtitle {
        color: $red-6;
        text-align: center;
        line-height: 32px;
        padding-top: 10px;
    }
    .footer__text {
        text-align: center;
        line-height: 48px;
        padding-top: 20px;
        color: $gray-9;
    }
    .dashboard-screen {
        display: flex;
        flex-direction: column;
        align-items: center;

        img {
            width: 30%;
        }
        text {
            margin: 10px 0;
        }
    }
}
#embedded-dashboard {
    width: 100%;
    iframe {
        height: calc(100vh - 62px);
        overflow-y: scroll;
        width: 100%;
        border: none;
    }
}
