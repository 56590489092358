@import '../../../../styles/global-settings.scss';
@import '../../../../styles/colorVariable';

.addQRConfigWrapper {
    height: calc(100vh - 380px);
    overflow-x: auto;
    padding: 20px;
    .ant-select {
        width: 100%;
    }
    .ant-select-selector {
        width: 100%;
        border-radius: 2px;
    }
    .ant-picker {
        width: 100%;
        border-radius: 2px;
    }
    .ant-input {
        width: 100%;
        border-radius: 2px;
    }
    .ant-input-number {
        width: 100%;
        border-radius: 2px;
    }
    &__basicInfo{
        padding: 12px 20px;
        gap: 40px;
    }
    &__configuration{
        padding: 12px 20px;
        gap: 28px;
    }
    &__addButton {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 0px 20px;
        .custom-add-button {
            background-color: #ffffff;
            color: $primary-7;
            border: 1px solid $primary-7;
            @include themify($themes) {
                background: themed('bodyBackgroundColor');  
                border: 1px solid themed('borderDarkColor') !important;  
        }

            &:hover,
            &:focus {
                background-color: white;
                border: 1px solid $primary-7;
                @include themify($themes) {
                    background: themed('bodyBackgroundColor');  
                    border: 1px solid themed('hoverColor') !important;  
            }  
            }

            &:active {
                background-color: $primary-7;
                color: white;
                border: 1px solid $primary-7;
            }

            .addIcon {
                color: $primary-7;
            }
        }
    }
    &__fieldTable {
        min-height: 18vh;
        padding: 0px 20px;
    }
    &__Footer {
        @include flexprob;
        background: $neutral-1;
        width: 100%;
        padding: 12px 24px 0px 24px;
        z-index: 1;
        gap: 8px;
        border-top: 0.5px solid $border;
        @include themify($themes) {
            background-color: themed('bodyBackgroundColor') !important;
            border-top: 1px solid themed('borderDarkColor') !important;    
        }
    }
    &__FooterWrapper {
        width: 110px;
    }

        .deleteicon {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .editable-cell {
            position: relative;
        }

        .editable-cell-value-wrap {
            padding: 5px 12px;
            cursor: pointer;
        }

        .editable-row:hover .editable-cell-value-wrap {
            padding: 4px 11px;
            border: 1px solid #d9d9d9;
            border-radius: 2px;
        }
}

.qrConfigFooter {
    &__footer {
        display: flex;
        padding: 5px 40px;
        justify-content: center;
        border-top: 1px solid $border;
        height: 80px;
    }
   
}
.qrConfigFooter__buttons {
    gap: 20px;
    @include flexprob;
    margin: auto;
}
.charLenItem {
    margin-bottom: 0px !important;
}