@import '../../styles/global-settings.scss';

.ant-card.braboAiq {
    margin: 10px;
    .ant-card-body {
        padding: 0px !important;
        height: calc(100vh - 75px);
    }
    .ant-layout.braboAiq__contentlayout {
        background-color: $neutral-1 !important;
    }
    .ant-layout-sider-children {
        background: $neutral-1 !important;
    }
    ul.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light {
        background: $neutral-1 !important;
    }
    .braboAiq__sider {
        aside.ant-layout-sider.ant-layout-sider-light {
            height: calc(100vh - 75px);
            box-shadow: 0px 2px 8px $border;
        }
        ul.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light {
            border: none !important;
        }
        aside.ant-layout-sider.ant-layout-sider-light.ant-layout-sider-collapsed {
            display: none;
        }
        .sider__title {
            padding: 16px 5px 0 16px;
            display: grid;
            grid-template-columns: 1fr 35%;
        }
        .ant-divider-horizontal {
            margin: 5px 0 15px 0 !important;
        }
    }
    aside.ant-layout-sider.ant-layout-sider-light.expand.brabiaiq {
        width: 300px !important;
        max-width: 300px !important;
        min-width: 300px !important;
        flex: 0 0 300px !important;
    }
    .braboAiq__collapsedExpandIocn {
        font-size: 16px;
        height: 64px;
    }
    .braboAiq__collapsedExpandIocn.ant-btn-text:hover,
    .ant-btn-text:active {
        background-color: transparent !important;
    }
    .sider__settings {
        @include flexprob;
    }
}

.braboAiq__content {
    .braboAiq__background {
        position: relative;
        background-image: url('../../assets/images/bgAIQ.png');
        background-size: cover;
        width: 100%;
        height: calc(100vh - 75px);
        max-height: 100%;
        .braboAiq__data {
            margin-top: 5%;
            @include flexprob;
            flex-direction: column;
        }

        .search {
            width: 95%;
            position: absolute;
            bottom: 8px;
            left: 50%;
            transform: translateX(-50%);
            padding: 0 0 10px 0;
            .ant-input-affix-wrapper.ant-input-affix-wrapper {
                height: 56px !important;
                border: 1px solid $neutral-5;
                border-radius: 25px;
                background-color: $neutral-7 !important;
            }
            span.ant-input-group-wrapper.ant-input-group-wrapper-outlined.ant-input-search {
                width: 100% !important;
            }
        }
    }
}
.braboAiq__text {
    .description {
        max-width: 65%;
        margin: 0 auto;
        color: #0f0e11a6;
    }
}
.cardContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 95%;
    margin: auto;
    gap: 10px;
    padding: 5% 0 0 0;
    .barboAiq__card {
        height: 124px;
        background: $neutral-7;
    }
    .content__item {
        @include flexprob;
        flex-direction: column;
        gap: 5px;
        width: 100%;
        padding: 20px;
    }
}

.sider__conversation {
    padding: 0 10px 10px 10px;
    height: calc(100vh - 140px);
    overflow-y: auto;
    .content__time {
        color: $disabled-text !important;
    }
    .content {
        display: grid;
        grid-template-columns: 1fr 10%;
        padding: 7px;
        cursor: pointer;
        span.anticon.anticon-more.cursor-pointer {
            display: flex;
            justify-content: center;
        }
    }
}
.content.selected {
    background-color: $date-range !important;
    color: $black-2 !important;
    border-radius: 10px;
}

.display-start:hover {
    background-color: #f1f3fc;
    color: black;
    border-radius: 5px;
    cursor: pointer;
}
