@import '../../../../styles/colorVariable';
@import '../../../../styles/global-settings.scss';

.createKpiWrapper {
    gap: 10px;
    padding: 12px;
    &__headerWrapper {
        padding: 20px;
        border-bottom: 0.5px solid $border;
        @include themify($themes) {
            background-color: themed('bodyBackgroundColor') !important; 
            border-bottom: 1px solid themed('borderDarkColor') !important; 
        }
    }
    &__createRolesList {
        padding-top: 12px;
        height: calc(100vh - 367px);
    }
    &__createRoleContent2 {
        padding: 20px;
    }

    &__heading {
        display: flex;
        align-items: center;
        height: 100%;
    }

    &__headingName {
        @include textfontstyle;
        color: $title-85;
        @include themify($themes) {
            color: themed('headingColor') !important; 
        }
    }

    &__backIcon {
        width: 30px;
        height: 32px;
        padding: 5px 0 0 8px;
        cursor: pointer;
        svg {
            @include themify($themes) {
                color: themed('svgColor') !important;
                stroke: themed('svgStrokeColor') !important;
            }      
        }
        path {
            @include themify($themes) {
                fill: themed('svgColor') !important;
            }
        } 
    }

    &__createRowHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 8px 20px 10px 20px;
    }

    .ant-collapse > .ant-collapse-item {
        margin: 10px 0px;
        background-color: rgba(0, 0, 0, 0.02);
        border: 1px solid #d9d9d9;

        border-radius: 8px;
    }

    .ant-collapse {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        color: rgba(0, 0, 0, 0.88);
        font-size: 14px;
        list-style: none;
        background-color: white !important;
        border: 0px !important;
        border-bottom: 0 !important;
        border-radius: 0px !important;
        box-shadow: none !important;
    }

    &__button {
        .ant-btn {
            padding: 0px;
        }
        width: 131px;
        height: 32px;
    }

    &__search {
        width: 300px;
        height: 40px;
        background: $gray-3;
        border: none;
        border-radius: 8px;
        input.ant-input {
            background: $gray-3;
        }
        span {
            color: $title-45;
        }
    }
    &__createRolesContent {
        display: flex;
        width: 100%;
        min-height: calc(100vh - 417px);
    }
    &__createRolesDropdownContent {
        width: 30%;
        border-right: 0.5px solid $border;
        padding: 10px 30px 10px 30px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        position: fixed;
    }
    &__createRolesTableContent {
        width: 70%;
        padding: 10px 30px 10px 30px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-left: auto;
    }
    &__createRolesDropdownCollapse {
        display: flex;
        flex-direction: column;
        gap: 10px;
        overflow-y: scroll;
        height: 430px;
    }
    &__buttonGroup {
        border: 1px solid $primary-4;
        color: $primary-4;
        border-radius: 4px;
        width: 100%;
    }
    &__buttonGroupIcon {
        color: $primary-4;
        size: 12px;
    }

    &__createRolesTableHeader {
        color: $title-45;
        font-weight: bold;
        display: flex;
    }
    &__createRolesSwitch {
        width: 28px;
    }
    &__createRolesTableContentRow {
        width: 100%;
    }
    &__createRolesTypographySwitch {
        width: 50%;
        text-align: center;
    }
    &__createRolesTypography {
        width: 100%;
        text-align: left;
    }
    &__createRolesSwitch {
        width: 50%;
        align-items: center;
    }
    &__createRoleFooter {
        @include flexprob;
        background: $neutral-1;
        width: 100%;
        padding: 12px 24px 16px 24px;
        z-index: 1;
        gap: 8px;
        border-top: 0.5px solid $border;
        @include themify($themes) {
            background: themed('bodyBackgroundColor');  
            border-top: 1px solid themed('borderDarkColor') !important;  
    } 
    }
    &__footerButtonWrapper {
        width: 110px;
    }
    &__footerButtonWrapper-formulaStep {
        display: flex;
        gap: 8px;
        .draftSave {
            background-color: transparent;
            border: 1px solid $button-outline;
            color: $button-outline;
        }
    }
    &__createRoleList {
        background-color: $neutral-7;
        padding: 0px 12px;
        .ant-list-item {
            padding: 12px 0px;
        }
    }
    &__createRoleContent3 {
        @include flexprob;
        flex-direction: column;
        height: 100%;
        width: 80%;
        gap: 10px;
    }
    &__createRoleInnerContent {
        display: flex;
        width: 40%;
        gap: 8px;
        margin: 10px;
        flex-direction: column;
    }
    &__createRoleInnerContentText {
        width: 70%;
    }
    &__createRoleInnerContentInput {
        border-radius: 2px !important;
    }
    &__scrollContent {
        height: calc(100vh - 365px);
        overflow-y: scroll;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &__scrollContentFormula {
        height: calc(100vh - 378px);
        padding: 12px;
        @include themify($themes) {
            background-color: themed('bodyBackgroundColor') !important; 
        }
    }
    &__saveButton {
        margin-bottom: 0px;
        width: 100% !important;
    }
}

.createKpiWrapper__header {
    display: grid;
    grid-template-columns: 1fr 133px 124px;
    grid-column-gap: 8px;
    align-items: center;
}

.mandatoryClass {
    color: $red-3;
}

.kpiFormWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    &__content__wrapper {
        width: 100% !important;
        @include themify($themes) {
            background-color: themed('bodyBackgroundColor') !important; 
        }
    }

    &__form {
        width: 100% !important;
        .NameInputBox {
            .ant-form-item-control-input {
                width: 80% !important;
            }
        }

        .ant-select.ant-select-in-form-item {
            width: 80% !important;
        }
        .DescriptionBox {
            .ant-form-item-control-input {
                width: 90% !important;
            }
        }

        .ant-select-dropdown {
            width: 237px !important;
        }
    }

    &__imageUpload {
        @include flexprob;
        flex-direction: column;
        color: $disabled-text;
        gap: 8px;
        margin: 20px 0px;
    }
    &__wrapper {
        width: 70% !important;
        margin: auto;
        padding: 20px 0px 10px 0px;
    }
}
