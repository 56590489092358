@import '../../../../styles/colorVariable';
@import '../../../../styles/global-settings.scss';

.ant-table-thead > tr > th {
    font-size: 15px;
}

.datVisualizationDataTable {
    /* Customize scroll bars */
    overflow: hidden; /* Hide default scroll bars */
    .customPaginationStatic {
        position: relative;
        bottom: 6px;
    }
}

.datVisualizationDataTable .ant-table-body {
    overflow: auto; /* Enable scrolling */
    max-height: 400px; /* Example: Set max height for vertical scrolling */
}

.datVisualizationDataTable .ant-table-body::-webkit-scrollbar {
    width: 0; /* Hide scrollbar for WebKit browsers */
}

.datVisualizationDataTable .ant-table-body {
    scrollbar-width: none; /* Hide scrollbar for Firefox */
}

.dataTable__pagination {
    background: $neutral-1;
    box-shadow: 0px -11px 24px $box-shadow-neutral;
    width: 100%;
    padding: 12px 24px 16px 24px;
    z-index: 1;
    text-align: end;
    position: relative;

    @include themify($themes) {
        background: themed('bodyBackgroundColor') !important;
    }
    .ant-pagination {
        display: block !important;
    }
}
