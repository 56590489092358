@import '../../../../styles/colorVariable';
@import '../../../../styles/global-settings.scss';
.assetsGroupLeftSection {
    height: 100%;
    .ant-card {
        height: 100%;
        margin: auto;
        border-radius: 0%;
        margin-right: 2px;
    }
    .ant-card-body {
        height: 100%;
        padding: 0px;
        height: 100%;
    }
    .ant-tree {
        @include themify($themes) {
            background: themed('bodyCardBackgroundColor') !important;
            color: themed('headingColor') !important;
            border: 1px solid themed('borderDarkColor') !important;
        }
        .ant-tree-node-selected {
            @include themify($themes) {
                background: themed('bodyCardBackgroundColor') !important;
            }
        }
    }
    svg {
        @include themify($themes) {
            stroke: themed('svgColor') !important;
            color: themed('svgColor') !important;
        }      
    }
    path {
        @include themify($themes) {
            color: themed('svgColor') !important;
        }
    }
    &__search {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 10px 25px 12px;
        align-items: center;
        svg {
            @include themify($themes) {
                color: themed('svgColor') !important;
            
            }
        }
        path {
            @include themify($themes) {
                fill: themed('svgColor') !important;
            }
        }
    }
    &__searchHeading {
        font-size: 16px;
        font-weight: 400;
    }
    &__notFound {
        margin-left: 5px;
    }
    &__searchBox {
        height: 32px;
        background-color: $neutral-6;
        border-color: $neutral-6;
        svg {
            @include themify($themes) {
                color: themed('svgColor') !important;
            
            }
        }
        path {
            @include themify($themes) {
                fill: themed('svgColor') !important;
            }
        }
        .ant-input {
            background-color: $neutral-6;
            height: 22px;
            bottom: 8px;
            width: 80%;
        }
        .ant-input-prefix svg {
            color: black;
            height: 16px;
            width: 16px;
            color: $disabled-text;
        }
    }
    .ant-input-affix-wrapper {
        width: 90%;
        margin: 10px 16px;
    }
    .ant-divider {
        margin: 0;
    }
    &__selectModel {
        padding: 12px 16px;
    }
    &__tree {
        padding: 0 12px;
        height: 120px;
        max-height: 120px;
        overflow-y: scroll;
    }
    &__searchBoxCloseIcon {
        cursor: pointer;
    }
}
.assetsNode {
    margin-right: 5px;
    vertical-align: middle;
}
