@import '../../../../styles/global-settings.scss';
.adapter.ant-select-dropdown {
    width: 29% !important;
}

.adapterList__content.addMapping {
    overflow-y: scroll;
    height: calc(100vh - 400px);
}
.adapterList__footer.addMapping {
    padding: 25px 40px;
}
.mappingFieldsTable th.ant-table-cell:last-child {
    background-color: rgba(240, 244, 255, 1);
    border-left: 1px solid rgba(217, 217, 217, 1);
    @include themify($themes) {
        background-color: themed('tableHeader') !important;
    }
}
.mappingFieldsTable td.ant-table-cell:last-child {
    border-left: 1px solid rgba(217, 217, 217, 1);
}
.testMapping-btn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
}
input#braboTableName {
    width: 320px;
}
.clearAll {
    justify-content: end;
    align-items: center;
    display: flex;
    .ant-btn {
        color: red;
        border: 1px solid red;
    }
}

.testMapping {
    display: flex;
    justify-content: center;
}
.testMappingContainer {
    padding: 50px;
}
input.ant-input.fieldInput {
    border: none !important;
}
.fieldInput {
    padding: 0px !important;
}
input.ant-input.fieldOutput {
    border: none !important;
}
.fieldOutput {
    padding: 0px !important;
    background-color: white !important;
    color: black !important;
}

.mappedOutputField thead.ant-table-thead th.ant-table-cell {
    background: rgba(240, 244, 255, 1);
    @include themify($themes) {
        background-color: themed('bodyBackgroundColor') !important;
    }
}

.mappedOutputField,
thead {
    border: 1px solid rgba(217, 217, 217, 1);
}

.braboTableOption .ant-select-item-option-content {
    display: flex;
    flex-direction: column !important;
    justify-content: flex-start !important;
    align-items: flex-start !important;
}
.viewEdit {
    display: flex;
    justify-content: flex-end;
}

.mappingStep.braboTableName {
    .ant-select-arrow {
        display: none !important;
    }
}
.addNewButton{
    width: 90% !important;
}