@import '../../../styles/global-settings.scss';

.countAnalyticsWrapper {
    display: flex;
    flex-wrap: nowrap;
    gap: 12px;
    justify-content: flex-end;
    width: 330px;
    float: right;
    .countAnalytics {
        border-right: 1px solid rgba(0, 0, 0, 0.1);
        @include themify($themes) {
            border-right: 1px solid themed('borderDarkColor') !important;
          }
        .countAnalytics__deactiveUsers {
            span {
                @include textfontstyle;
                color: $title-45;
                @include themify($themes) {
                    color: themed('textColor')!important;
                  }
            }
        }
        &__activeUsers {
            width: 160px;
            @extend .countAnalytics__deactiveUsers;
           
        }
        &__activeCustomUsers {
            min-width: 120px;
            @extend .countAnalytics__deactiveUsers;
        }
        .countAnalytics__countInfo {
            @include flexprob;
            gap: 5px;
            span {
                @include textfontstyle;
                color: $title-85;
                @include themify($themes) {
                    color: themed('headingColor')!important;
                  }
            }
        }
    }
    :last-child {
        border-right: none;
        @include themify($themes) {
            border-right: none !important;
          }
    }
    span.calendarCountAnalytics {
        display: block;
        width: 100%;
        font-size: 14px;
    }
}
.calendarCountAnalytics.countAnalytics__countInfo {
    justify-content: flex-end !important;
    padding-right: 10px;
}
.implementationCountAnalytics.countAnalytics__countInfo {
    justify-content: flex-end !important;
    padding-right: 10px;
}
