@import '../../styles/global-settings.scss';
@import '../../styles/colorVariable';

.dynamicForm {
    .dynamicFormWrapperScrolContent {
        height: calc(100vh - 165px);
        display: flex;
        justify-content: center;
        @include themify($themes) {
            border-top: 1px solid themed('borderDarkColor') !important;
        }
    }
    .dynamicFormWrapper {
        height: calc(100vh - 196px);
        width: 100%;
        background: $neutral-1;
        @include fontTextStyle;
        @include themify($themes) {
            background: themed('bacgroundBlack');
        }
        &__cardContent {
            overflow-y: scroll;
            height: 100%;
            width: 100%;
            @include themify($themes) {
                background: themed('bodyBackgroundColor');
            }
        }

        .ant-btn-default:disabled {
            @include themify($themes) {
                background: themed('disableButtonNocil') !important;
                color: themed('disableButtonNocil') !important;
                border-color: themed('disableButtonNocil') !important;
                border: 1px solid themed('disableButtonNocil') !important;
            }
        }
    }
}

.formWrapper {
    .card.card-body.bg-light.mb-3 {
        background-color: transparent !important;
        border: 0px;
    }
    @include themify($themes) {
        background: themed('bodyBackgroundColor') !important;
        border-color: themed('borderDarkColor') !important;
        color: themed('headingColor') !important;
        input {
            background: themed('inputColor') !important;
            border-color: themed('borderDarkColor') !important;
            color: themed('headingColor') !important;
        }
        select {
            background: themed('inputColor') !important;
            border-color: themed('borderDarkColor') !important;
            color: themed('headingColor') !important;

            .dropdown,
            option {
                background: themed('inputColor') !important;
                border-color: themed('borderDarkColor') !important;
                color: themed('headingColor') !important;
            }
        }
    }
}

.buttonWrapper {
    @include flexprob;
    background: $neutral-1;
    width: 100%;
    padding: 12px 24px 16px 24px;
    z-index: 1;
    gap: 8px;
    border-top: 0.5px solid $border;
    @include themify($themes) {
        background-color: themed('bodyBackgroundColor') !important;
        border-color: themed('borderDarkColor') !important;
        border-top: 1px solid themed('borderDarkColor') !important;
    }

    .card.card-body.bg-light.mb-3 {
        background-color: transparent !important;
        border: 0px;
        align-items: center;
        div {
            display: inline;
            justify-content: center;
            align-items: center;
        }
    }
}
.footerButtonWrapper {
    width: 200px;
}

.formio-error-wrapper {
    color: #721c24;
    background-color: unset;
    border-color: #f5c6cb;
    position: static;
    margin-top: 10px;
}

.radio.form-check-inline {
    margin: 10px 0px;
    background-color: rgba(0, 0, 0, 0.02);
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    padding: 10px;
    width: 240px;
    cursor: pointer;
}

.form-radio.radio {
    grid-column-gap: 20px;
    grid-row-gap: 10px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    overflow-y: scroll;
}

.reasonCodeWrapper {
    .card.card-body.bg-light.mb-3 {
        background-color: transparent !important;
        border: 0px;
    }
}

.form__heading {
    display: flex;
    width: 100%;
    padding: 15px;
}

.form__backIcon {
    width: 30px;
    height: 32px;
    padding: 5px 0 0 8px;
    cursor: pointer;
    svg {
        @include themify($themes) {
            color: themed('svgColor') !important;
            stroke: themed('svgColor') !important;
        }
    }
    path {
        @include themify($themes) {
            fill: themed('svgColor') !important;
        }
    }
}
.form__headingName {
    @include textfontstyle;
    color: $title-85;
    @include themify($themes) {
        color: themed('headingColor') !important;
    }
}
.form__headingLabel {
    width: 90%;
    display: flex;
}

.form__cardDiv {
    width: 200px;
    background-color: rgba(0, 0, 0, 0.02);
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    padding: 10px;
    @include themify($themes) {
        background-color: themed('bodyBackgroundColor') !important;
        border-color: themed('borderDarkColor') !important;
        color: themed('headingColor') !important;
    }
}

.form__wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}
