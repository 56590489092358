@import '../../../../styles/global-settings.scss';

.steamCoalWrapper {
    width: 100%;
    height: 120px;
    font-weight: 500;
    .ant-card-body {
        padding: 0px !important;
        width: 100%;
        height: 100%;
        @include flexprob;
    }
    .steamCoalWrapper-card {
        margin: 15px 20px 15px 20px;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .ant-divider {
            margin: 0px;
        }
        .steamGenerationWrapper {
            height: 50%;

            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            &__valueUnit {
                display: flex;
                width: 100%;
                gap: 5px;
                @include themify($themes) {
                    color: themed('headingColor') !important;
                }
            }
            &__value,
            &__unit {
                color: $black;
                font-family: Inter;
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                line-height: 24px;
                @include themify($themes) {
                    color: themed('headingColor') !important;
                }
            }
            &__labelWithVariance {
                display: flex;
                width: 100%;
                justify-content: flex-start;
                align-items: center;
                gap: 12px;
            }
            &__label {
                color: $title-85;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
                letter-spacing: 0.84px;
                @include themify($themes) {
                    color: themed('headingColor') !important;
                }
            }
            &__greenpercentage {
                color: $green;
                font-size: 16px;
                font-weight: 400;
            }
            &__redpercentage {
                color: $red-4;
                font-size: 16px;
                font-weight: 400;
            }
            &__greenpercentage,
            &__redpercentage {
                margin-left: 5px;
            }
            &__image {
                @include flexprob;
                height: 35px;
            }
        }
        .coalConsumptionWrapper {
            height: 50%;

            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            &__valueUnit {
                display: flex;
                width: 100%;
                gap: 5px;
            }
            &__label {
                display: flex;
                width: 100%;
                color: $title-85;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
                letter-spacing: 0.84px;
                @include themify($themes) {
                    color: themed('headingColor') !important;
                }
            }
            &__value,
            &__unit {
                color: $black;
                font-family: Inter;
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                line-height: 24px;
                @include themify($themes) {
                    color: themed('headingColor') !important;
                }
            }
        }
    }
}
