@import '../../../../styles/colorVariable';
@import '../../../../styles/mixins';
@import '../../../../styles/global-settings.scss';

.myPreferencesComponent {
    .theme-dark & {
        padding: 0;
        background-color: $black-8;
    }
    padding: 12px;
    .ant-card-body {
        @include themify($themes) {
            background: themed('bacgroundBlack');
        }
        .preferenceContainer {
            .ant-card-body {
                @include themify($themes) {
                    background: themed('bodyCardBackgroundColor');
                    border-radius: 0 !important;
                }
            }
            &__heading {
                padding: 24px;
                border-bottom: 1px solid $divider;
            }
            &__content {
                min-height: 802px;
                width: 100%;
                &__child {
                    max-width: 936px;
                    margin: 0 auto;
                    margin-top: 55px;
                }
                &__items {
                    min-height: 234px;

                    .contentCardOne {
                        padding: 24px;
                        border: 1px solid $neutral-5 !important;
                        @include themify($themes) {
                            border-color: themed('borderCardColor') !important;
                        }
                        border-radius: 12px;
                        .ant-card-body {
                            display: flex;

                            justify-content: flex-start;
                        }
                        &__header {
                            display: flex;
                            justify-content: flex-start;
                            flex-direction: column;
                            width: 50%;
                            span:nth-child(1) {
                                font-weight: 400;
                                font-size: 18px;
                                line-height: 22px;
                                color: $title-85;
                                @include themify($themes) {
                                    color: themed('headingColor') !important;
                                }
                            }
                            span:nth-child(2) {
                                font-weight: 400;
                                font-size: 14px;
                                line-height: 22px;
                                color: $title-65;
                                @include themify($themes) {
                                    color: themed('textColor') !important;
                                }
                            }
                        }
                        &__fields {
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                            width: 100%;
                            margin-top: 7px;
                        }
                        @include themify($themes) {
                            background: themed('bodyCardBackgroundColor');
                        }
                    }
                    .contentCardTwo {
                        padding: 24px;
                        border: 1px solid $neutral-5;
                        @include themify($themes) {
                            border-color: themed('borderCardColor') !important;
                            border: 1px solid themed('borderCardColor') !important;
                        }
                        border-radius: 12px;
                        &__header {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            height: 32px;
                            &__title {
                                font-family: 'Inter';
                                font-style: normal;
                                font-weight: 400;
                                font-size: 18px;
                                line-height: 22px;
                                color: $title-85;
                            }
                            div:nth-child(1) {
                                width: 58px;
                            }
                        }
                        &__heading {
                            font-weight: 400;
                            font-size: 18px;
                            line-height: 22px;
                            color: $title-85;
                            @include themify($themes) {
                                color: themed('headingColor');
                            }
                        }
                        &__fields {
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                            width: 100%;
                            margin-top: 7px;
                            &__details {
                                height: 100%;
                                display: grid;
                                grid-template-columns: repeat(3, 1fr);
                                width: 100%;
                                gap: 24px;
                                .ant-form-item {
                                    width: 100%;
                                    max-width: 280px;
                                    .ant-row {
                                        width: 100%;
                                        display: flex;
                                        flex-direction: column;
                                        align-items: flex-start;
                                        .ant-col:nth-child(1) {
                                            label {
                                                span {
                                                    font-family: 'Inter';
                                                    font-style: normal;
                                                    font-weight: 400;
                                                    font-size: 14px;
                                                    line-height: 22px;
                                                    color: $title-85;
                                                    @include themify($themes) {
                                                        color: themed(
                                                            'headingColor'
                                                        );
                                                    }
                                                }
                                            }
                                        }
                                        .ant-col:nth-child(2) {
                                            width: 100%;

                                            span {
                                                font-family: 'Inter';
                                                font-style: normal;
                                                font-weight: 400;
                                                font-size: 14px;
                                                line-height: 22px;
                                                display: flex;
                                                align-items: center;
                                                color: $title-45;
                                                @include themify($themes) {
                                                    color: themed('textColor');
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        @include themify($themes) {
                            background: themed('bodyCardBackgroundColor');
                        }
                    }
                }
                &__items:nth-child(2) {
                    margin-top: 24px;
                }
            }
            @include themify($themes) {
                background: themed('bodyBackgroundColor');
            }

            .ant-select-selection-item {
                @include themify($themes) {
                    color: themed('headingColor') !important;
                }
            }
            .ant-select-selector {
                @include themify($themes) {
                    background: themed('inputColor') !important;
                    border-color: themed('borderDarkColor') !important;
                }
            }
        }
    }
    .ant-card-bordered {
        .theme-dark & {
            border: 0 !important;
        }
    }
}
