@import '../../../../styles/global-settings.scss';

.steamPressureWrapper {
    height: 187px;
    width: 100%;
    margin-bottom: 16px;
    font-weight: 500;
    &__div {
        width: 100%;
        height: 100%;
    }
    .ant-card-body {
        height: 100%;
    }
    &__value {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }
    &__column {
        display: flex;
        align-items: center;
    }
    &__name {
        color: $title-85;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0.96px;
        @include themify($themes) {
            color: themed('headingColor') !important;
        }
    }
    &__degree {
        color: $title-45;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0.72px;
        @include themify($themes) {
            color: themed('headingColor') !important;
        }
    }
    &__num {
        color: $black;
        font-size: 18px;
        font-weight: 600;
        @include themify($themes) {
            color: themed('headingColor') !important;
        }
    }
    &__val {
        color: $title-45;
        font-size: 12px;
        font-weight: 500;
        @include themify($themes) {
            color: themed('headingColor') !important;
        }
    }
    &__charts {
        width: 100%;
        height: 100%;
    }
    &__loader {
        @include flexprob;
        height: 100%;
    }
}
