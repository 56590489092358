@import '../../styles/global-settings.scss';
@import '../../styles/fontStyles';
@import '../../styles/mixins';

.uomConfigurator {
    width: 100%;
    &__headerWrapper {
        padding: 20px 5px 20px 24px;
        background: $neutral-1;
        @include themify($themes) {
            background: themed('bodyBackgroundColor') !important;
            border-bottom: 1px solid themed('borderDarkColor') !important;
        }
    }
    &__content {
        padding: 20px 15px 20px 15px;
        height: calc(100vh - 160px);
        overflow: hidden;
        @include themify($themes) {
            background: themed('bacgroundBlack') !important;
            border: 1px solid themed('borderDarkColor') !important;
        }
    }
    &__data {
        height: calc(100vh - 180px);
        padding: 12px;
        @include themify($themes) {
            background: themed('bodyBackgroundColor') !important;;
        }
    }
    &__card {
        .ant-card-body {
            padding: 0px !important;
        }
    }
    &__tableWrapper {
        padding: 25px;
        .customPagination ul.ant-pagination {
            width: 80% !important;
            left: 20% !important;
        }
    }
    &__tableHeaderWrapper {
        display: flex;
        justify-content: space-between;
    }
    &__tableEmpty {
        align-items: center;
        display: flex;
        gap: 10px;
        flex-direction: column;
        justify-content: center;
        height: 100%;
    }
}

.reasonCodeWrapper__downTimeListPagination {
    padding-top: 12px;
}
.reasonCodeWrapper__downTimeList {
    padding-top: 12px;
    height: calc(100vh - 305px);
    overflow-y: scroll;
}
