* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
body,
h1,
h2,
h2,
h4,
h5,
h6,
p,
span,
label,
input,
a {
    font-family: 'Inter', sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    ::placeholder {
        font-family: 'Inter', sans-serif !important;
    }
}
body {
    overflow: hidden;
}

/* width */
::-webkit-scrollbar {
    width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px white;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.1);
    border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.1);
}
.highcharts-credits {
    display: none;
}

.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled) {
    font-weight: 400 !important;
    background-color: transparent !important;
}
