@import '../../../../../styles/global-settings.scss';
.operatorContainer {
    display: flex;
    gap: 8px;
    justify-content: center;
    &__type {
        background-color: rgba(0, 0, 0, 0.02);
        padding: 0px;
        @include flexprob;
        border-radius: 2px;
        button {
            padding: 0px 8px;
            font-size: 14px;
            font-weight: 500;
            line-height: 22px;
            cursor: pointer;
            border: none;
            background-color: transparent;
            @include themify($themes) {
                background: themed('formulaButtonOperator') !important;
                color: themed('headingColor') !important;
            }
        }
    }
}
