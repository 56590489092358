.customPopOverContent {
    width: 100%;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    .ant-input-affix-wrapper {
        height: auto;
        margin: 16px;
        border: 1px solid #d9d9d9;
        width: 88%;
        textarea {
            width: 205px;
            min-height: 96px;
            padding: 0px !important;
        }
    }
    &__footerButtons {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 64px;
        gap: 16px;
    }
    .ant-input-clear-icon {
        top: 2px !important;
    }
}
