@import '../../styles/global-settings.scss';

.tabsSection {
    background-color: #ffffff;
    box-sizing: border-box;
    margin-top: 12px;
    @include themify($themes) {
        background: themed('bodyBackgroundColor') !important;
        border-top: 1px solid themed('borderDarkColor') !important;
    }
}

.implementationEmptyDataComponent {
    @include flexprob;
    height: 100%;
    flex-direction: column;
    gap: 32px;
}

.implementation_loader {
    height: 300px;
    width: 100%;
   @include flexprob
}
.unassignedTableEmptyDataComponent {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh  - 300px)
}
.ant-table-thead >tr>th{
    font-size: 14px;
}

.assignedAttribute__searchBox{
    .ant-input-affix-wrapper{
        @include themify($themes) {
            background: themed('bodyBackgroundColor') !important;
            border:1px solid themed('borderDarkColor') !important;
            }
    }
    svg {
        @include themify($themes) {
            color: themed('svgColor') !important;
            stroke: themed('svgColor') !important;
        }      
    }
    path {
        @include themify($themes) {
            fill: themed('svgColor') !important;
        }
    }
}



