@import '../../../../styles/colorVariable';
@import '../../../../styles/global-settings.scss';

.validateModal{
   
    .ant-modal-content {
        padding: 0 !important;
    }
    &__title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    &__editBtn{
        margin-right: 24px;
    }
    .ant-modal-header {
        border: 1px solid neutral-4;
        border-color: neutral-4;
        padding: 16px 24px 0px;
        @include themify($themes) {
            background: themed('bodyBackgroundColor');
        }
    }

    .ant-modal-footer {
        padding: 16px 24px;
        border: 1px solid neutral-4;
        border-color: neutral-4;
        @include flexprob;
        border-radius: 10px;
        .customButton {
            width: 20%;
        }
    }
    .ant-modal-body {
        padding: 24px;
    }
    .tagValue__title {
        padding: 24px;
        button {
            margin-right: 10px;
        }
    }
    p {
        margin-bottom: 10px;
    }
    .ant-modal-body {
        padding: 0 !important;
    }
    .ant-divider {
        margin: 0;
    }
    .ant-select-arrow {
        cursor: pointer !important;
        pointer-events: unset !important;
    }
    .ant-select.ant-select-in-form-item {
        width: 192px;
    }
    .startTrigger {
        .ant-form-item {
            margin-bottom: 0;
        }
    }
    &__innerCard {
        border-radius: 1px;
        border: 1px solid $neutral-5;
        .ant-card-body {
            padding: 12px !important;
            @include themify($themes) {
                background: themed('bodyBackgroundColor');
            }
        }
    }
    &__selectDropdown {
        .ant-select-selector {
            border-radius: 1px;
        }
    }
    &__footer {
        padding-top: 6%;
    }
    &__footerBtns {
        display: flex;
        flex-direction: row;
        justify-content: center;
        col {
            margin-right: 10px;
        }
        padding: 20px 0px;
    }
    &__cancelBtn {
        padding-right: 4px;
    }
    &__saveBtn {
        padding-left: 4px;
    }
    &__inputBox {
        border-radius: 1px;
        width: 192px;
    }
    &__triggerHeading{
        font-weight: 500;
        font-size: 14px;
        padding-bottom: 4px;
        @include themify($themes) {
            color: themed('headingColor');
        }
    }
}
.upperRow {
    padding: 12px 20px;
}
.viewModal {
    padding-bottom: 8%;
    padding-top: 1%;
}
.validationDetails {
    padding-top: 2%;
}

.validateModal.timeCapsule{
 width: 65% !important;
}