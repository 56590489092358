@import '../../../styles/colorVariable';
@import '../../../styles/global-settings.scss';

.provider__container {
    margin: 0 10px;
    &__backIcon {
        cursor: pointer;
    }

    .circle.normal-circle {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        svg {
            margin-right: 10px;
        }
        .text {
            margin-bottom: 4px;
        }
        img {
            width: 32px;
            height: 32px;
            margin-right: 10px;
        }
    }

    .provider__card {
        height: 250px;
        padding: 24px;
        @include themify($themes) {
            background: themed('bodyBackgroundColor') !important;
            color: themed('headingColor') !important;
        }
        .desc{
height: 30px;
max-height: 30px;
        }
    }
    .provider__switch {
        margin-top: 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
       
}
    .provider__createConfigBtn {
        margin-top: 40px;
        text-align: right;
        cursor: pointer;
        button {
            background: transparent;
            color: $primary-4;
            box-shadow: none;
        }
        :hover {
            background-color: transparent !important;
            color: $primary-4 !important;
        }
        .create-config{
           margin-top: 12px;
        }
    }
    .ant-spin-spinning {
        @include flexprob;
        height: auto;
        margin: 30% auto;
    }
}
