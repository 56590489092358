@import '../../../../styles/global-settings.scss';

.formulaCreatorModal {
    position: fixed;
    max-width: 100vw;
    .ant-modal-content {
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 0px 0px !important;
        .ant-modal-body {
            max-height: calc(100% - 160px);
            height: calc(100% - 160px);
        }
        .ant-modal-header {
            border-radius: 12px 12px 0px 0px;
            background: var(--Neutral-1, #fff);
            box-shadow: 0px -1px 0px 0px #f0f0f0 inset;
            margin-bottom: 0px;
            height: 70px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .ant-modal-title {
                margin-left: 24px;
                height: 70px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
            }
            @include themify($themes) {
                background: themed('bodyBackgroundColor');
                border-color: themed('borderDarkColor') !important;
                border:1px solid themed('borderDarkColor') !important;
                box-shadow: unset!important;
            }
        }
        .ant-modal-footer {
            @include flexprob;
            margin-top: 0;
        }
    }
    &__content {
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    &__content-rightOperators {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        gap: 8px;
        position: relative;
        .clear-all {
            position: absolute;
            right: 0;
            @include flexprob;
            background-color: transparent;
            border: none;
            cursor: pointer;
            &__text {
                color: $red-5;
                padding: 1px;
            }
        }
    }
    &__content-primary {
        height: 90%;
        padding-bottom: 12px;
    }
    &__content-primaryMain {
        margin: 20px 24px 10px 24px;
        height: 100%;
        display: flex;
        gap: 20px;
    }
    &__content-left {
        width: 20%;
        display: flex;
        min-width: 230px;
        flex-direction: column;
        gap: 8px;
        height: 100%;
        .ant-select {
            width: 100% !important;
        }
        .ant-input-affix-wrapper {
            padding: 4px;
        }
    }
    &__content-right {
        width: calc(100% - 254px);
        display: flex;
        flex-direction: column;
    }
    &__content-rightInput {
        border-radius: 8px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        height: 70%;
        background: #fcfcfd;
        width: 100%;
        @include flexprob;
        @include themify($themes) {
            background: themed('bodyCardBackgroundColor');
        }
    }
    &__content-rightPreview {
        height: 20%;
        background-color: transparent;
        margin-top: 1%;
        width: 100%;
        display: flex;
        overflow-y: scroll;
        .rightPreviewContent {
            padding: 1%;
            height: 100%;
            background-color: aliceblue;
            width: 100%;
            span {
                word-wrap: break-word;
            }
            @include themify($themes) {
                background: themed('menuBackground') !important;
                color: themed('headingColor') !important;
         }
        }
       
    }
    &__content-secondary {
        border-top: solid 1px rgba(0, 0, 0, 0.2);
        margin-top: 20px;
    }
    &__content-secondaryMain {
        padding: 13px 24px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 12px;
        .ant-select {
            width: 218px;
        }
    }
    &__content-frequencyTitle {
        line-height: 22px;
        font-weight: 500;
        @include themify($themes) {
            color: themed('headingColor') !important;
     }
    }

    &__footer {
        height: 90px;
        width: 100%;
        border-top: 1px solid rgba(240, 240, 240, 1);
        @include flexprob;
        gap: 8px;
        .draftSave {
            background-color: transparent;
            border: 1px solid $button-outline;
            color: $button-outline;
        }
        @include themify($themes) {
            border-color: themed('borderDarkColor') !important;
            border:1px solid themed('borderDarkColor') !important;
            box-shadow: unset!important;
        }
    }
    .ant-select .ant-select-clear:hover {
        svg {
            @include themify($themes) {
                color: themed('svgColor') !important;
                stroke: themed('svgColor') !important;
            }
        }
        path {
            @include themify($themes) {
                fill: themed('bacgroundBlack') !important;
            }
        }
    }
}
.formulaCreatorPopOver {
    .ant-popover-content {
        .ant-popover-inner {
            padding: 0;
        }
    }
}
.hideFormulaCreatorPopOver {
    display: none;
}
.clearAllContainer {
    position: absolute;
    right: 0;
    bottom: 20px;
    width: 100%;
    .clearAllOverlay {
        position: absolute;
        inset: 420px auto auto 1158.85px !important;
        .ant-popover-content {
            .ant-popover-inner {
                padding: 0;
            }
        }
    }
}
