@import '../../styles/global-settings.scss';

.uomConfigurator__data .ldapConfigurator__button,
.ant-input-affix-wrapper .ldapConfigurator__button {
    width: 215px;
    height: 32px;
}

.uomConfigurator__data .ant-card,
.ant-input-affix-wrapper .ant-card,
.ant-input-affix-wrapper.ant-card.ldapService__card {
    height: auto !important;
    margin: 0px !important;
    .ant-card-body {
        padding: 25px 24px !important;
    }
}

.ldapService__card {
    @include themify($themes) {
        border: 1px solid themed('borderDarkColor') !important;
        background-color: themed('bodyBackgroundColor') !important;
    }
}

.ldapServiceListing__List {
    overflow-y: scroll;
    padding-top: 12px;
    height: calc(100vh - 250px) !important;
}

.ldapServiceListing {
    grid-gap: 30px;
    display: grid;
    gap: 30px;
    grid-template-columns: repeat(4, 1fr);
}

.ldapService {
    &__content {
        display: flex;
        padding: 20px;
    }
    &__cardContent {
        @include themify($themes) {
            color: themed('whitecolor');
        }
        display: grid;
        grid-template-columns: 1fr 30%;
        .connectionIcon {
            flex-direction: column;
            @include flexprob;
        }
    }
    &__cardDetails {
        display: flex;
        flex-direction: column;
        gap: 10px;
        cursor: pointer;
        .ldapActionIcon {
            display: flex;
            justify-content: flex-start;
            gap: 10px;
            align-items: center;
        }
    }
}
.ldapService__details {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.ldap {
    .custom-cancel,
    .custom-save {
        height: 40px !important;
    }
}

.ldapForm {
    width: 60%;
    margin: auto;
    .ldapTimePicker {
        width: 100%;
    }
}

.ldapConfiguration {
    width: 95%;
    margin: auto;
}

.ant-select.ant-select-in-form-item.typeOfIdentity {
    width: 100%;
}

.ldapConfiguratorView__btn {
    display: flex;
    gap: 10px;
}

.ldapActionIcon svg {
    cursor: pointer;
}
.adapterList__content.addMapping.formLdap {
    height: calc(100vh - 345px) !important;
    overflow-y: scroll;
}
.ldapPassword.ant-input-affix-wrapper.ant-input-affix-wrapper {
    padding: 0px 10px !important;
    height: 32px !important;
}

.ldapConfigurator__filter {
    background: $neutral-5;
    padding: 6px 11px;
    border-radius: 5px;
}

.filterPopover {
    .cursor-pointer {
        padding: 5px;
    }
    .ant-divider-horizontal {
        margin: 11px 0px !important;
    }
    .cursor-pointer:hover {
        background-color: $primary-8;
        color: black;
        padding: 5px;
        border-radius: 5px;
    }
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 1;
}

.dimmed {
    opacity: 1.5;
    pointer-events: none;
}

.uomConfigurator__card {
    position: relative;
}

.uomConfigurator__data.listPage .ant-empty-description span,
.ant-input-affix-wrapper .ant-empty-description span {
    max-width: 260px;
    display: block;
}

.selected-filter {
    background-color: #2e53ea;
    color: white;
    padding: 5px;
    border-radius: 5px;
}
