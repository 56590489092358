@import '../../../styles/colorVariable';
@import '../../../styles/global-settings.scss';

.connectionWrapper__noData {
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: auto;
    transform: translate(-50%, -50%);
}

.customButton.custom-new.connections {
    width: 70% !important;
    margin-right: 0px !important;
}

.bulk-importbtn .EmptyDataComponent__buttons__customButton {
    justify-content: center;
}
.connectionWrapper__button {
    .custom-new.connections {
        width: 100% !important;
    }
}

.validateModal {
    width: 40% !important;
    .ant-modal-header {
        padding: 24px;
        background-color: $header-background;
    }
    .ant-modal-title {
        font-weight: 500;
        font-size: 16px;
    }
    .validatedModalText {
        padding: 24px !important;
        // max-width: 92%;
        // color: $success-green-text;
    }
    .validatedModalErrorText {
        padding: 24px !important;
        // max-width: 92%;
        // color: $red-8;
    }
}

.connectionWrapper.attributeWrapper .ant-table-body,
.ant-input-affix-wrapper .ant-table-body {
    overflow-x: unset !important;
}
.connectionWrapper {
    ::-webkit-scrollbar {
        width: 4px;
        height: 10px !important;
    }
}

.attributeWrapper__attributeList {
    overflow: unset !important;
}
.connections {
    .ant-modal-title {
        visibility: hidden;
    }
    .ant-modal-header {
        background-color: #fff !important;
    }
}

.validateTagModal.validateModal.confirmationModal.connections {
    width: 509px !important;
}
.validateModal.connections .validatedModalErrorText {
    padding: 0px 0 15px 0 !important;
}
.popoverWidth {
    max-width: 400px;
    word-wrap: break-word;
}