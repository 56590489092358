@import '../../styles/global-settings.scss';

.auditTrailWrapper,
.ant-input-affix-wrapper {
    gap: 10px;
    padding: 12px;
    background: white;
    @include themify($themes) {
        background: themed('bodyBackgroundColor') !important;
        border-top: 1px solid themed('borderDarkColor') !important;
    }
    .ant-card {
        .ant-card-body {
            padding: 12px 24px 24px 24px;
            @include themify($themes) {
                background-color: themed('bodyBackgroundColor') !important;
                border: 1px solid themed('borderDarkColor') !important;
            }
        }
    }
    &__noData {
        display: flex;

        flex-direction: column;

        padding: 120px;
        .EmptyDataComponent__buttons__customButton {
            padding-top: 30px;
        }
        .EmptyDataComponent__buttons__customButton button {
            width: 143px;
            margin: 0 auto;
        }
    }

    &__attributeListPagination {
        padding-top: 12px;
    }
    &__attributeList {
        padding-top: 12px;
        height: calc(100vh - 305px);
        overflow-y: scroll;
    }

    .ant-card .ant-card-body {
        padding: 12px 24px;
    }

    &__rowHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0px;
    }

    &__button {
        width: 120px;
        height: 32px;
    }

    &__search {
        width: 300px !important;
        height: 40px !important;
        border: none !important;
        border-radius: 8px !important;
        background: $neutral-6 !important;
        @include themify($themes) {
            border-color: themed('borderDarkColor') !important;
            border: 1px solid themed('borderDarkColor') !important;
        }
        .ant-select-selector,
        .ant-input-outlined:focus,
        .ant-input-outlined:hover {
            background: $neutral-6 !important;
            border: none !important;
            width: 300px !important;
        }
        .ant-select-selector::before {
            content: url('/assets/icons/searchIcon.svg');
            font-size: 5px !important;
            margin-left: 4px !important;
        }
        span {
            color: $title-45 !important;
        }
        span.ant-select-selection-placeholder {
            margin-left: 20px !important;
        }
        .ant-select-arrow {
            display: none !important;
        }
        &:hover input.ant-input,
        &:hover .ant-select-selector {
            background: $neutral-6 !important;
            border: none !important;
            width: 300px !important;
            @include themify($themes) {
                background-color: themed('bodyBackgroundColor') !important;
            }
        }

        /* Focus effect */
        &:focus-within input.ant-input,
        &:focus-within .ant-select-selector {
            background: $neutral-6 !important;
            border: none !important;
            width: 300px !important;
            @include themify($themes) {
                background-color: themed('bodyBackgroundColor') !important;
            }
        }
        input.ant-input {
            background: $gray-3;
            height: 16px;
        }
    }
    &__rolesListPagination {
        padding-top: 12px;
    }
    &__rolesList {
        padding-top: 12px;
        height: calc(100vh - 305px);
        overflow-y: scroll;
    }
    .ant-card {
        height: 75vh;
        margin: auto;
    }
    .ant-card-body {
        height: 100%;
        padding: 12px 24px !important;
    }
    .ant-table-body {
        overflow-x: hidden !important;
    }
}

.tableSection {
    background-color: $neutral-1;
    box-sizing: border-box;
    margin-top: 12px;
    @include themify($themes) {
        background: themed('bodyBackgroundColor') !important;
    }
}

.implementationEmptyDataComponent {
    @include flexprob;
    height: 100%;
    flex-direction: column;
    gap: 32px;
}

.implementation_loader {
    height: 300px;
    width: 100%;
    @include flexprob;
}
.unassignedTableEmptyDataComponent {
    @include flexprob;
    height: calc(100vh - 300px);
}
.ant-table-thead > tr > th {
    font-size: 14px;
}

.Container__tabswrapper {
    background: $neutral-6;
    @include themify($themes) {
        background: themed('bodyBackgroundColor') !important;
    }
}

.auditTrailWrapper {
    width: 100%;
    background: white;
    @include themify($themes) {
        background: themed('bodyBackgroundColor') !important;
    }
    &__headerWrapper {
        padding: 20px 5px 20px 24px;
    }
    &__filterWrapper {
        padding: 0px 5px 20px 24px;
        gap: 10px;
        .ant-select {
            width: 100%;
        }
        .ant-picker {
            width: 100%;
        }
    }
}
.customButton.custom-add.users.button-disabled :hover {
    cursor: not-allowed;
    background-color: $title-04 !important;
}
.userCascader {
    .ant-select-selector {
        height: 32px;
    }
}
.ant-select-dropdown.ant-cascader-dropdown.ant-select-dropdown-placement-bottomLeft {
    width: 240px !important;
}
