@import '../../styles/fontStyles';
@import '../../styles/mixins';
@import '../../styles/global-settings.scss';

.reasonCode {
    width: 100%;
    &__headerWrapper {
        padding: 20px 5px 20px 24px;
        background: white;
        @include themify($themes) {
            background: themed('bodyBackgroundColor') !important;
     }
    }
    &__content {
        padding: 20px 15px 20px 15px;
        height: calc(100vh - 160px);
        overflow: hidden;
    }
    &__data {
        height: calc(100vh - 184px);
    }
    &__dataModel {
        background-color: #f1f2f380;
        position: static;
        z-index: 3;
        border-right: 1px solid rgba(0, 0, 0, 0.25);
        height: calc(100vh - 182px);
        overflow-y: scroll;
        @include themify($themes) {
            background: themed('bodyBackgroundColor') !important;
            color: themed('headingColor') !important;
     }
    }
     .dataModel .active {
        @include themify($themes) {
        border: 1px solid rgba(255, 92, 0, 0.85) !important;
        background: rgb(255, 239, 229) !important;
        color: themed('bacgroundBlack') !important;
        }
    }
    .cardDefaultClass {
            @include themify($themes) {
                background: themed('bodyCardBackgroundColor') !important;
                color: themed('headingColor') !important;
                border: 1px solid themed('borderDarkColor') !important;
            }
    }
    .dataModel__card {
    @include themify($themes) {
        background-color: themed('bodyCardBackgroundColor') !important;
        border: 1px solid themed('borderDarkColor') !important;
    }
}
    &__card {
        .ant-card-body {
            padding: 0px !important;
        }
    }
    &__tableWrapper {
        height: 100%;
        @include themify($themes) {
            background: themed('bodyBackgroundColor') !important;
     }
        .customPagination ul.ant-pagination {
            width: 80% !important;
            left: 20% !important;
        }
    }
    &__tableWrapperPadding {
        padding: 25px 25px 0px 25px;
    }
    &__tableHeaderWrapper {
        display: flex;
        justify-content: space-between;
    }
    &__tableHeaderDropdoenWrapper {
        display: flex;
        gap: 10px;
    }
    &__refreshLanguageContainer {
        display: flex;
        gap: 15px;
        .ant-radio-group.ant-radio-group-outline label {
            @include themify($themes) {
                background-color: themed('bodyBackgroundColor') !important;  
                 color: themed('headingColor') !important;
            }
        }  
        .ant-radio-button.ant-radio-button-checked{
            @include themify($themes) {
                background-color: themed('brandColor') !important;
            } 
        }
      
        .ant-radio-button-wrapper {                
            &:hover {
                @include themify($themes) {
                    background-color: themed('hoverBackgroundColor') !important;
                    border-color: themed('hoverColor') !important; 
                    color: themed('hoverColor') !important;
                }
            }
        }
    }
}

.reasonCodeWrapper__downTimeListPagination {
    padding-top: 12px;
    height: calc(100vh - 300px);
}
.reasonCodeWrapper__downTimeList {
    padding-top: 12px;
    height: calc(100vh - 305px);
}
