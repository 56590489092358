@import '../../../styles/global-settings.scss';

.customDropDown {
    padding-right: 5px;
    width: 100%;
    .ant-select-single {
        width: 100%;
    }
    span.anticon.anticon-smile {
        display: flex;
    }
    .ant-select-selection-item {
        display: flex;
        align-items: center;
        gap: 5px;
        @include themify($themes) {
            color: themed('headingColor') !important;
            background: themed('hoverMenuColor') !important;
        }
    }

    .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
        padding-inline-end: 0px;
        @include themify($themes) {
            color: themed('headingColor') !important;
        }
    }

    .ant-select-dropdown .ant-select-item-option-content {
        display: flex;
        gap: 5px;
        align-items: center;
    }
    .ant-select-dropdown {
        width: 192px !important;
    }
}

.ant-select-dropdown .ant-select-item-option-content {
    display: flex;
    gap: 5px;
    align-items: center;
}

.ant-input-search.customDropDownValues__searchbar input {
    @include themify($themes) {
        background: themed('bodyCardBackgroundColor');
    }
    .ant-input-search-button {
        @include themify($themes) {
            background: themed('bodyCardBackgroundColor');
        }
    }
}
.theme-dark
    .ant-input-search.customDropDownValues__searchbar
    input::placeholder {
    color: #fff;
}
.theme-dark .ant-input-search-button {
    background: #030921 !important;
}

.theme-dark .ant-select-dropdown {
    background: #030921;
    border-color: rgb(255 255 255 / 20%) !important;
    border: 1px solid rgb(255 255 255 / 10%) !important;

    .ant-select-item-option-content {
        color: #fff !important;
    }
}

.ant-dropdown-menu {
    @include themify($themes) {
        background: themed('bodyBackgroundColor') !important;
    }
    label {
        @include themify($themes) {
            color: themed('headingColor') !important;
        }
    }
}

.ant-btn-default.ant-dropdown-trigger.ant-dropdown-open:hover {
    @include themify($themes) {
        border-color: themed('borderDarkColor') !important;
    }
}
