@import '../../../../../styles/global-settings.scss';
.chipsInputContainer {
    display: flex;
    flex-wrap: wrap;
    width: 98%;
    height: 98%;
    overflow-y: scroll;
    padding-top: 10px;
    .chipsInputComponent {
        position: relative;
        display: flex;
        height: 35px;
        justify-content: center;
        align-items: flex-start;
        margin-left: 5px;
        gap: 5px;
        .ant-input {
            border: 0;
            border-bottom: lightblue solid 1px;
            padding-left: 4px;
            height: 30px;
        }
        .ant-input:focus {
            box-shadow: none;
        }

        &__cancel {
            display: none;
            width: 20px;
            height: 20px;
            position: absolute;
            z-index: 999;
            right: -10px;
            top: -10px;
        }
        &__editButton {
            width: 30px;
            height: 30px;
            border-radius: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: transparent;
            padding: 0 !important;
        }
        &__editButton:hover {
            background-color: lightgray;
        }
    }
    .chipsInputComponent:hover,
    :focus {
        border-style: dashed;
        border-color: darkgray;
        .chipsInputComponent__cancel {
            display: block;
        }
    }
}
