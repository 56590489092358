@import '../../styles/global-settings.scss';

.manualEntry {
    .manualEntryWrapperScrolContent {
        overflow-y: scroll;
        height: calc(100vh - 165px);
        @include themify($themes) {
        border-top: 1px solid themed('borderDarkColor') !important;
        }
    }
    .manualEntryWrapper {
        height: calc(100vh - 196px);
        @include fontTextStyle;
        @include themify($themes) {
            background: themed('bacgroundBlack');
        }
        &__cardContent {
            height: 100%;
         @include flexprob;
            @include themify($themes) {
                background: themed('bodyBackgroundColor');
            }
        }
        &__cardBody {
            height: 100%;
            display: flex;
            flex-direction: column;
            gap: 45px;
        }

        .ant-btn-default:disabled  {
            @include themify($themes) {
                background: themed('disableButtonNocil')!important;
               color:  themed('disableButtonNocil')!important;
                border-color:  themed('disableButtonNocil') !important;
                border: 1px solid  themed('disableButtonNocil')!important;
            }
        }
    }
}


