@import '../../../../styles/global-settings.scss';
@import '../../../../styles/colorVariable';

.editTable-template {  
    padding: 10px;
    .ant-card-body{
        @include themify($themes) {
            background-color: themed('bodyBackgroundColor') !important; 
            border-radius: 7px;  
        }
        .ant-table-wrapper .ant-table-thead >tr>td {
            @include themify($themes) {
                background: themed('tableHeader');  
                border-bottom: 1px solid themed('borderDarkColor') !important;  
        }
    }
    }
    .wrapper {
        padding-top: 5px;
        padding-bottom: 25px;
        @include themify($themes) {
            color: themed('headingColor') !important;
        }
        .anticon svg {
            svg {
                @include themify($themes) {
                    color: themed('svgColor') !important;
                    stroke: themed('svgStrokeColor') !important;
                }      
            }
            path {
                @include themify($themes) {
                    fill: themed('svgColor') !important;
                }
            } 
        }
    }

    .header {
        .anticon-arrow-left {
            margin-right: 8px;
            color: rgba(0, 0, 0, 0.45);
            cursor: pointer;
        }
        align-items: center;
        width: 205px;
        height: 24px;
    }
    .name-description {
        .ant-form {
            display: flex;
        }
        .name {
            margin-right: 8px;
            width: 50%;
        }
        .description {
            margin-right: 8px;
            width: 100%;
        }
        .type {
            width: 30%;
        }
    }
    .form {
        .EditTableCreateTable {
            &__Wrapper {
                &__button {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    .custom-add-button {
                        background-color: #ffffff;
                        color: $primary-7;
                        border: 1px solid $primary-7;
                        @include themify($themes) {
                            background: themed('bodyBackgroundColor');  
                            border: 1px solid themed('borderDarkColor') !important;  
                    }

                        &:hover,
                        &:focus {
                            background-color: white;
                            border: 1px solid $primary-7;
                            @include themify($themes) {
                                background: themed('bodyBackgroundColor');  
                                border: 1px solid themed('hoverColor') !important;  
                        } 
                        }

                        &:active {
                            background-color: $primary-7;
                            color: white;
                            border: 1px solid $primary-7;
                        }

                        .addIcon {
                            color: $primary-7;
                        }
                    }
                }

                &__Footer {
                    @include flexprob;
                    background: $neutral-1;
                    width: 100%;
                    padding: 18px 24px 0px 24px;
                    z-index: 1;
                    gap: 8px;
                    border-top: 0.5px solid $border;
                    @include themify($themes) {
                        background-color: themed('bodyBackgroundColor') !important;
                        border-top: 1px solid themed('borderDarkColor') !important;    
                    }
                }
                &__FooterWrapper {
                    width: 110px;
                }
            }

            .deleteicon {
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .editable-cell {
                position: relative;
            }

            .editable-cell-value-wrap {
                padding: 5px 12px;
                cursor: pointer;
            }

            .editable-row:hover .editable-cell-value-wrap {
                padding: 4px 11px;
                border: 1px solid #d9d9d9;
                border-radius: 2px;
            }
            td.ant-table-cell svg{
                svg {
                    @include themify($themes) {
                        color: themed('svgColor') !important;
                        stroke: themed('svgColor') !important;
                    }
                }
                path {
                    @include themify($themes) {
                        fill: themed('svgColor') !important;
                    }
                }
            }
        }
    }
}

.ant-table-thead > tr > th.columnName,
.ant-table-thead > tr > th.dataType,
.ant-table-thead > tr > th.uom {
    padding-left: 25px; // Adjust the padding to move the header to the right
}

.column__action {
    text-align: center !important;
}
.editCard{
        height: calc(100vh - 374px) !important;
        overflow: hidden;
    }