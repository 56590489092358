@import '../../../../styles/colorVariable';
@import '../../../../styles/global-settings.scss';

.deviceWrapper {
    gap: 10px;
    padding: 12px;
    @include themify($themes) {
        background-color: themed('bacgroundBlack') !important;
        border-color: themed('borderDarkColor') !important;
        border: 1px solid themed('borderDarkColor') !important;
    }  
    .ant-card-body{
        @include themify($themes) {
        background-color: themed('bacgroundBlack') !important;  
        }
    }
    &__headerWrapper {
        padding: 21px 10px 15px 15px;
        border-bottom: 0.5px solid $border;
        @include themify($themes) {
            background-color: themed('bodyBackgroundColor') !important;
        } 
    }
    &__createRolesList {
        padding-top: 12px;
        height: calc(100vh - 367px);
    }
    &__createRoleContent2 {
        padding: 20px;
    }

    &__heading {
        display: flex;
    }

    &__backIcon {
        width: 30px;
        height: 32px;
        padding: 8px 0 0 8px;
        cursor: pointer;
        svg{    
            @include themify($themes) {
            color: themed('svgColor') !important;
        }
    }
        path{
            @include themify($themes) {
                fill: themed('svgColor') !important;
            }  
        }
    }

    &__screenNameHeading{
        margin-left: 10px;
    }

    &__createRowHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 20px 15px 20px;
        border-bottom: 0.5px solid $border;
    }

    &__button {
        .ant-btn {
            padding: 0px;
        }
        width: 131px;
        height: 32px;
    }

    &__search {
        width: 300px;
        height: 40px;
        background: $gray-3;
        border: none;
        border-radius: 8px;
        input.ant-input {
            background: $gray-3;
        }
        span {
            color: $title-45;
        }
    }
    &__createRolesContent {
        display: flex;
        width: 100%;
        margin-bottom: 10px;
    }
    &__createRolesDropdownContent {
        width: 30%;
        border-right: 0.5px solid $border;
        padding: 10px 30px 10px 30px;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    &__createRolesTableContent {
        width: 70%;
        padding: 10px 30px 10px 30px;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    &__createRolesDropdownCollapse {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    &__buttonGroup {
        border: 1px solid $primary-4;
        color: $primary-4;
        border-radius: 4px;
        width: 100%;
    }
    &__buttonGroupIcon {
        color: $primary-4;
        size: 12px;
    }

    &__createRolesTableHeader {
        color: $title-45;
        font-weight: bold;
        display: flex;
    }
    &__createRolesSwitch {
        width: 28px;
    }
    &__createRolesTableContentRow {
        width: 100%;
    }
    &__createRolesTypographySwitch {
        width: 50%;
        text-align: center;
    }
    &__createRolesTypography {
        width: 100%;
        text-align: left;
    }
    &__createRolesSwitch {
        width: 50%;
        align-items: center;
    }
    &__createRoleFooter {
        @include flexprob;
        background: $neutral-1;
        width: 100%;
        padding: 12px 24px 16px 24px;
        z-index: 1;
        gap: 8px;
        border-top: 0.5px solid $border;
        @include themify($themes) {
            background-color: themed('bodyBackgroundColor') !important;
            border-color: themed('borderDarkColor') !important;
            border: 1px solid themed('borderDarkColor') !important;
        } 
    }
    &__footerButtonWrapper {
        width: 110px;    
        .ant-btn {
            @include themify($themes) {
                color: themed('skipButton') !important;
            }           
            &:hover {
                @include themify($themes) {
                    color: themed('hoverColor') !important;
                }
            }
        }
    } 
    &__createRoleList {
        background-color: $neutral-7;
        padding: 0px 12px;
        .ant-list-item {
            padding: 12px 0px;
        }
    }
    &__createRolesTableContentRowHeader {
        padding: 0px 12px;
    }
    &__createRoleContent3 {
        @include flexprob;
        flex-direction: column;
        height: 100%;
        width: 100%;
    }
    &__createRoleInnerContent {
        display: flex;
        width: 60%;
        gap: 20px;
        margin: 10px;
    }
    &__createRoleInnerContentText {
        width: 70%;
        text-align: end;
    }
    &__scrollContent {
        height: calc(100vh - 401px);
        overflow: scroll;
        @include themify($themes) {
            background-color: themed('bodyBackgroundColor') !important;
        }  
    }
}
.selectInterfaceContainer {
    @include themify($themes) {
        background-color: themed('bodyBackgroundColor') !important;
        border-color: themed('borderDarkColor') !important;
        border-bottom: 1px solid themed('borderDarkColor') !important;
    }}

.deviceWrapper__header {
    display: grid;
    grid-template-columns: 1fr 133px 124px;
    grid-column-gap: 8px;
    align-items: center;
    @include themify($themes) {
        background-color: themed('bodyBackgroundColor') !important;
    }
}

.mandatoryClass {
    color: $red-3;
}
.tagListing__tabs {
    .ant-tabs-nav {
        padding: 0px 20px;
        margin: 0px !important;
    }
}
.ant-radio-inner{
    @include themify($themes) {
        background-color: themed('bodyBackgroundColor') !important;
    }
}
.ant-table-selection-column{
    @include themify($themes) {
        background-color: themed('tableHeader') !important;
        border-bottom: themed('borderlineColor') !important;
    }
}
