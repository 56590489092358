@import '../../../styles/global-settings.scss';

.dataVisualizationDashboard {
    .card-heading {
        height: 116px;
    }
    .dataVisualizationWrapperScrolContent {
        overflow-y: scroll;
        height: calc(100vh - 180px);
    }

    .ant-card .ant-card-body {
        padding-right: 20px;
        padding-top: 16px;
    }
    .dataVisualizationWrapper {
        @include fontTextStyle;
    }
}

.data-visualization-container {
    background-color: $neutral-1;
    height: calc(100vh - 186px);
    margin: 8px;
    display: flex;
    .ant-table-wrapper.child {
        overflow-y: auto !important;
        height: calc(100vh - 330px) !important;
    }
    .ant-table-wrapper {
        overflow-y: auto;
        &.trending-page {
            height: calc(100vh - 640px);
        }
        &.other-page {
            height: calc(100vh - 255px);
        }
    }
    .ant-table-selection {
        display: none !important;
    }
    .left {
        width: 25%;
        padding: 8px;
        border-right: 1px solid #dedede;
        .device-tag-selector-header {
            display: flex;
            place-content: space-between;
        }
        .child {
            padding: 6px;
            .ant-select-selector {
                padding: 7px;
            }
        }
        @include themify($themes) {
            background: themed('bodyBackgroundColor') !important;
        }
    }
    .leftCollaspe {
        width: 0%;
        display: none;
    }
    .ant-input-affix-wrapper.search-tag {
        padding: 0 !important;
    }
    .search-tag {
        margin-top: 6px;
        margin-left: 4px;
        width: calc(100% - 7px);
        span {
            color: $gray-9;
            margin-right: 2px;
        }
    }
    .right {
        width: 75%;
        @include themify($themes) {
            background: themed('bodyBackgroundColor') !important;
        }
    }
    .rightFull {
        width: 100%;
        @include themify($themes) {
            background: themed('bodyBackgroundColor') !important;
        }
        .expandIcon {
            margin-right: 10px;
        }
    }
    &__header {
        padding: 16px 24px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include themify($themes) {
            background: themed('bodyBackgroundColor') !important;
            color: themed('headingColor') !important;

            .ant-radio-group.ant-radio-group-outline label {
                @include themify($themes) {
                    background-color: themed('bodyBackgroundColor') !important;
                    color: themed('headingColor') !important;
                    border: 1px solid themed('borderDarkColor') !important;
                }
            }
            .ant-radio-button.ant-radio-button-checked {
                @include themify($themes) {
                    background-color: themed('brandColor') !important;
                    border: 1px solid themed('borderDarkColor') !important;
                    border-radius: 5px;
                }
            }

            .ant-radio-button-wrapper {
                &:hover {
                    @include themify($themes) {
                        background-color: themed(
                            'hoverBackgroundColor'
                        ) !important;
                        border-color: themed('hoverColor') !important;
                        color: themed('hoverColor') !important;
                    }
                }
            }
        }

        &__header-exportButton {
            button {
                .export-name {
                    color: $primary-5 !important;
                }
                border: 1px solid var(--neutral-5, #d9d9d9);
                background: transparent;

                span:nth-child(1) {
                    svg {
                        fill: $title-45;
                        color: $title-45;
                        stroke: none;
                    }
                }
                span:nth-child(2) {
                    color: $title-85;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 22px;
                }
            }
            button:hover {
                border: 1px solid $primary-5;
                background-color: transparent !important;

                span:nth-child(1),
                .custom-download.as.csv {
                    svg {
                        color: $primary-5 !important;
                        stroke: none;
                    }
                    span {
                        color: $primary-5 !important;
                    }
                    path {
                        fill: $primary-5 !important;
                    }
                }
                span:nth-child(2) {
                    color: $primary-5 !important;
                }
            }
        }
        .custom-download.as.csv {
            svg {
                color: $title-85;
                stroke: none !important;
            }
            span {
                color: $title-85;
            }
            path {
                fill: $title-85;
            }
        }
    }
    &__multiAxisChart {
        g.highcharts-range-selector-buttons {
            display: none !important;
        }
        height: 400px;
        overflow-y: scroll;
        @include themify($themes) {
            background: themed('bodyBackgroundColor') !important;
        }
        &__multiAxisChartTable {
            padding: 10px;
        }
        &__header-exportButton {
            button {
                .export-name {
                    color: $primary-5 !important;
                }
                border: 1px solid var(--neutral-5, #d9d9d9);
                background: transparent;

                span:nth-child(1) {
                    svg {
                        fill: $title-45;
                        color: $title-45;
                        stroke: none;
                    }
                }
                span:nth-child(2) {
                    color: $title-85;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 22px;
                }
            }
            button:hover {
                border: 1px solid $primary-5;
                background-color: transparent !important;

                span:nth-child(1),
                .custom-download.as.csv {
                    svg {
                        color: $primary-5 !important;
                        stroke: none;
                    }
                    span {
                        color: $primary-5 !important;
                    }
                    path {
                        fill: $primary-5 !important;
                    }
                }
                span:nth-child(2) {
                    color: $primary-5 !important;
                }
            }
        }
        @include themify($themes) {
            background: themed('bodyBackgroundColor') !important;
        }
    }
    .table {
        margin-top: 8px;
        height: 29%;
    }
    .deviceTagsHeading {
        font-size: 16px;
        font-weight: 400;

        @include themify($themes) {
            color: themed('headingColor') !important;
        }
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }
    .expandExportOption {
        display: flex;
        flex-direction: row;
        gap: 1rem;
        .export-fn {
            border: 0 !important;
        }
        .ant-select-selection-item {
            display: none;
        }
    }
    .icon-Inside svg {
        position: absolute;
        right: 37px;
        top: 5px;
        z-index: 999;
    }

    .icon-Inside {
        position: relative;
    }
    .sampling {
        .ant-select-selector {
            border: 0;
            background: #f8f9fa !important;
        }
        .ant-btn-default {
            border: 0;
            background: #f8f9fa !important;
        }
    }

   .sampling.functions {
        cursor: pointer ;
    }
    .merge {
        display: flex;
        align-items: center;
    }
    .merge label {
        margin-right: 10px;
    }
    .expandFrequency {
        width: 70px;
        background: #ffffff;
        border-color: #d9d9d9;
        color: rgba(0, 0, 0, 0.88);
        box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
        @include themify($themes) {
            background: themed('bodyCardBackgroundColor') !important;
        }
    }

    .expandIcon {
        cursor: pointer;
      

        @include themify($themes) {
            border: 1px solid themed('borderDarkColor') !important;
            border-radius: 5px;
        }

        svg {
            @include themify($themes) {
                stroke: themed('svgColor') !important;
            }
        }

        rect {
            @include themify($themes) {
                fill: themed('bodyBackgroundColor') !important;
            }
        }

        path {
            @include themify($themes) {
                fill: themed('svgColor') !important;
                fill-opacity: 1;
            }
        }
    }

    .disableExpandBtn {
        margin-right: 12px;
        cursor: not-allowed;

        @include themify($themes) {
            border: 1px solid themed('borderDarkColor') !important;
            border-radius: 5px;
        }

        svg {
            @include themify($themes) {
                stroke: themed('svgColor') !important;
            }
        }

        rect {
            @include themify($themes) {
                fill: themed('bodyBackgroundColor') !important;
            }
        }

        path {
            @include themify($themes) {
                fill: themed('svgColor') !important;
            }
        }
    }

    .closeIcon {
        margin-right: 12px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgb(248, 249, 250);
        padding: 8px;
        border-radius: 6px;
        @include themify($themes) {
            background-color: themed('bodyBackgroundColor') !important;
            border: 1px solid themed('borderDarkColor') !important;
        }
        svg {
            @include themify($themes) {
                color: themed('svgColor') !important;
                stroke: themed('svgColor') !important;
            }
        }
        path {
            @include themify($themes) {
                fill: themed('svgColor') !important;
            }
        }
    }
    .data-visualization-container.trending-container.hideTrendingArea {
        display: none;
    }

    // might use later

    // .data-visualization-container.trending-container{
    //     .right {
    //             @include themify($themes) {
    //                 background: themed('bodyBackgroundColor') !important;
    //             }
    //     }
    // }

    // .data-visualization-container.other-container {
    //     .right {
    //         @include themify($themes) {
    //             background: themed('bodyBackgroundColor') !important;
    //         }
    // }
    
    .selecedTagCloseIcon{
        svg {
            @include themify($themes) {
                color: themed('headingColor') !important;
            }
        }
        path {
            @include themify($themes) {
                fill: themed('headingColor') !important;
                stroke: themed('headingColor') !important;
            }
        }
    }
    .ant-spin-spinning {
        @include flexprob;
        height: 50vh;
    }
}

/* Set width of the dropdown overlay */
.custom-dropdown {
    width: 120px !important; /* Use !important to override inline styles */
}

.functionPopover label {
    padding: 7px;
    label.ant-checkbox-wrapper.ant-checkbox-group-item:hover {
        background-color: #e6f4ff;
    }
}

/* Set width of the dropdown overlay */
.custom-dropdown {
    width: 120px !important; /* Use !important to override inline styles */
}

.functionPopover label {
    padding: 7px;
    label.ant-checkbox-wrapper.ant-checkbox-group-item:hover {
        background-color: #e6f4ff;
    }
}

/* scroll  */
// .ant-table-body::-webkit-scrollbar-track
// {
// 	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3)!important;
// 	border-radius: 10px ;
// 	background-color: #F5F5F5;
// }

// .ant-table-body::-webkit-scrollbar
// {
// 	width: 12px;
// 	background-color: #F5F5F5;
// }
.tableScroll .ant-table-body{
overflow-x: hidden;
    .dataTable__pagination {  
        padding: 0;  
        bottom: 30px;
    }
    .ant-table-body{
        overflow-x: hidden;

    }
}
