@import '../../../styles/global-settings.scss';

.tabsComponent {
    .ant-tabs-content-holder {
        background: $neutral-6;
    } 
   
}
.implementation__tabsSection {
    margin-top: 2px;
    height: 100%;
    .ant-tabs-content {
        height: 100%;
        .ant-tabs-tabpane {
            height: 100%;
        }
    }
    .ant-tabs {
        height: 100%;
        @include themify($themes) {
            background: themed('bodyBackgroundColor') !important;
            }
    }
    .ant-tabs-nav {
        margin-left: 8px;
        @include themify($themes) {
            background: themed('bodyBackgroundColor') !important;
            margin-left: 0;
            }
    }
    .assignedAttribute__searchBox {
        margin-left: 8px;
        @include themify($themes) {
            background: themed('bodyBackgroundColor') !important;
            }
    }
    .ant-tabs-nav-wrap {
        margin-left: 8px;
    }
}
.theme-dark .ant-tabs-nav::before {
  // border-bottom: 0!important;
    @include themify($themes) { 
        border-color: themed('borderDarkColor')!important;
      }
}

.implementation{
    @include themify($themes) {
        background: themed('bacgroundBlack') !important;
        }
}