@import '../../../styles/global-settings.scss';
.currentDateTimeComponent {
    display: flex;
    flex-direction: column;
    &__date {
        color: $title-5;
        text-align: right;
        font-size: 14px;
        font-weight: 300;
        letter-spacing: 2.1px;
        text-transform: uppercase;
        @include themify($themes) {
            color: themed('headingColor') !important;
        }
    }
    &__time {
        color: $title-35;
        text-align: right;
        font-size: 20px;
        font-weight: 500;
        letter-spacing: 2px;
        text-transform: uppercase;
        @include themify($themes) {
            color: themed('headingColor') !important;
        }
    }
}
