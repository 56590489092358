@import '../../../../styles/global-settings.scss';

.DataFilter {
   
   
    .ant-modal-footer {
      @include flexprob;
    }
    .ant-row {
        width: 100%;
        justify-content: flex-end;
    }
    .ant-modal-content {
        width: 600px;
    }
    .ant-form-item-control-input-content{
        display: flex;
        gap: 30px;
        margin-right: 10px;
    }
    .inputList {
        display: flex;
    }
    .input-container{ 
    display: flex;
    input:first-child{
    margin-right: 10px;
    }

}
}

.controlLimits {
    .ant-modal-footer {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .ant-btn {
        width: 30%;
    }
    .ant-form-item {
        margin-bottom: 10px;
    }
}
