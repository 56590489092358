.moreContent__items {
    list-style: none;
    padding-bottom: 12px;
}
.moreContent__option {
    font-size: 14px;
}
.moreContent__option:hover {
    cursor: pointer;
}
.moreContent__icon {
    margin-right: 8.6px;
    vertical-align: middle;
}
