@import '../../styles/fontStyles';
@import '../../styles/mixins';
@import '../../styles/global-settings.scss';

.calendarConfigurator {
    width: 100%;
    background: white;
    @include themify($themes) {
        background: themed('bodyBackgroundColor') !important;
    }
    &__headerWrapper {
        padding: 21px 24px;
    }
    &__tabsWrapper {
        @include textfontstyle;
        margin-top: 16px;
    }
    &__innerbox{
        @include themify($themes) {
            background: themed('bacgroundBlack') !important;
            border-top: 1px solid themed('borderDarkColor') !important;
        }
        .ant-btn-primary:disabled {
            @include themify($themes) {
                color: themed('headingColor') !important;
            }
        }
    }
    .ant-tabs-nav {
        margin: 0px 0px 0px 24px;
    }
    .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: $primary-6;
    }
    .ant-tabs-ink-bar {
        background-color: $primary-6;
    }
    .ant-tabs-tab:hover {
        color: $primary-6;
    }
}
