@import '../../../../styles/global-settings.scss';

.criticalParametersTrendAreaWrapper {
    height: 693px;
    width: 100%;
    margin-bottom: 16px;
    font-weight: 500;
    .ant-card-body {
        height: 100%;
        .multiAxis {
            height: 100%;
        }
        @include themify($themes) {
            background: themed('bodyBackgroundColor') !important;
            border:1px solid themed('borderDarkColor') !important;
        }
    }
    &__header {
        display: flex;
        justify-content: space-between;
        @include themify($themes) {
            color: themed('headingColor') !important;
        }
           
    }
    &__header-exportButton {
        .custom-apply:hover {
            background: transparent !important;
            border: 1px solid $primary-5;
        }
        button {
            border: 1px solid var(--neutral-5, #d9d9d9);
            background: transparent;
            div:nth-child(1) {
                background-color: transparent;
            }

            span:nth-child(1) {
                svg {
                    fill: $title-45;
                    color: $title-45;
                    stroke: none;
                    @include themify($themes) {
                        color: themed('tableSvgColor') !important;
                        stroke: themed('tableSvgColor') !important;
                    }  
                }
                path{
                    @include themify($themes) {
                        fill: themed('tableSvgColor') !important;
                    }   
                }
            }
            span:nth-child(2) {
                font-size: 14px;
                font-weight: 400;
                line-height: 22px;
            }
        }
       
    }
    .custom-download.as.csv {
        svg {
            color: $title-85;
            stroke: none !important;
        }
        span {
            color: $title-85;
        }
        path {
            fill: $title-85;
        }
    }
    svg {
        @include themify($themes) {
            color: themed('tableSvgColor') !important;
            stroke: themed('svgTableStrokeColor') !important;
        }      
    }
  
}

.criticalParametersTrendEmptyTrendWrapper {
   @include flexprob;
    height: 100%;
    svg {
        @include themify($themes) {
            stroke: themed('svgColor') !important;    
        }      
    }
    path {
        @include themify($themes) {
            stroke: themed('svgColor') !important;
            color: themed('svgColor') !important; 
        }
    }
}
