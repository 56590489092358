.view__loader {
    height: 307px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100% !important;
}

.assignTimeCapsuleWrapper {
    padding: 12px;
    height: 762px !important;
}
