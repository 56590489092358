@import '../../../../../styles/global-settings.scss';

.selectInterfaceContainer {
    margin: 9px 8px 0px 9px;
    height: calc(100vh - 221px);
    overflow-y: scroll;
    background: #ffffff;
    border-radius: 2px;
    @include themify($themes) {
        background-color: themed('bodyBackgroundColor') !important;
        border-color: themed('borderDarkColor') !important;
        border: 1px solid themed('borderDarkColor') !important;
    }

    .title {
        padding: 14px 0px 13px 18px;
        font-size: 16px;
        font-family: 'Roboto', sans-serif;
        color: rgba(0, 0, 0, 0.85);
        background: #ffffff;
        margin: 0px;
        border-radius: 2px 2px 0px 0px;
        box-shadow: inset 0px -1px 0px #f0f0f0;
        @include themify($themes) {
            background-color: themed('bodyBackgroundColor') !important;
            color: themed('headingColor') !important;
        }
    }

    .optionsContainer {
        padding: 16px 10px;
        @include themify($themes) {
            background-color: themed('bodyBackgroundColor') !important;
            color: themed('headingColor') !important;
        }

        .select-text {
            font-family: 'Roboto', sans-serif;
            font-size: 14px;
            line-height: 16px;
            color: rgba(0, 0, 0, 0.45);
            padding-bottom: 16px;
            @include themify($themes) {
                color: themed('textColor') !important;
            }
    
        }

        .device-selection-container {
            display: flex;
            flex-wrap: wrap;
            gap: 4px;
            
            .active-box {
                box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12),
                    0px 6px 16px rgba(0, 0, 0, 0.08),
                    0px 9px 28px 8px rgba(0, 0, 0, 0.05);
                border: 1px solid #1890ff;
                @include themify($themes) {
                    background-color: themed('bodyBackgroundColor') !important;
                }
            }

            .device-selection-disabled {
                cursor: not-allowed !important;
                background-color: lightgrey;
                opacity: 50%;
            }

            .inactive-box {
                border: 1px solid #f0f0f0;
                box-shadow: none;
                @include themify($themes) {
                    background-color: themed('bodyBackgroundColor') !important;
                    border-color: themed('borderDarkColor') !important;
                    border: 1px solid themed('borderDarkColor') !important;
                    &:hover {
                        @include themify($themes) {
                        background: themed('hoverMenuColor') !important;
                        }
                    }
                }
            }

            .device-selection-box {
                width: 334px;
                padding: 24px;
                border-radius: 8px;
                cursor: pointer;
                background: #ffffff;

                .device-info {
                    font-family: 'Roboto', sans-serif;
                    font-size: 13px;
                    line-height: 20px;
                    color: rgba(0, 0, 0, 0.65);
                    margin: 0;
                    height: 60px;
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    @include themify($themes) {
                        color: themed('textColor') !important;
                    }
                }

                .image-block {
                    display: flex;
                    align-items: center;
                    margin-bottom: 16px;

                    .circle {
                        border-radius: 50%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 64px;
                        height: 64px;
                        box-sizing: border-box;
                        background: #ffffff;
                    }

                    .normal-circle {
                        border: 1px solid #e5e5e5;
                    }

                    .active-circle {
                        border: 1px solid #91d5ff;
                    }

                    .common {
                        margin: 0 8px;
                        font-family: 'Roboto', sans-serif;
                        font-size: 16px;
                        line-height: 24px;
                    }

                    .heading {
                        @extend .common;
                        color: rgba(0, 0, 0, 0.85);
                        @include themify($themes) {
                            color: themed('headingColor') !important;
                        }
                    }

                    .heading-active {
                        @extend .common;
                        font-weight: bold;
                        color: #1890ff;
                    }
                }
            }

            .device-selection-box:hover {
                box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12),
                    0px 6px 16px rgba(0, 0, 0, 0.08),
                    0px 9px 28px 8px rgba(0, 0, 0, 0.05);
            }
        }
    }
    .ant-card-body{
        @include themify($themes) {
            background-color: themed('bodyBackgroundColor') !important;
              border-color: themed('borderDarkColor') !important;
            border: 1px solid themed('borderDarkColor') !important;
        }
    }
}
.tabsComponent .ant-tabs-content-holder {
    @include themify($themes) {
        background-color: themed('bacgroundBlack') !important;
        border-color: themed('borderDarkColor') !important;
        border: 1px solid themed('borderDarkColor') !important;
    }
}

.communicationInterface__container {
    .ant-drawer-body {
        padding: 0px !important;
    }
}

