@import '../../../styles/colorVariable';
@import '../../../styles/global-settings.scss';

.auditTableWrapper {
    width: 100%;
    padding: 4px;
    height: calc(100vh - 172px);
    background: white;
    @include themify($themes) {
        background: themed('bodyBackgroundColor') !important;
    }
    .ant-card {
        height: 100%;
    }
    .ant-card-body {
        height: 100%;
    }
    .column-title,
    &__columnTitle {
        display: flex;
        justify-content: space-between;
        &__name {
            display: grid;
            grid-template-columns: 10% 1fr 10%;
            justify-content: center;
            align-items: center;

            .sortArrows {
                @include flexprob;
                flex-direction: column;
                gap: 2px;
                cursor: pointer;
            }
        }
        .sortArrows,
        span {
            @include flexprob;
            flex-direction: column;
            gap: 2px;
            cursor: pointer;
        }
    }
}
.subTableComponent {
    display: flex;
    margin-inline: -16px;
    margin-block: -16px;
    align-items: flex-start;
    span {
        border: 1px solid $neutral-4;
        padding: 12px 12px;
        background: $neutral-7;
        border-radius: 8px;
        @include themify($themes) {
            background-color: themed('bodyBackgroundColor') !important;
        }
    }
}
.sub-table-component {
    max-height: auto;
    overflow-y: visible; 
    width: 500px; 

    &.is-long {
        max-height: 100px; 
        overflow-y: auto; 
    }
}
.icon{
    font-size: 16px;
    cursor: pointer;
}
