@import '../../styles/fontStyles';
@import '../../styles/mixins';
@import '../../styles/global-settings.scss';

.assetModelling {
    width: 100%;
    background: white;
    @include themify($themes) {
        background: themed('bodyBackgroundColor') !important;
        color: themed('headingColor') !important;
 }
    &__headerWrapper {
        padding: 21px 24px;
        @include themify($themes) {
            background: themed('bodyBackgroundColor') !important;
            color: themed('headingColor') !important;
            border-bottom: 1px solid themed('borderDarkColor') !important;
     }
    }
    &__tabsWrapper {
        @include textfontstyle;
        margin-top: 16px;
    }
    .ant-tabs-nav {
        margin: 0px 0px 0px 24px;
    }
    .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: $primary-6;
    }
    .ant-tabs-ink-bar {
        background-color: $primary-6;
    }
    .ant-tabs-tab:hover {
        color: $primary-6;
    }
}
