/*********************Dark theme global css****************************************/

.ant-notification .ant-notification-notice {
    svg {
        @include themify($themes) {
            color: themed('notificationIconTable') !important;
            stroke: themed('notificationIconTable') !important;
        }
    }
    path {
        @include themify($themes) {
            fill: themed('notificationIconTable') !important;
        }
    }
}

.ant-input {
    @include themify($themes) {
        background: themed('inputColor') !important;
        border-color: themed('borderDarkColor') !important;
        color: themed('headingColor') !important;
    }
}

.ant-select-selector {
    @include themify($themes) {
        background: themed('inputColor') !important;
        border-color: themed('borderDarkColor') !important;
        color: themed('textColor') !important;
    }
}
.ant-select-dropdown
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    @include themify($themes) {
        background: themed('menuBackground') !important;
        color: themed('textColor') !important;
    }
}

.ant-select-item.ant-select-item-option:hover {
    @include themify($themes) {
        background: themed('menuBackground') !important;
        color: themed('textColor') !important;
    }
}
.ant-form-item-label > label {
    @include themify($themes) {
        color: themed('headingColor');
    }
}
.ant-input::placeholder {
    @include themify($themes) {
        color: themed('textColor') !important;
    }
}
.ant-select-selection-placeholder {
    @include themify($themes) {
        color: themed('textColor');
    }
}

.ant-table-body {
    @include themify($themes) {
        background: themed('bodyBackgroundColor');
    }
}

tr.ant-table-row {
    @include themify($themes) {
        color: themed('headingColor') !important;
        border-bottom: 1px solid themed('borderDarkColor') !important;
        border-color: themed('borderDarkColor') !important;
    }
}
.ant-table-wrapper .ant-table-tbody > tr > td {
    @include themify($themes) {
        border-bottom: 1px solid themed('borderDarkColor') !important;
        background: themed('bodyBackgroundColor');
    }
}
.ant-table-thead > tr > th {
    @include themify($themes) {
        background: themed('tableHeader');
        color: themed('headingColor');
        border: 0;
        border-bottom: 1px solid themed('borderDarkColor') !important;
    }
}
.ant-btn-default {
    @include themify($themes) {
        background: themed('bodyBackgroundColor');
        color: themed('headingColor');
        border-color: themed('borderDarkColor') !important;
        border-top: 1px solid themed('borderDarkColor') !important;
    }
}
.ant-btn-default:disabled {
    @include themify($themes) {
        background: themed('disableButtonNocil') !important;
        color: themed('borderDarkColor') !important;
        border-color: themed('disableButtonNocil') !important;
        border: 1px solid themed('disableButtonNocil') !important;
    }
}
.ant-btn-primary:disabled {
    @include themify($themes) {
        color: themed('headingColor') !important;
    }
}

.ant-table-wrapper .ant-table-tbody > tr.ant-table-row:hover > th,
.ant-table-wrapper .ant-table-tbody > tr.ant-table-row:hover > td,
.ant-table-wrapper
    .ant-table-tbody
    > tr
    > th.ant-table-cell-row-hover
    > td.ant-table-cell-row-hover {
    @include themify($themes) {
        background: themed('bodyCardBackgroundColor') !important;
    }
}

.ant-table-wrapper
    .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(
        .ant-table-row-expand-icon-cell
    ):not([colspan])::before {
    @include themify($themes) {
        background-color: themed('borderlineColor') !important;
    }
}

.ant-table-wrapper .ant-table-tbody > tr.ant-table-row-selected > td {
    @include themify($themes) {
        background: themed('bodyCardBackgroundColor') !important;
    }
}
.ant-table {
    @include themify($themes) {
        background: themed('bodyCardBackgroundColor') !important;
    }
}

.theme-dark .ant-table-wrapper .ant-table-tbody > tr > td {
    @include themify($themes) {
        background: themed('bodyBackgroundColor') !important;
    }
}
.ant-empty-description {
    @include themify($themes) {
        color: themed('textColor') !important;
    }
}
.ant-select-selection-item-content {
    @include themify($themes) {
        color: themed('headingColor');
    }
}

.ant-cascader-menu-item-content {
    @include themify($themes) {
        color: themed('textColor');
    }
}

/**********checkbox css for dark theme*****************/

.ant-checkbox .ant-checkbox-inner {
    @include themify($themes) {
        background-color: themed('bodyBackgroundColor') !important;
        border: 1px solid themed('whitecolor') !important;
    }
}

.ant-checkbox-checked .ant-checkbox-inner {
    @include themify($themes) {
        background-color: themed('brandColor') !important;
        border: 1px solid themed('brandColor') !important;
        border-color: themed('brandColor') !important;
    }
}

.ant-cascader-checkbox .ant-cascader-checkbox-inner {
    @include themify($themes) {
        background-color: themed('bodyBackgroundColor') !important;
        border: 1px solid themed('whitecolor') !important;
    }
}

.ant-cascader-checkbox-checked:not(.ant-cascader-checkbox-disabled):hover
    .ant-cascader-checkbox-inner {
    background-color: #2e53ea !important;
    border-color: #2e53ea !important;
    border: 1px solid #2e53ea !important;
}

.ant-cascader-checkbox-checked:not(.ant-cascader-checkbox-disabled)
    .ant-cascader-checkbox-inner {
    background-color: #2e53ea !important;
    border-color: #2e53ea !important;
    border: 1px solid #2e53ea !important;
}

.ant-popover-inner {
    @include themify($themes) {
        background: themed('bodyCardBackgroundColor') !important;
        border: 1px solid themed('borderDarkColor') !important;
        border-color: themed('borderDarkColor') !important;
    }
    .popover-content {
        @include themify($themes) {
            color: themed('headingColor');
        }
    }
    .ant-popover-inner-content {
        @include themify($themes) {
            color: themed('headingColor') !important;
        }
        label {
            span {
                @include themify($themes) {
                    color: themed('headingColor') !important;
                }
            }
        }
        svg {
            @include themify($themes) {
                stroke: themed('svgColor') !important;
                color: themed('svgColor') !important;
                fill: themed('svgColor') !important;
            }
        }
        path {
            @include themify($themes) {
                color: themed('svgColor') !important;
            }
        }
    }

    @include themify($themes) {
        stroke: themed('stroke') !important;
    }
}
.ant-popover-arrow:before {
    @include themify($themes) {
        background: themed('hoverMenuColor') !important;
    }
}
.ant-drawer .ant-drawer-content {
    @include themify($themes) {
        background: themed('bodyBackgroundColor') !important;
        border: 1px solid themed('borderDarkColor') !important;
        border-color: themed('borderDarkColor') !important;
    }
    .ant-drawer-title {
        @include themify($themes) {
            color: themed('headingColor') !important;
        }
    }
    .ant-drawer-close {
        @include themify($themes) {
            color: themed('svgColor') !important;
        }
    }
}

.ant-checkbox + span {
    @include themify($themes) {
        color: themed('textColor') !important;
    }
}

.ant-checkbox-checked:after {
    @include themify($themes) {
        border: none;
    }
}
.ant-collapse-content-box {
    @include themify($themes) {
        background: themed('bodyCardBackgroundColor') !important;
    }
}

.ant-typography {
    @include themify($themes) {
        color: themed('headingColor') !important;
    }
}

// will use later
// .ant-switch .ant-switch-inner {
//     @include themify($themes) {
//         background: themed('switchIcon') !important;
//     }
// }
.ant-switch-small.ant-switch-checked .ant-switch-inner {
    @include themify($themes) {
        background: themed('hoverColor') !important;
    }
}
.ant-modal .ant-modal-content {
    @include themify($themes) {
        background: themed('modalBackgroundColor') !important;
        border: 1px solid themed('borderDarkColor') !important;
        border-color: themed('borderDarkColor') !important;
        color: themed('headingColor') !important;
        .ant-modal-body span {
            @include themify($themes) {
                color: themed('headingColor') !important;
            }
        }
    }
}
.ant-table-wrapper .ant-table-thead th.ant-table-column-has-sorters:hover {
    @include themify($themes) {
        background: themed('tableHeader') !important;
    }
}

.assetsGroup {
    @include themify($themes) {
        .ant-card-body {
            background: themed('bodyBackgroundColor') !important;
            color: themed('headingColor') !important;
        }
    }
    .ant-tree {
        @include themify($themes) {
            background: themed('bodyCardBackgroundColor') !important;
            color: themed('headingColor') !important;
            border: 1px solid themed('borderDarkColor') !important;
        }
        .ant-tree-node-selected {
            @include themify($themes) {
                background: themed('bodyCardBackgroundColor') !important;
            }
        }
    }
    svg {
        @include themify($themes) {
            stroke: themed('svgColor') !important;
            color: themed('svgColor') !important;
        }
    }
    path {
        @include themify($themes) {
            color: themed('svgColor') !important;
        }
    }
}
.ant-card-bordered {
    @include themify($themes) {
        border: 1px solid themed('bordered-default') !important;
    }
}

.ant-divider-horizontal {
    @include themify($themes) {
        border-block-start: 1px solid themed('bordered-default') !important;
    }
}

.ant-btn-background-ghost:disabled {
    @include themify($themes) {
        background: themed('disableButton') !important;
        color: #ababab;
        border: 1px solid themed('borderDarkColor') !important;
    }
    // color: #ababab;
    // background: #2d2e33ab;
    // border-color: rgb(255 255 255 / 17%);
}

.ant-btn-default:not(:disabled):hover {
    @include themify($themes) {
        border-color: themed('brandColor') !important;
        background: themed('bodyBackgroundColor');
        color: themed('brandColor');
    }
}
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item-disabled:hover {
    @include themify($themes) {
        background: themed('menuBackground');
        color: themed('textColor') !important;
    }
}
.ant-btn-default.ant-btn-sm.selectedDayButtons {
    @include themify($themes) {
        border-color: themed('brandColor') !important;
        color: themed('brandColor') !important;
    }
}

.ant-input-outlined.ant-input-status-error:not(.ant-input-disabled):hover {
    @include themify($themes) {
        background: themed('bodyBackgroundColor') !important;
    }
}
.ant-input-outlined.ant-input-status-error:not(
        .ant-input-disabled
    ):focus-within {
    @include themify($themes) {
        background: themed('bodyBackgroundColor') !important;
    }
}
.ant-tabs .ant-tabs-tab {
    @include themify($themes) {
        color: themed('headingColor') !important;
    }
}

.ant-empty-description span {
    @include themify($themes) {
        color: themed('headingColor') !important;
    }
}

.ant-menu-light > .ant-menu .ant-menu-item a {
    @include themify($themes) {
        color: themed('headingColor') !important;
    }
}
.ant-select .ant-select-arrow .anticon > svg {
    @include themify($themes) {
        color: themed('svgColor') !important;
    }
}

.ant-select-selection-item {
    @include themify($themes) {
        color: themed('headingColor') !important;
    }
}

.ant-select-selection-item-remove svg {
    @include themify($themes) {
        color: themed('svgColor') !important;
    }
}

.sortArrows {
    svg {
        @include themify($themes) {
            color: themed('tableSvgColor') !important;
            stroke: themed('svgTableStrokeColor') !important;
        }
    }
    path {
        @include themify($themes) {
            fill: themed('tableSvgColor') !important;
        }
    }
}
.ant-picker-panel-container {
    @include themify($themes) {
        background: themed('bodyBackgroundColor') !important;
    }
}

.ant-picker-content {
    @include themify($themes) {
        color: themed('headingColor') !important;
    }
    th {
        @include themify($themes) {
            color: themed('headingColor') !important;
        }
    }
}
.ant-picker-cell-inner {
    @include themify($themes) {
        color: themed('headingColor') !important;
    }
}

.ant-picker-dropdown .ant-picker-header-view {
    @include themify($themes) {
        color: themed('headingColor') !important;
    }
}
.ant-picker-dropdown .ant-picker-header > button {
    @include themify($themes) {
        color: themed('headingColor') !important;
    }
}
.theme-dark .ant-select-selector::before {
    filter: invert(10);
}
.ant-picker-time-panel-cell-inner {
    @include themify($themes) {
        color: themed('headingColor') !important;
    }
}
.ant-picker-cell-in-view.ant-picker-cell-in-range::before {
    @include themify($themes) {
        color: themed('headingColor') !important;
        background: themed('datePickerColor') !important;
    }
}
.ant-picker-focused {
    @include themify($themes) {
        background: themed('bodyBackgroundColor') !important;
    }
}
.ant-picker-input input {
    @include themify($themes) {
        color: themed('headingColor') !important;
    }
}
.ant-picker-clear {
    .anticon-close-circle {
        @include themify($themes) {
            background: themed('bodyBackgroundColor') !important;
        }
    }

    svg {
        @include themify($themes) {
            color: themed('svgColor') !important;
            stroke: themed('svgColor') !important;
        }
    }
    path {
        @include themify($themes) {
            fill: themed('svgColor') !important;
        }
    }
}
.ant-picker-input input::placeholder {
    @include themify($themes) {
        color: themed('headingColor') !important;
    }
}
.ant-picker-range {
    @include themify($themes) {
        background: themed('bodyBackgroundColor') !important;
        border-color: themed('borderDarkColor') !important;
    }
}
.ant-picker-range-separator {
    svg {
        @include themify($themes) {
            color: themed('svgColor') !important;
        }
    }
}

.ant-picker-dropdown
    .ant-picker-time-panel-column
    > li.ant-picker-time-panel-cell-selected
    .ant-picker-time-panel-cell-inner {
    @include themify($themes) {
        color: themed('headingColor') !important;
        background: themed('datePickerColor') !important;
    }
}

.custom-apply {
    @include themify($themes) {
        background: themed('bodyBackgroundColor') !important;
        border-color: themed('borderDarkColor') !important;
    }
}

.ant-modal-close-x {
    svg {
        @include themify($themes) {
            stroke: themed('svgColor') !important;
        }
    }
    path {
        @include themify($themes) {
            fill: themed('svgColor') !important;
        }
    }
}

.theme-dark .ant-select-selector::before {
    filter: invert(10);
}

.ant-cascader {
    .ant-select-selection-item {
        @include themify($themes) {
            color: themed('headingColor') !important;
            background: themed('menuBackground') !important;
        }
    }
}

.ant-select .ant-select-clear:hover {
    svg {
        @include themify($themes) {
            color: themed('svgColor') !important;
            stroke: themed('svgColor') !important;
        }
    }
    path {
        @include themify($themes) {
            fill: themed('svgColor') !important;
        }
    }
}
.ant-dropdown-menu-title-content {
    @include themify($themes) {
        color: themed('headingColor') !important;
    }
}

.ant-dropdown-menu-item:hover {
    @include themify($themes) {
        color: themed('headingColor') !important;
        background: themed('menuBackground') !important;
    }
}

.ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
    @include themify($themes) {
        border-color: themed('brandColor') !important;
    }
}

.ant-badge-count {
    @include themify($themes) {
        background: themed('bodyBackgroundColor') !important;
        color: themed('headingColor') !important;
    }
}
.ant-modal-title {
    @include themify($themes) {
        color: themed('headingColor') !important;
    }
}

span.ant-select-selection-item span {
    color: #0a0a0a !important;
    @include themify($themes) {
        color: themed('headingColor') !important;
    }
}

.ant-table-wrapper
    .ant-table-container
    table
    > thead
    > tr:first-child
    > *:first-child {
    @include themify($themes) {
        background: themed('tableHeader') !important;
    }
}
.ant-table-wrapper .ant-table-thead > tr > td {
    @include themify($themes) {
        background: themed('tableHeader') !important;
        border-bottom: 1px solid themed('tableHeader') !important;
    }
}
.custom-validate {
    span {
        @include themify($themes) {
            color: themed('headingColor') !important;
        }
    }
}

.button-disabled {
    span {
        @include themify($themes) {
            color: themed('headingColor') !important;
        }
    }
}
.tagModal span.ant-modal-close-x:hover {
    @include themify($themes) {
        background: themed('hoverMenuColor') !important;
    }
}

.ant-modal .ant-modal-close-x:hover {
    @include themify($themes) {
        background: themed('hoverMenuColor') !important;
    }
}

.ant-select .ant-select-clear {
    @include themify($themes) {
        background: themed('bodyBackgroundColor') !important;
    }
    svg {
        @include themify($themes) {
            color: themed('svgColor') !important;
            stroke: themed('svgColor') !important;
        }
    }
    path {
        @include themify($themes) {
            fill: themed('svgColor') !important;
        }
    }
}
/****** ScrollBar***************/
/* Track */
::-webkit-scrollbar-track {
    @include themify($themes) {
        box-shadow: inset 0 0 5px themed('scrollBarColor') !important;
    }
}

/* Handle */
::-webkit-scrollbar-thumb {
    @include themify($themes) {
        background: themed('scrollBarColorTrack') !important;
    }
}
.ant-select-tree {
    @include themify($themes) {
        background: themed('scrollBarColor') !important;
        color: themed('headingColor') !important;
    }
    &:hover {
        @include themify($themes) {
            background: themed('hoverMenuColor') !important;
        }
    }
}
.ant-select-tree-title {
    @include themify($themes) {
        color: themed('headingColor') !important;
    }
}
.ant-select-tree-node-selected {
    @include themify($themes) {
        background-color: themed('menuBackground') !important;
    }
}
.anticon-search,
.anticon-close-circle {
    @include themify($themes) {
        color: themed('svgColor') !important;
    }
}
.ant-table-column-sorter {
    svg {
        @include themify($themes) {
            color: themed('tableSvgColor') !important;
            stroke: themed('svgTableStrokeColor') !important;
        }
    }
    path {
        @include themify($themes) {
            fill: themed('tableSvgColor') !important;
        }
    }
}

.ant-table-wrapper .ant-table-tbody > tr.ant-table-placeholder:hover > td {
    @include themify($themes) {
        background: themed('bodyBackgroundColor') !important;
    }
}

.ant-pagination-item-ellipsis {
    @include themify($themes) {
        color: themed('brandColor') !important;
    }
}
.ant-btn-default.activeButton {
    @include themify($themes) {
        color: themed('activeDeviceColor') !important;
        background-color: themed('activeDeviceBackground') !important;
        border: 1px solid themed('activeDeviceBorder') !important;
    }
}
.ant-btn-default.inactiveButton {
    @include themify($themes) {
        color: themed('inactiveDeviceColor') !important;
        background-color: themed('inactiveDeviceBackground') !important;
        border: 1px solid themed('inactiveDeviceBorder') !important;
    }
}
.ant-table-wrapper .ant-table-tbody > tr.ant-table-placeholder:hover > td {
    @include themify($themes) {
        background: themed('bodyBackgroundColor') !important;
    }
}

.ant-message-notice-content {
    @include themify($themes) {
        background: themed('bodyBackgroundColor') !important;
        color: themed('headingColor') !important;
        border: 1px solid themed('borderDarkColor') !important;
    }
    .ant-message-error {
        svg {
            @include themify($themes) {
                color: themed('errorIcon') !important;
                stroke: themed('errorIcon') !important;
            }
        }
        path {
            @include themify($themes) {
                fill: themed('errorIcon') !important;
            }
        }
    }
}

.theme-dark .ant-switch.ant-switch-checked {
    background: #1677ff;
}

/* Dark theme styles */
.theme-dark .ant-switch {
    background-color: #333; /* Background color in dark theme */
}

.theme-dark .ant-switch-checked {
    background-color: #1d8bdb; /* Background color when checked in dark theme */
}

.theme-dark .ant-switch-inner {
    color: #fff; /* Text color in dark theme */
}
.ant-pagination {
    @include themify($themes) {
        background-color: themed('bodyBackgroundColor') !important;
    }
    .ant-pagination-total-text {
        @include themify($themes) {
            color: themed('headingColor') !important;
        }
    }
    .ant-pagination-item a {
        @include themify($themes) {
            color: themed('headingColor') !important;
            background-color: themed('bodyBackgroundColor') !important;
            border: 1px solid themed('borderDarkColor') !important;
            border-radius: 4px;
        }
    }
    .ant-pagination-item-active a {
        @include themify($themes) {
            color: themed('headingColor') !important;
            background-color: themed('skipButton') !important;
        }
    }
}

.ant-picker {
    @include themify($themes) {
        background-color: themed('bodyBackgroundColor') !important;
    }
    svg {
        @include themify($themes) {
            color: themed('svgColor') !important;
            stroke: themed('svgColor') !important;
        }
    }
    path {
        @include themify($themes) {
            fill: themed('svgColor') !important;
        }
    }
}

.ant-menu .ant-menu-submenu-arrow {
    @include themify($themes) {
        color: themed('headingColor') !important;
    }
}

.ant-menu-submenu-popup > .ant-menu .ant-menu-submenu-arrow {
    @include themify($themes) {
        color: themed('headingColor') !important;
    }
}
