@import '../../../styles/global-settings.scss';

.groupsWrapper__noData {
    display: flex;
    flex-direction: column;
    padding: 120px;
    .EmptyDataComponent__buttons__customButton {
        padding-top: 30px;
    }
    .EmptyDataComponent__buttons__customButton button {
        width: 143px;
        margin: 0 auto;
    }
}

.groupsWrapper {
    .download-create-header {
        display: flex;
        .download-button {
            margin-right: 8px;
        }
    }
    padding: 12px;
    @include themify($themes) {
        background: themed('bacgroundBlack');
    }
    .ant-card-body {
        height: 100%;
        padding: 12px 24px !important;
        @include themify($themes) {
            background: themed('bodyBackgroundColor');
            border-color: themed('borderDarkColor') !important;
            border: 1px solid themed('borderDarkColor') !important;
            border-radius: 4px 4px 0 0;
        }
    }
    .ant-card {
        height: 75vh;
        margin: auto;
    }
}
.usersWrapper-head {
    justify-content: space-between;
}
.export-option {
    display: flex;
    gap: 10px;
}
