.chartProperties {
    .custom-drawer .ant-drawer-title {
        font-weight: bold;
        font-size: 16px;
    }
   .ant-switch.custom-switch {
        margin-right: 10px;
    }
    .close-icon {
        cursor: pointer;
    }
    .ant-collapse-header:nth-child(1n) {
        border-bottom: 1px solid #e0e0e0 !important;
    }
    .custom-collapse {
        border-radius: 8px !important;
        border: 1px solid #e0e0e0 !important;
    }
    .switch-section {
        display: flex;
        gap: 30px;
        align-items: center;
    }
    .functionWrapper {
        background: #2f54eb0d;
        padding: 10px;
        border-radius: 10px;
        display: flex;
        flex-wrap: wrap;
    }
    .custom-panel-header {
        background-color: #fff;

        border-radius: 8px;
        border-bottom: 1px solid #e6e6e6;
    }

    .switch-wrapper {
       
        margin-bottom: 15px;
    }

    .custom-input {
        border-radius: 4px;
        padding: 4px;
        font-size: 14px;
    }

    .custom-select {
        border-radius: 4px;
        font-size: 14px;
    }

    .ant-drawer-header-title {
        flex-direction: row-reverse;
    }

    .ant-collapse-header {
        flex-direction: row-reverse;
    }
    .trend-functions-wrapper {
        display: flex;
        margin-top: 20px; 
        flex-wrap: wrap;
    }

    .trend-function-switch {
        display: flex;
       
        align-items: center;
        margin-right: 10px;
        margin-bottom: 10px;
    }

    .switch-wrapper {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
    }
     .statistical-functions-wrapper {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        background: #2F54EB0D;
        padding: 10px;
        border-radius: 20px;
    }
}
