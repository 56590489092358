@import '../../../../styles/colorVariable';
@import '../../../../styles/global-settings.scss';

.adapterForm {
    width: 95%;
    margin: auto;
    .ant-select.ant-select-in-form-item {
        width: 100%;
    }
    .ant-input-password-icon {
        svg {
       @include themify($themes) {
           color: themed('svgColor') !important;
           stroke: themed('svgColor') !important;
       }      
   }
   path {
       @include themify($themes) {
           fill: themed('svgColor') !important;
       }
   }
}
}
.adapter.ant-select-dropdown {
    width: 43% !important;
}
div#label {
    border: 1px solid $border;
    border-radius: 5px;
    padding-left: 4px;
}
.ant-input-outlined.labelInput {
    border: none !important;
    width: 100%;
}
.ant-input-outlined:focus.ant-input-outlined:focus-within.labelInput {
    box-shadow: none !important;
}
.ant-input-affix-wrapper.ant-input-affix-wrapper {
    height: 40px !important;
}
.adapterConfirmPassord {
    color: $red-4;
}

.disabled {
    background: $disabled-bg;
    cursor: not-allowed;
    height: 32px;
}

.adapterLabelTag {
    @include themify($themes) {
        color: themed('headingColor') !important;
        background-color: themed('bodyBackgroundColor') !important;
        border: 1px solid themed('borderDarkColor') !important;
        }
    
    .ant-input-outlined.tag-input {
        width: 100% !important;
    }
    .ant-tag{
        @include themify($themes) {
            color: themed('headingColor') !important;
            background-color: themed('bodyBackgroundColor') !important;
            border: 1px solid themed('borderDarkColor') !important;
            }
            svg {
                @include themify($themes) {
                    color: themed('svgColor') !important;
                    stroke: themed('svgColor') !important;
                }      
            }
            path {
                @include themify($themes) {
                    fill: themed('svgColor') !important;
                }
            }
    }
}
