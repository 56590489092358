@import '../../../../styles/global-settings.scss';

.chooseAnyParamterWrapper {
    .ant-checkbox-checked .ant-checkbox-inner {
        background-color: $primary-6;
        border-color: $primary-6;
    }
    height: 693px;
    width: 100%;
    cursor: pointer;
    background-color: $white;
    @include themify($themes) {
        background: themed('bodyBackgroundColor') !important;
        border:1px solid themed('borderDarkColor') !important;
    }
    .chooseAnyParamterContainer {
        display: flex;
        flex-direction: column;
        &__heading {
            color: $title-45;
            font-size: 16px;
            font-weight: 500;
            line-height: 22px;
            letter-spacing: 0.96px;
            padding: 20px 20px 0px 20px;
            @include themify($themes) {
                color: themed('headingColor') !important;
            }
        }
        &__selectDeselect {
            margin-top: 20px;
            padding: 0px 20px 8px 0px;
            &__buttons {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                gap: 8px;

                .select-all {
                    color: $primary-4;
                }
                .clear-all {
                    color: $clear-all;
                }
                .horizontal {
                    width: 1px;
                    height: 13px;
                    background-color: black;
                }
            }
        }
    }
    &__accordion {
        .ant-collapse {
            border: none;
            .ant-collapse-header {
                border-radius: 0px !important;
                height: 40px;
                background-color: $neutral-3;
                @include themify($themes) {
                    background: themed('menuBackground') !important;
                }
            }
            .ant-collapse-content,
            .ant-collapse-header {
                .ant-collapse-header-text {
                    .custom-listItem {
                        padding-left: 8px;
                        .custom-listItem_label {
                            display: flex;
                            gap: 8px;
                            justify-content: flex-start;
                        }
                    }
                }
            }
            .ant-collapse-content-box {
                padding: 0;
                .custom-listItem {
                    @include themify($themes) {
                        background: themed('bodyBackgroundColor') !important;
                    }
                    padding: 16px 20px;
                    height: 40px;
                    background-color: $white;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .custom-listItem_label {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        gap: 8px;
                        .ant-checkbox-wrapper {
                            width: 16px;

                            span:nth-child(2) {
                                display: flex;
                                justify-content: space-between;
                            }
                        }
                        
                    }
                }
                .custom-listItem:hover {
                    background-color: $lightGreyColor7;
                    box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.16);
                }
               
            }
        }
    }
}
