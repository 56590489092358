
.qrGenerateSeparator {
    display: flex;
    align-items: center;
    padding-left: 12px;
    padding-top: 12px;
}
.generateQRFooterButtons{
    justify-content: center;
    gap: 10px;
}

.qrGenerateField {
    padding-top: 12px;
}

.printQRForm {
    height: calc(100vh - 390px);
    overflow: auto;
}