@import '../../../styles/global-settings.scss';

.rolesWrapper,
.ant-input-affix-wrapper {
    gap: 10px;
    padding: 12px;
    .ant-card {
        .ant-card-body {
            padding: 12px 24px 24px 24px;
        }
    }

    &__noData {
        display: flex;

        flex-direction: column;

        padding: 120px;
        .EmptyDataComponent__buttons__customButton {
            padding-top: 30px;
        }
        .EmptyDataComponent__buttons__customButton button {
            width: 143px;
            margin: 0 auto;
        }
    }

    .ant-card .ant-card-body {
        padding: 12px 24px;
    }

    &__rowHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0px;
    }

    &__button {
        width: 131px;
        height: 32px;
    }

    &__search {
        width: 300px;
        height: 40px;
        border: none;
        border-radius: 8px;
        input.ant-input,
        .ant-select-selector {
            background: $neutral-6 !important;
            border: none !important;
            padding: 4px;
            width: 300px;
            height: 40px;
            @include themify($themes) {
                background: themed('bodyBackgroundColor') !important;
                border-color: themed('borderDarkColor') !important;
                border: 1px solid themed('borderDarkColor') !important;
            }
            .ant-select-selection-placeholder {
                @include themify($themes) {
                    color: themed('textColor');
                }
            }
        }
        .ant-select-selector::before {
            content: url('/assets/icons/searchIcon.svg');
            font-size: 5px;
            margin-left: 4px;
        }
        span {
            color: $title-45;
        }
        span.ant-select-selection-placeholder {
            margin-left: 20px;
        }
        .ant-select-arrow {
            display: none;
        }
    }
    &__rolesListPagination {
        padding-top: 12px;
    }
    &__rolesList {
        padding-top: 12px;
        height: calc(100vh - 305px);
        overflow-y: scroll;
    }
    .ant-card {
        height: 75vh;
        margin: auto;
    }
    .ant-card-body {
        height: 100%;
        padding: 12px 24px !important;
        @include themify($themes) {
            background: themed('bodyBackgroundColor');
            border-color: themed('borderDarkColor') !important;
            border: 1px solid themed('borderDarkColor') !important;
            border-radius: 4px 4px 0 0;
        }
    }

    @include themify($themes) {
        background: themed('bacgroundBlack');
    }
}
