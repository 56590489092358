@import '../../../styles/colorVariable';
@import '../../../styles/global-settings.scss';

.createAlertWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 10px;
    &__cardWrapper {
        width: 100%;
        margin-top: 2px;
        border-radius: 0px;
    }
    &__headerWrapper {
        padding: 16px;
        width: 100%;
        @include themify($themes) {
            background: themed('bodyBackgroundColor') !important;
        }
    }
    &__tabsWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        padding: 20px;
        border-top: 0.5px solid $border;
        background-color: #ffffff;
        @include themify($themes) {
            background: themed('bodyBackgroundColor') !important;
        }
    }
    .tabButton {
        margin: 0px 10px;
        color: $title-85 !important;
        background-color: transparent;
        border-color: $neutral-5 !important;
        box-shadow: none;
        border-radius: 20px !important;
        @include themify($themes) {
            color: themed('headingColor') !important;
        }
    }
    &__outerCard {
        margin: 10px;
        border-radius: 2px;
        @include themify($themes) {
            background: themed('bodyBackgroundColor') !important;
            color: themed('headingColor') !important;
        }
    }
    &__innerCard {
        border-radius: 2px;
        .ant-card-body {
            padding: 16px 12px;
            @include themify($themes) {
                background: themed('bodyBackgroundColor') !important;
            }
        }
    }
    &__stepWrapper {
        height: calc(100vh - 354px);
        overflow: scroll;
        @include themify($themes) {
            background: themed('bodyBackgroundColor') !important;
        }
    }
    &__stepOne {
        display: flex;
        flex-direction: column;
        gap: 12px;
    }
    &__stepOneInfoDesc {
        width: 98%;
    }
    &__stepTwoInfo {
        gap: 12px;
    }
    &__alertHeading {
        padding: 0px 0px 10px 16px;
    }
    &__stepConsequences {
        @include flexprob;
        background-color: #fbfbfb;
        height: 48px;
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        margin-bottom: 12px;
        @include themify($themes) {
            background: themed('bodyBackgroundColor') !important;
            color: themed('headingColor') !important;
        }
    }
    .upperValInput {
        padding-left: 6px;
    }
    .lowerValInput {
        padding-right: 6px;
    }
    &__alertRadioGroup {
        display: flex;
        justify-content: space-evenly;
        .ant-radio-inner {
            width: 24px;
            height: 24px;
        }
        .ant-radio-disabled .ant-radio-inner::after {
            @include themify($themes) {
                background-color: themed('textColor') !important;
            }
        }
    }
    &__stepThreeWrapper {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    &__stepThreeInfo {
        gap: 13px;
    }
    &__alertRadioLabel {
        display: flex;
        padding-left: 32px;
    }
    &__selectDropdown {
        width: 100%;
        .ant-select-selector {
            border-radius: 2px;
            border-color: #d9d9d9 !important;
        }
        .notificationBoolean {
            display: flex;
            justify-content: flex-end;
            @include themify($themes) {
                color: themed('headingColor') !important;
            }
        }
    }
    &__selectCascader {
        width: 100%;
        .ant-select-selector {
            border-radius: 2px;
        }
    }
    &__selectDropdownAggregated {
        width: 30% !important;
        .ant-select-selector {
            border-radius: 0px 2px 2px 0px !important;
        }
    }
    &__selectInput {
        width: 100%;
        .ant-input {
            border-radius: 2px 0px 0px 2px !important;
        }
    }
    &__selectText {
        display: flex;
        justify-content: center;
    }
    &__clearTextDiv {
        display: flex;
        justify-content: flex-end;
        color: #d80000;
        padding-right: 12px;
        height: 16px;
        font-size: 10px;
        .deleteText {
            cursor: pointer;
        }
        .clearText {
            cursor: pointer;
        }
    }
    &__heading {
        display: flex;
        width: 100%;
    }
    &__label {
        padding-bottom: 4px;
        color: $title-85;
        @include themify($themes) {
            color: themed('headingColor') !important;
        }
        span {
            color: #d80000;
        }
    }
    &__nameInput {
        width: 96%;
        border-radius: 2px;
    }
    &__dropdown {
        width: 96%;
        .ant-select-selector {
            border-radius: 2px;
            border-color: #d9d9d9 !important;
        }
    }
    &__aggregatedInput {
        border-radius: 2px;
    }
    &__aggregatedCol {
        display: flex;
    }
    &__descriptionInput {
        border-radius: 2px;
    }
    &__stepTitle {
        @include flexprob;
        gap: 10px;
        margin-bottom: 24px;
        @include themify($themes) {
            color: themed('headingColor') !important;
        }
    }
    &__headingName {
        @include textfontstyle;
        color: $title-85;
        @include themify($themes) {
            color: themed('headingColor') !important;
        }
    }
    &__alertName {
        justify-content: center;
        display: flex;
        align-items: center;
        padding: 2px;
    }
    &__backIcon {
        width: 30px;
        height: 32px;
        padding: 5px 0 0 8px;
        cursor: pointer;
        svg {
            @include themify($themes) {
                color: themed('svgColor') !important;
                stroke: themed('svgColor') !important;
            }
        }
        path {
            @include themify($themes) {
                fill: themed('svgColor') !important;
            }
        }
    }
    &__headingLabel {
        width: 90%;
        display: flex;
    }
    &__editButton {
        width: 12%;
        text-align: end;
    }
    &__createRowHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 8px 20px 10px 20px;
    }
    &__verticalLineDiv {
        @include flexprob;
        padding-top: 16px;
        .plusIconButton {
            font-size: 24px;
            color: $neutral-5;
        }
    }
    &__conditionalRow {
        @include flexprob;
    }
    &__conditionalCol {
        padding-bottom: 12px;
    }
    &__verticalButtonsDiv {
        @include flexprob;
        padding-top: 16px;
        .condtionalButtonDiv {
            width: 120px;
        }
        .condtionalAndButton {
            width: 50%;
            border-radius: 2px 0px 0px 2px !important;
            border: 1px solid $neutral-5;
            background-color: $neutral-4;
            color: $title-45;
        }
        .condtionalOrButton {
            border-radius: 0px 2px 2px 0px !important;
            border: 1px solid $neutral-5;
            background-color: $neutral-4;
            color: $title-45;
        }
    }
    &__verticalButtonsSecondDiv {
        @include flexprob;
        padding-top: 16px;
    }
    .dropdownButton {
        color: $title-45;
    }
    .stepperButton {
        background-color: $primary-4;
        pointer-events: none;
    }

    .ant-collapse > .ant-collapse-item {
        margin: 10px 0px;
        background-color: rgba(0, 0, 0, 0.02);
        border: 1px solid #d9d9d9;
        border-radius: 8px;
    }

    .ant-collapse {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        color: rgba(0, 0, 0, 0.88);
        font-size: 14px;
        list-style: none;
        background-color: white !important;
        border: 0px !important;
        border-bottom: 0 !important;
        border-radius: 0px !important;
        box-shadow: none !important;
    }
    .ant-divider-vertical {
        margin: 2px 8px;
    }
    .ant-select.ant-select-in-form-item {
        width: 100%;
    }
    .ant-form-item {
        margin-bottom: 0px;
    }
    .ant-anchor-link-active {
        .tabButton {
            margin: 0px 10px;
            color: $primary-3 !important;
            background-color: transparent;
            border-color: $primary-3 !important;
            box-shadow: none;
            border-radius: 20px !important;
        }
    }
    .ant-anchor-ink {
        background-color: transparent !important;
    }
    .ant-input {
        border-color: #d9d9d9 !important;
    }
    &__button {
        .ant-btn {
            padding: 0px;
        }
        width: 131px;
        height: 32px;
    }
    &__createRoleFooter {
        @include flexprob;
        background: $neutral-1;
        width: 100%;
        padding: 12px 24px 16px 24px;
        z-index: 1;
        gap: 8px;
        border-top: 0.5px solid $border;
        @include themify($themes) {
            background: themed('bodyBackgroundColor') !important;
        }
    }
    &__footerButtonWrapper {
        width: 110px;
    }
}

.createAttributeWrapper__header {
    display: grid;
    grid-template-columns: 1fr 133px 124px;
    grid-column-gap: 8px;
    align-items: center;
}

.mandatoryClass {
    color: $red-3;
}

.notificationWrapper {
    display: flex;
    justify-content: flex-end;
}
.createAlertWrapper__verticalButtonsDiv::before {
    content: '';
    position: absolute;
    height: 64px;
    width: 1px;
    border-left: 1px dashed $neutral-5;
}
.createAlertWrapper__verticalButtonsSecondDiv::before {
    content: '';
    position: absolute;
    height: 32px;
    width: 1px;
    border-left: 1px dashed $neutral-5;
}
