@import '../../../../../../styles/global-settings.scss';
.kpiListing {
    height: 80%;
    margin-top: 5px;
    overflow-y: scroll;

    &__container {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        justify-content: center;
        &__dataItem {
            padding: 2px 12px 2px 12px;
            border-bottom: 1px solid $neutral-5;
            gap: 4px;
            width: 100%;
            @include themify($themes) {
                color: themed('headingColor') !important;
            }
        }
        .noResultOnSearch {
            width: 100%;
            height: 100%;
            @include flexprob;
        }
    }
}
