@import '../../../../styles/global-settings.scss';

.ant-modal-root .ant-modal-mask {
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(7.5px);
}
.parentChildActivationModal.save {
    width: 509px !important;
}
.parentChildActivationModal.delete {
    width: 593px !important;
}
.parentChildActivationModal {
    .ant-modal-content {
        padding: 0px !important;
        .ant-modal-body {
            @include flexprob;
            flex-direction: column;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            span {
                @include textfontstyle;
                color: $title-85;
                text-align: left; 
                @include themify($themes) {
                    color: themed('headingColor') !important;
                } 
            }
        }
        .ant-modal-close {
            top: 26px !important;
        }
        .ant-modal-footer {
            @include textfontstyle;
            text-align: center;
            margin-top: 0px !important;
            padding: 24px 0px;

            .ant-btn-primary {
                padding: 4px 0px;
                width: 109px;
            }
            .ant-btn-default {
                width: 109px;
            }
        }
    }
}

.ant-switch .ant-switch-inner {
    width: 44px !important;
}

.ant-switch .ant-switch-loading-icon.anticon {
    width: auto !important;
    line-height: 0px !important;
}
.modalheader {
    display: flex;
    padding: 30px 20px 15px 15px;
}
.modalSearch {
    width: 224px;
    background-color: $date-range;
    svg{    
        @include themify($themes) {
        color: themed('svgColor') !important;
    }}
    path{
        @include themify($themes) {
            fill: themed('svgColor') !important;
        }  
    }  
}
.modalText {
   font-weight: 400;
   font-size: 20px;
}
.modalDivider {
    background-color: $date-range;
    width: 100%;
    height: 48px;
    margin-bottom: 10px
}
.modalCounterText{
    color: $title-45 !important;
}
.modalDividerText {
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    @include themify($themes) {
        background-color: themed('bodyBackgroundColor') !important;
        border-color: themed('borderDarkColor') !important;
        border: 1px solid themed('borderDarkColor') !important;
    }  
}
.ant-table-placeholder{
        @include themify($themes) {
        background: themed('bodyBackgroundColor') !important;
        }
}
.modalTable {
    padding-left: 20px;
    padding-right: 20px;
    height: 426px;
}
.ant-input-affix-wrapper.modalSearch {
    margin-top: 30px;
    background: $neutral-6;
    border-color: $neutral-6;
    height: 40px;

    input.ant-input {
        background: $neutral-6;
        height: 22px;
        bottom: 5px;
    }
}
.parentChildActivationModal
    .ant-modal-content
    .ant-modal-body
    span
    .unselectText {
    font-weight: 500;
    color: $red-3;
    margin-left: 10px;
    cursor: pointer;
        @include themify($themes) {
        color: themed('inactiveDeviceBorder') !important;
        }
}
.parentChildActivationModal
    .ant-modal-content
    .ant-modal-body
    span
    .selectText {
    font-weight: 500;
    color: $primary-6;
    margin-left: 10px;
    cursor: pointer;
}
.parentChildActivationModal
    .ant-modal-content
    .ant-modal-body
    span
    .boldText {
    font-weight: 700;
    color: $title-45;
}
