@import '../../styles/fontStyles';
@import '../../styles/mixins';
@import '../../styles/global-settings.scss';

.deviceManagement {
    width: 100%;
    background: white;
    @include themify($themes) {
        background-color: themed('bodyBackgroundColor') !important;
    }
    &__headerWrapper {
        padding: 21px 24px;
    }
    &__tabsWrapper {
        @include textfontstyle;
        margin-top: 16px;
    }
    .ant-tabs-nav {
        margin: 0px 0px 0px 24px; 
    }
    .ant-tabs .ant-tabs-tab-btn {
        @include themify($themes) {
            color: themed('headingColor') !important;
        }
    }
    .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: $primary-6;
    }
    .ant-tabs-ink-bar {
        background-color: $primary-6;
    }
    .ant-tabs-tab:hover {
        color: $primary-6;
    }
}
// NOTE- We have used negative values here because, we have to adjust the position of popover with respect to 3 dots and this is the only way possible.
.devicesTablePopover.ant-popover-placement-bottomLeft {
    .ant-popover-arrow {
        left: 0px !important;
        top: -11px !important;
    }
    .ant-popover-content {
        left: -13px !important;
        top: -11px !important;
    }
}
.devicesTablePopover.ant-popover-placement-topLeft {
    .ant-popover-arrow {
        left: 0px !important;
        bottom: -11px !important;
    }
    .ant-popover-content {
        left: -13px !important;
        top: 11px !important;
    }
}
