@import '../../styles/global-settings.scss';

.qrCodeGeneration {
    width: 100%;
    background: white;
    &__headerWrapper {
        padding: 20px 5px 20px 24px;
        @include themify($themes) {
            background: themed('bodyBackgroundColor');
            border-bottom: 1px solid themed('borderDarkColor') !important;
        }
    }
    &__tabsWrapper {
        @include textfontstyle;
        margin-top: 16px;
    }
    @include themify($themes) {
        background: themed('headerFooterBackground');
        color: themed('textColor');
        .ant-tabs-nav {
            margin-left: 24px;
        }
    }
    .ant-tabs {
        @include themify($themes) {
            background: themed('bodyBackgroundColor');
        }
    }
    .ant-tabs-nav {
        margin: 0px 0px 0px 24px !important;
        @include themify($themes) {
            background: themed('bodyBackgroundColor');
        }
    }
    .countAnalytics__countInfo {
        svg{
            width: 24px;
            height: 24px;
        }
    }
}
.customButton.custom-add.users.button-disabled :hover {
    cursor: not-allowed;
    background-color: rgba(0, 0, 0, 0.04) !important;
}
