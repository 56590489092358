@import '../../styles/global-settings.scss';

.ant-image-img {
    border-radius: 50% !important;
}
.layoutWrapper {
    min-height: 100vh;
}
.ant-layout {
    aside.ant-layout-sider,
    .ant-layout-sider-collapsed {
        transition: all 0.2s;
    }
    .ant-layout-sider-children {
        background: #f1f3fc;
        @include themify($themes) {
            background: themed('bodyCardBackgroundColor');
        }
        display: flex;
        flex-direction: column;
        .empty-space {
            flex-grow: 1;
        }
        .switch-module {
            @include flexprob;
            justify-content: flex-end !important;
            cursor: pointer;
            color: $primary-2;
            padding: 16px 16px;
        }
    }

    .ant-layout-sider-collapsed {
        width: 47px !important;
        flex: 0 0 47px !important;
        max-width: 47px !important;
        min-width: 47px !important;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
        position: static;
        z-index: 10;
    }
    .expand {
        width: 273px !important;
        flex: 0 0 273px !important;
        max-width: 273px !important;
        min-width: 273px !important;
        box-shadow: 4px 0px 6px $title-08;
        position: static;
        z-index: 10;
    }
    header.ant-layout-header.header {
        height: 48px !important;
        box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.06);
        background-color: white;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        align-items: center;
        align-content: center;
        padding: 7px 20px;
        position: static;
        z-index: 10;
        @include themify($themes) {
            background: themed('bodyBackgroundColor');
            color: themed('textColor');
            border-bottom: 1px solid themed('borderDarkColor');
        }
        .header-setting-container {
            display: flex;
            justify-content: flex-end;
            padding: 8px 0px;
            height: 47px;
            align-items: center;
            .braboLogo-container {
                @include flexprob;
                gap: 8px;
                height: 48px;
                padding-right: 20px;
                img {
                    height: 30px;
                }
            }

            .customer-container {
                @include flexprob;
                gap: 8px;
                height: 48px;
                padding-right: 12px;
                img {
                    border-radius: 50%;
                    height: 30px;
                }
            }
            .profile-container {
                .ant-image img {
                    width: 50% !important;
                    height: 50% !important;
                    border-radius: 50% !important;
                    object-fit: contain;
                }

                .ant-image {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                padding-left: 26px;
                gap: 16px;
                .profile-setting {
                    display: flex;
                }
            }
            .ant-divider-vertical {
                height: 33px;
                border-inline-start: 1px solid rgba(0, 0, 0, 0.1);
                @include themify($themes) {
                    border-color: themed('borderDarkColor') !important;
                }
            }
        }
        .logout {
            color: $black-1;
        }
        .ant-breadcrumb-link {
            @include themify($themes) {
                color: themed('textColor');
            }
        }
        .ant-breadcrumb-separator {
            @include themify($themes) {
                color: themed('textColor');
            }
        }
    }
    .site-layout-content {
        z-index: 5;
        @include themify($themes) {
            background: themed('bodyBackgroundColor');
        }
    }
    ol li:last-child {
        span {
            @include themify($themes) {
                color: themed('brandColor') !important;
            }
        }
    }
}

.user-profile-popover-content {
    width: 200px;
    display: flex;
    flex-flow: column;
    align-items: center;
}
.popover-content {
    display: flex;
    flex-direction: column;
    place-items: center;
}
.signOut {
    align-items: center;
}

.ant-layout-sider-children svg {
    @include themify($themes) {
        stroke: themed('stroke') !important;
        color: themed('svgColor');
    }
}

.theme-dark .ant-layout-sider-children svg path {
    stroke: #ffffff !important; /* Set stroke color to white */
}

.theme-dark .ant-layout-sider-children svg path,
.theme-dark .ant-layout-sider-children svg ellipse,
.theme-dark .ant-layout-sider-children svg circle {
    stroke: #ffffff !important; /* Set stroke color to white */
    stroke-opacity: 1;
}

.ant-menu-vertical svg {
    @include themify($themes) {
        stroke: themed('stroke') !important;
        color: themed('svgColor');
    }
}

.theme-dark .ant-menu-vertical svg path,
.theme-dark .ant-menu-vertical svg ellipse,
.theme-dark .ant-menu-vertical svg circle {
    stroke: #ffffff !important; /* Set stroke color to white */
    stroke-opacity: 1;
}

.ant-menu-submenu > .ant-menu {
    @include themify($themes) {
        background: themed('menuBackground') !important;
        color: themed('textColor');
    }
}

.ant-menu-vertical svg {
    @include themify($themes) {
        stroke: themed('stroke') !important;
        color: themed('svgColor');
    }
}

.theme-dark .ant-menu-vertical svg path,
.theme-dark .ant-menu-vertical svg ellipse,
.theme-dark .ant-menu-vertical svg circle {
    stroke: #ffffff !important; /* Set stroke color to white */
    stroke-opacity: 1;
}

.ant-menu-submenu > .ant-menu {
    @include themify($themes) {
        background: themed('menuBackground') !important;
        color: themed('textColor');
    }
}
.side-menu-tabs-expanded
    li.ant-menu-submenu.ant-menu-submenu-vertical.ant-menu-submenu-selected {
    svg {
        stroke: white;
    }
    path {
        fill: white;
    }
}
 
 
.ant-menu-submenu-title {
    display: flex !important;
    justify-content: flex-start !important;
    align-items: center !important;
}
.ant-menu-item {
    display: flex !important;
    justify-content: flex-start;
    align-items: center;
}
 
.side-menu-tabs-expanded
    li.ant-menu-submenu.ant-menu-submenu-vertical.ant-menu-submenu-selected {
    svg {
        stroke: white;
    }
    path {
        fill: white;
    }
}

// NOTE- scrol/ is added to fix he issue, whenever we zoom the screen, a scroll bar should come in side nav
@media only screen and (max-height: 750px) {
    .sider-container-collapsed,
    .ant-menu-light {
        overflow-y: scroll;
        height: 500px;
    }
}
 
.ant-menu .ant-menu-item .ant-menu-item-icon,
.ant-menu .ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu .ant-menu-item .anticon,
.ant-menu .ant-menu-submenu-title .anticon {
    min-width: 22px !important;
}
.ant-menu-item .ant-menu-item-active .ant-menu-item-selected {
    svg {
        stroke: white !important;
    }
    path {
        fill: white !important;
    }
}