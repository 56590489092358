$themes: (
// will use this later
    // light: (
    //     // bodyBackgroundColor: #ffffff,
    //     //  textColor: #000
    //     // borderCardColor: #f0f0f0,
    //     // borderSearch: none,
    //     // borderDarkColor: none,
    //     // lightWhiteColor: none,
    //     // whitecolor: none,
    //     // brandColor: none,
    //     // bordered-default:none
    //     //loginBackgroundColor:#ffffff
    // ),
    dark: (
        bodyBackgroundColor: $darkBlue1,
        bodyCardBackgroundColor: $darkBlue,
        bacgroundBlack: $black-8,
        borderCardColor: $lightGreyOpacity,
        borderlineColor: $lightGreyOpacity1,
        borderSearch: $lightGreyOpacity,
        borderDarkColor: $lightGreyOpacity2,
        textColor: $lightGreyOpacity3,
        headingColor: $lightGreyOpacity4,
        lightWhiteColor: $lightGreyOpacity5,
        hoverColor: $primary-hover,
        dropdownText: $black-2,
        stroke: $neutral-1,
        svgColor: $neutral-1,
        inputColor: $darkBlue,
        hoverMenuColor: $darkBlue4,
        menuBackground: $ligtDarkBlue,
        tableHeader: $lightBlue,
        whitecolor: $neutral-1,
        brandColor: $primary-6,
        LightSvgColor: $netural-8,
        modalBackgroundColor: $darkBule2,
        disableButton: $darkBlue3,
        skipButton: $primary-4,
        switchIcon: $darkBlue5,
        bordered-default:$gray-7,
        tableSvgColor:$gray-8,
        svgTableStrokeColor:$lightGreyOpacity6,
        scrollBarColor:$darkBlue6,
        datePickerColor:$darkBlue7,
        formulaButtonOperator:$darkBlue8,
        activeDeviceColor:$green-3,
        activeDeviceBorder:$polar-green-6,
        inactiveDeviceColor:$red-4,
        inactiveDeviceBorder:$red-3,
        nocilGraphbodyColor:$darkBlueOpacity,
        kpiFormulaHeader:$darkBlueOpacity9,
        scrollBarColorTrack:$gray10,
        errorIcon:$red-3,
        notificationIconTable:$red-4,
        disableButtonNocil:$disableGrey,
    ),
);

@mixin themify($themes) {
    @each $theme, $map in $themes {
        .theme-#{$theme} & {
            $theme-map: () !global;
            @each $key, $submap in $map {
                $value: map-get(map-get($themes, $theme), '#{$key}');
                $theme-map: map-merge(
                    $theme-map,
                    (
                        $key: $value,
                    )
                ) !global;
            }
            @content;
            $theme-map: null !global;
        }
    }
}

@function themed($key) {
    @return map-get($theme-map, $key);
}
