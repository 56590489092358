@import '../../../styles/global-settings.scss';

.communicationChannel {
    @include flexprob;
    flex-direction: column;
    gap: 25px;
    padding: 10px;
    &__card {
        margin: auto;
        background: white;
        width: 70%;
        border-radius: 16px;
        @include flexprob;
        // height: 95px;
        cursor: pointer;
        @include themify($themes) {
            background: themed('bodyBackgroundColor') !important;
            color: themed('headingColor') !important;
            border: 1px solid themed('borderDarkColor') !important;
        }
    }
    .active {
        border: 1px solid $orange-1;
        background: rgba(255, 239, 229, 1);
        @include themify($themes) {
            border: 1px solid $orange-1!important;
            background: rgba(255, 239, 229, 1)!important;
            color: themed('bacgroundBlack') !important;
        }
        &__label{
            @include themify($themes) {
                color: themed('bacgroundBlack') !important;
            }
        }
    }
    &__content {
        height: 100%;
        @include flexprob;
        flex-direction: column;
        gap: 10px;
        margin: 20px;
    }
    .ant-spin-spinning {
      @include flexprob;
        height: 100vh;
    }
   
}
