@import '../../styles/fontStyles';
@import '../../styles/mixins';
@import '../../styles/global-settings.scss';

.notification {
    &__text{
        @include themify($themes) {
            color: themed('headingColor') !important;

     }
    }
    width: 100%;
    &__headerWrapper {
        padding: 20px 5px 20px 24px;
        background: white;
        @include themify($themes) {
            background: themed('bodyBackgroundColor') !important;
            border-bottom: 1px solid themed('borderDarkColor') !important;
     }
    }
    &__content {
        padding: 20px 15px 20px 15px;
        @include themify($themes) {
            background: themed('bacgroundBlack') !important;
     }
        // height: calc(100vh - 10%);
        // overflow: hidden;
       
    }
  .notification__card{
        height: calc(100vh - 200px);
     }
    &__data {
        height: calc(100vh - 200px);
        @include themify($themes) {
            background: themed('bodyBackgroundColor') !important;
            border: 1px solid themed('borderDarkColor') !important;
            border-radius: 3px;
     }
    }
    &__dataModel {
        background-color: $gray-11;
        position: static;
        z-index: 3;
        border-right: 1px solid $disabled-text;
        height: calc(100vh - 203px);
        overflow-y: scroll;
        @include themify($themes) {
            background: themed('bodyBackgroundColor') !important;
            color: themed('headingColor') !important;
            border-right: 1px solid themed('borderDarkColor') !important;
     }
    }
     .dataModel .active {
        @include themify($themes) {
        border: 1px solid rgba(255, 92, 0, 0.85) !important;
        background: rgb(255, 239, 229) !important;
        color: themed('bacgroundBlack') !important;
        }
    }
    .cardDefaultClass {
            @include themify($themes) {
                background: themed('bodyCardBackgroundColor') !important;
                color: themed('headingColor') !important;
                border: 1px solid themed('borderDarkColor') !important;
            }
    }
    .dataModel__card {
    @include themify($themes) {
        background-color: themed('bodyCardBackgroundColor') !important;
        border: 1px solid themed('borderDarkColor') !important;
    }
    
}
    &__card {
        .ant-card-body {
            padding: 0px !important;
        }
    }
    &__tableWrapper {
        height: 100%;
        @include themify($themes) {
            background: themed('bodyBackgroundColor') !important;
     }
        .customPagination ul.ant-pagination {
            width: 80% !important;
            left: 20% !important;
        }
        
    }
    &__tableWrapperPadding {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 25px 25px 0px 25px;
        .attribute__backIcon{
            cursor: pointer;
            svg {
                @include themify($themes) {
                    color: themed('svgColor') !important;
                    stroke: themed('svgColor') !important;
                }
            }
            path {
                @include themify($themes) {
                    fill: themed('svgColor') !important;
                }
            }
        }     
    }
    &__tableHeaderWrapper {
        display: flex;
        justify-content: space-between;
    }
   
}


