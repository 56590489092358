@import '../../../styles/global-settings.scss';

.attributeFormWrapper {
    width: 100%;
    display: flex;
    justify-content: center;

    &__form {
        width: 100% !important;
        height: 100%;
        .ant-form-item-control-input {
            width: 80% !important;
        }
        .ant-select.ant-select-in-form-item {
            width: 80% !important;
        }
        .DescriptionBox {
            .ant-form-item-control-input {
                width: 90% !important;
            }
        }

        .ant-select-dropdown {
            width: 237px !important;
        }
    }
    label.ant-form-item-required {
        width: 100%;
    }
    .disabled {
        cursor: not-allowed;
        color: grey;
        svg {
            fill: grey;
        }
    }

    &__imageUpload {
        @include flexprob;
        flex-direction: column;
        color: $disabled-text;
        gap: 8px;
        margin: 20px 0px;
    }
    &__wrapper {
        width: 70% !important;
        margin: auto;
        padding: 20px 0;
    }
    .ant-select-selection-item {
        button {
            display: none;
        }
    }
}

.labelRowForAdd {
    align-items: center;
    width: 80%;
    line-height: 4px;
}
.addItemsButton {
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    svg{    
        @include themify($themes) {
        color: themed('svgColor') !important;
    }}
    path{
        @include themify($themes) {
            fill: themed('svgColor') !important;
        }  
    }
}

span.ant-select-tree-title {
    color: black;
}
.ant-select-dropdown .ant-select-item-option-content {
    gap: 0 !important;
    justify-content: space-between;
}
.uomDeleteClass {
    border: none;
    background: none;
}
.ant-select-selection-item {
    .uomDeleteClass {
        display: none;
    }
}
