@import '../../styles/global-settings.scss';

.operatorEntry {
    .operatorEntryWrapperScrolContent {
        overflow-y: scroll;
        height: calc(100vh - 165px);
        @include themify($themes) {
        border-top: 1px solid themed('borderDarkColor') !important;
        }
    }
    .operatorEntryWrapper {
        height: calc(100vh - 196px);
        @include fontTextStyle;

        &__cardContent {
            height: 100%;
            @include flexprob;
            @include themify($themes) {
            border: 1px solid themed('borderDarkColor') !important;
            }
        }
        &__cardBody {
            height: 100%;
            display: flex;
            flex-direction: column;
            gap: 45px;
        }
       
        .ant-card{
            @include themify($themes) {
                background: themed('bodyBackgroundColor');
                color:themed('headingColor');
            }
        }
    }
}
