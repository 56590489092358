@import '../../../../styles/colorVariable';
@import '../../../../styles/global-settings.scss';

.monitoringViewWrapper {
    .ant-card {
        height: 100%;
        margin: auto;
    }
    .ant-card-body {
        padding: 0 !important;
    }
    &__backIcon {
        cursor: pointer;
    }
    &__header {
        display: flex;
        gap: 20px;
        padding: 27px;
        align-items: center;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);;
    }
    &__title {
        color: $title-85;
    }
    &__headingCardHealthy {
        margin: 20px !important;
        padding: 20px 40px !important;
        background-image: linear-gradient(90deg, #E5FDE0, #F1F3FC);
    }
    &__headingCardWarning {
        margin: 20px !important;
        padding: 20px 40px !important;
        background-image: linear-gradient(90deg, #FFF5E0, #F1F3FC);
    }
    &__headingCardError {
        margin: 20px !important;
        padding: 20px 40px !important;
        background-image: linear-gradient(90deg, #FFE3E0, #F1F3FC);
    }
    .cardButton {
        display: flex !important;
        align-items: center;
    }
    &__detailsCard {
        margin: 20px !important;
        padding: 20px 20px !important;
        height: calc(100vh - 440px) !important;
        overflow: auto;
        .ant-row {
            gap: 20px;
            padding: 18px;
        }
        .ant-input {
            border-radius: 2px;
        }
    }
}

.cardTitle {
    max-height: 90px;
    overflow: auto;
    word-wrap: break-word;
}