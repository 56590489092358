@import '../../../styles/global-settings.scss';
.configurationTabs {
    background-color: $white;
    @include themify($themes) {
        background: themed('bodyBackgroundColor') !important;
    }
}
.configurationWrapper,
.ant-input-affix-wrapper {
    gap: 10px;
    padding: 12px;
  
    .ant-card {
        height: 67vh;
        margin: auto;
    }
    .ant-card-body {
        height: 100%;
        padding: 12px 24px !important;
        @include themify($themes) {
            background: themed('bodyBackgroundColor') !important;
            
     }
    }
    &__usersListPagination {
        padding-top: 12px;
    }
    &__usersList {
        padding-top: 12px;
        height: calc(100vh - 305px);
        overflow-y: scroll;
    }
    &__heading {
        margin-bottom: 12px;
    }
    &__search {
        width: 300px !important;
        height: 40px !important;
        border: none !important;
        border-radius: 8px !important;
        background: $neutral-6 !important;
        .ant-select-selector,
        .ant-input-outlined:focus,
        .ant-input-outlined:hover {
            background: $neutral-6 !important;
            border: none !important;
            width: 300px !important;
        }
    }
    .configurationWrapper__addEquipmentClassBtn {
        width: 174px;
        height: 32px;
        margin-left: auto;
        margin-right: 0;
    }
    .configurationClassWrapper__header {
        display: grid;
        grid-template-columns: 1fr 133px 124px;
        grid-column-gap: 8px;
        align-items: center;
        &-button {
            display: flex;
            gap: 8px;
            padding-top: 4px;
        }
    }
    .ant-select-dropdown.ant-cascader-dropdown.ant-select-dropdown-placement-bottomLeft {
        width: 300px !important;
    }
    .ant-modal-content .ant-modal-body {
        @include flexprob;
        padding: 52px 0px 22px 0px;
        flex-direction: column;
        gap: 20px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        span.text-center.fw-400.fs-20 {
            padding: 0 32px;
        }
    }
    .ant-modal-footer {
        @include textfontstyle;
        text-align: center;
        margin-top: 0px !important;
        padding: 24px 0px;
    }
    .read-permission {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 10px;
    }
    .add-user .ant-modal {
        width: 720px !important;
        .ant-modal-content {
            width: 720px;
        }
        .userCreationTypeWrapper .addUserManually__wrapper {
            .ant-select-selector {
                width: 98%;
            }
        }
    }
    .ant-checkbox-checked .ant-checkbox-inner {
        background-color: #2e53ea !important;
        border-color: #2e53ea !important;
        border: 1px solid #2e53ea !important;
    }
    .ant-checkbox-checked:hover .ant-checkbox-inner {
        background-color: #5c79ef !important;
        border-color: #5c79ef !important;
        border: 1px solid #5c79ef !important;
    }
    .configurationTableWrapper__configurationTableEmpty {
        @include flexprob;
        flex-direction: column;
        padding: 52px 0px 22px 0px;
            gap: 32px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
    }
}