@import '../../../../styles/global-settings.scss';
.editClassDrawer {
    position: absolute;
    width: 720px;
    height: 689px;
    right: 0px;
    bottom: 0px;
    background: $neutral-1;
    box-shadow: -9px 0px 22px rgba(0, 0, 0, 0.1);
    border-radius: 8px 0px 0px 8px;
    .ant-drawer-body {
        overflow-x: hidden !important;
    }
    &__primaryModel {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0 12px;
        color: $gray-8;
        margin-top: 4px;
    }
    &__divider {
        margin: 8px 0;
        width: calc(100% + 48px);
    }
    &__inputField {
        border-radius: 2px;
    }
    &__icon {
        color: $title-45;
        float: right;
    }
    &__heading {
        span {
            @include textfontstyle;
            color: $title-85;
            @include themify($themes) {
                color: themed('headingColor') !important;
         }
        }
    }
    &__button {
        position: absolute;
        bottom: 30px;
        width: 100%;
        left: 0;
    }
    &__footerButtons {
        justify-content: center;
        col {
            margin-right: 10px;
        }
    }
    &__SaveButton {
        padding-left: 4px;
    }
    &__cancelButton {
        padding-right: 4px;
    }
}
