.kpiImplementationWrapper {
    .implementation {
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        padding-left: 3px;
        padding-bottom: 3px;
        padding-right: 3px;
    }
    .tabsSection {
        margin-top: 0px;
    }
}
