@import '../../../../styles/colorVariable';
@import '../../../../styles/mixins';
@import '../../../../styles/global-settings.scss';
.passwordComponent {
    .theme-dark & {
        padding: 0;
        background-color: $black-8;
    }
    padding: 12px;
    height: 100%;
    .ant-card {
       // height: 100%;
        .ant-card-body {
            @include themify($themes) {
                background: themed('bacgroundBlack');
                border-radius: 0 !important;
            }
            height: 100%;
            .passwordContainer {
                @include themify($themes) {
                    background: themed('bodyCardBackgroundColor');
                    color: themed('textColor') !important;
                }
                &__heading {
                    padding: 24px;
                    border-bottom: 1px solid $divider;
                }
                &__content {
                    width: 100%;
                    &__child {
                        margin: 0 auto;
                        margin-top: 55px;
                    }
                }
                &__form {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    margin-top: 5%;
                    gap: 50px;
                    .ant-form-item {
                        width: 100%;
                        .ant-form-item-row {
                            @include flexprob;
                            .ant-form-item-label label {
                                font-style: normal;
                                font-weight: 400;
                                font-size: 14px;
                                line-height: 22px;
                                color: $title-45;
                                @include flexprob;
                                gap: 4px;
                                span {
                                    @include flexprob;
                                }
                                @include themify($themes) {
                                    color: themed('headingColor') !important;
                                }
                            }
                        }
                    }
                    &__container {
                        width: 100%;
                    }
                    &__input {
                        .ant-row {
                            margin: auto;
                        }
                    }
                    &__divider {
                        height: 2px;
                        width: 100%;
                        background-color: $divider;
                    }
                    &__buttons {
                        display: flex;
                        .ant-row {
                            width: 20%;
                            margin: auto;
                            .ant-form-item-control-input-content {
                                display: flex;
                                gap: 8px;
                            }
                        }
                    }
                }
                svg {
                    @include themify($themes) {
                        color: themed('svgColor') !important;
                        stroke: themed('svgColor') !important;
                    }      
                }
                path {
                    @include themify($themes) {
                        fill: themed('svgColor') !important;
                    }
                }
            }
        }
    }
    .ant-input-affix-wrapper {
        @include themify($themes) {
            background: themed('bodyCardBackgroundColor')!important;
        }
    }
    .ant-input {
        @include themify($themes) {
            background: themed('bodyCardBackgroundColor');
            color: themed('textColor') !important;
        }
    }

    .ant-card-bordered {
        .theme-dark & {
            border: 0 !important;
        }
    }
}
