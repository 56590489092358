@import '../../../styles/global-settings.scss';


.tooltip li>img {
    width: 15px;
    height: 15px;
    margin-right: 8px;
}

.ant-tooltip-inner{
    @include themify($themes) {
      background: themed('bodyCardBackgroundColor');
      color: themed('textColor')!important;
    }
  }