@import '../../../styles/global-settings.scss';

.reasonCodeTableWrapper {
    margin-top: 20px;
}

.reasonCodeCategory {
    .ant-select.ant-select-in-form-item {
        width: 100% !important;
    }
}

.reasonCodeModal {
    .ant-modal {
        border-radius: 12px !important;
        width: 846px !important;
        height: 750px !important;
        .ant-modal-close-x {
            font-size: 12px;
        }
        .ant-modal-close {
            top: 32px;
        }
        .ant-modal-content {
            padding: 0px;
        }
    }
    &__body {
        width: 100% !important;
        height: 100% !important;
        &__header {
            padding: 28px 10% 28px 28px;
            @include flexprob;
            justify-content: space-between;
            align-content: center;
            border-bottom: 1px solid $neutral-5;
        }
    }
    &__footerWrapper {
        border-top: 1px solid $neutral-5;
        padding: 25px 0;
    }
    &__footerContent {
        width: 30%;
        margin: auto;
        @include flexprob;
        justify-content: space-between;
        gap: 8px;
    }
}

.button__reasonCode {
    width: 150px;
}
