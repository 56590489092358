@import '../../styles/global-settings.scss';

.login {
    &__leftSection {
        background-image: url('../../assets/images/loginBgImage.png');
        background-size: cover;
        width: 100%;
        height: 100vh;
        max-height: 100%;
    }
    &__right{
        @include themify($themes) {
            background: themed('bodyBackgroundColor') !important;
            color:themed('headingColor')!important;
        }
    }
    &__rightSection {
        width: 57%;
        height: 100vh;
        margin: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .remember-check {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .ant-form-item {
                margin-bottom: 0;
                color: $gray-5;
            }
        }
        .title {
            margin-top: 10%;
            .screenNameHeading__heading {
                font-weight: 600 !important;
                font-size: 30px;
                span {
                    color: $black-1;
                    @include themify($themes) {
                        color:themed('headingColor')!important;
                    }
                }
            }
            .screenNameHeading__subHeading {
                font-size: 16px;
                span {
                    color: $gray-4;
                    @include themify($themes) {
                        color:themed('headingColor')!important;
                    }
                }
            }
        }
        #login_form {
            margin-top: 10%;
            position: relative;
            .ant-form-item-label > label {
                color: $gray-4;
                font-size: 16px !important;
                @include themify($themes) {
                    color:themed('headingColor')!important;
                }
            }
            input {
                padding: 11px 11px;
                &::placeholder {
                    font-size: 16px;
                    font-weight: 400;
                }
                @include themify($themes) {
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px;
                }
            }
            .ant-input-suffix {
                font-size: 20px !important;
            }
            .remember-check {
                margin-bottom: 60px;
                label > span {
                    color: $gray-4;
                    font-size: 16px !important;
                    margin-right: 10px;
                    @include themify($themes) {
                        color:themed('headingColor')!important;
                    }
                }
                a {
                    color: $gray-4;
                    text-decoration: none;
                    @include themify($themes) {
                        color:themed('headingColor')!important;
                    }
                }
            }
            .copyright-text {
                @include flexprob;
                color: $gray-4;
                margin-top: 20%;
                @include themify($themes) {
                    color:themed('headingColor')!important;
                }
            }
            .ant-input-affix-wrapper {
                padding: 0px 11px;
                gap: 0;
                @include themify($themes) {
                    padding: 0px 11px 0px 0px;
                }
            }
        }

        input:-webkit-autofill {
            -webkit-background-clip: text;
        }
    }
    .right-arrow {
        margin-bottom: 16px;
        a {
            text-decoration: none;
            color: $gray-4;
            @include themify($themes) {
                color:themed('headingColor')!important;
            }
        }
        img {
            margin-right: 10px;
        }
    }
    .login-btn button {
        height: 48px;
        padding: 7px 20px;
        background: $primary-5;
        border: 1px solid $neutral-2;
        border-radius: 8px;
        width: 100%;
        flex-grow: 1;
        color: $neutral-1;
        
    }
    .customButton span {
        font-weight: 500;
        font-size: 20px;
    }
    svg {
        @include themify($themes) {
            color: themed('svgColor') !important;
            stroke: themed('svgColor') !important;
        }      
    }
}
