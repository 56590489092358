$primary-1: #e8ecfd;
$primary-2: #e6f7ff;
$primary-3: #153ad1;
$primary-6: #2e53ea;
$primary-5: #5c79ef;
$primary-4: #2f54eb;
$primary-7: #153ad1;
$primary-8: #e8ecfd;
$primary-9: #2f54eb;
$primary-10: #f6f7fe;
$button: #2f54eb;
$primary-8: #e8ecfd;
$primary-hover: #1677ff;

$neutral-1: #ffffff;
$neutral-2: #dadde0;
$neutral-3: #f5f5f5;
$neutral-4: #f0f0f0;
$neutral-4: #f0f0f0;
$neutral-5: #d9d9d9;
$neutral-6: #f6f7f9;
$neutral-7: #fafafa;
$white: #fcfcfc;
$neutral-8: #eee;

$black-1: #172a3f;
$black-2: #000;
$gray-1: #f3f3f3;
$gray-2: #848484;
$gray-3: #f6f7f9;
$gray-4: #546271;
$gray-5: #6d7986;
$gray-6: #434343;
$gray-7: #838383;
$gray-8: #00000073;
$gray-9: #777;
$black-3: rgba(5, 14, 38, 1);


$progress-border: #f86806;
$progress-inactive-bg: #0000001a;

$title-85: rgba(0, 0, 0, 0.85);
$title-65: rgba(0, 0, 0, 0.65);

$title-08: rgba(0, 0, 0, 0.08);
$title-5: rgba(0, 0, 0, 0.5);
$title-35: rgba(0, 0, 0, 0.35);
$title-45: rgba(0, 0, 0, 0.45);
$border: rgba(0, 0, 0, 0.1);
$text: rgba(0, 0, 0, 1);
$box-shadow: rgba(0, 0, 0, 0.016);
$box-shadow-neutral: rgba(0, 0, 0, 0.13);
$header-background: rgba(251, 251, 251, 1);
$success-green-text: rgba(50, 153, 0, 1);

$disabled-text: rgba(0, 0, 0, 0.25);
$divider: rgba(0, 0, 0, 0.03);
$shadow: rgba(0, 0, 0, 0.016);
$border: rgba(0, 0, 0, 0.1);

$geek-blue: #597ef7;
$volcano-4: #ff9c6e;
$volcano-5:#FF5C00;
$polar-green-1: #f6ffed;
$polar-green-3: #b7eb8f;
$polar-green-6: #52c41a;
$green-1: #52c41a0d;
:root {
    --green-1: #{$green-1};
}
$green-2: #52c41a1a;
:root {
    --green-2: #{$green-2};
    --disable: #{$neutral-4};
}
$green-3: #319300;
:root {
    --green-3: #{$green-3};
}

$red-1: #fff0f0;
$red-2: #ffadae;
$red-3: #ff4d4f;
$red-4: #fd3d33;
$red-5: #ff4d4f;
$red-6: rgba(255, 92, 0, 1);

$button: #2f54eb;

$neutral-1: #ffffff;
$neutral-2: #dadde0;
$neutral-3: #f5f5f5;
$neutral-4: #f0f0f0;
$neutral-5: #d9d9d9;
$neutral-6: #f6f7f9;
$neutral-7: #fafafa;
$netural-8: #ffffff78;
$neutral-9:#fff3;

$black-1: #172a3f;
$gray-1: #f3f3f3;
$gray-2: #848484;
$gray-3: #f6f7f9;
$gray-3: #f6f7f9;
$gray-4: #546271;
$gray-5: #6d7986;
$gray-6: #434343;
$gray-7:#5e5e5e;
$gray-8:#838383;
$gray-9: rgba(128, 129, 132, 1);
$gray10:#838383;
$gray-11:#f1f2f380;


$stepper-border: #1957f3;

$progress-border: #f86806;
$progress-inactive-bg: #0000001a;

$progress-border: #f86806;
$progress-inactive-bg: #0000001a;

$title-85: rgba(0, 0, 0, 0.85);

$title-65: rgba(0, 0, 0, 0.65);

$title-65: rgba(0, 0, 0, 0.65);

$title-08: rgba(0, 0, 0, 0.08);
$title-04: rgba(0, 0, 0, 0.04);
$title-45: rgba(0, 0, 0, 0.45);
$border: rgba(0, 0, 0, 0.1);
$box-shadow: rgba(0, 0, 0, 0.016);
$border: rgba(0, 0, 0, 0.1);
$box-shadow: rgba(0, 0, 0, 0.016);

$disabled-text: rgba(0, 0, 0, 0.25);
$divider: rgba(0, 0, 0, 0.03);
$shadow: rgba(0, 0, 0, 0.016);
$border: rgba(0, 0, 0, 0.15);
$black: #1e1e1e;
$black-7: #000000;
$black-8: #01040e;
$geek-blue: #597ef7;


$polar-green-1: #f6ffed;
$polar-green-3: #b7eb8f;
$polar-green-6: #52c41a;
$green: #4ed964;

$red-1: #fff0f0;
$red-2: #ffadae;
$red-3: #ff4d4f;
$red-4: #fb554c;
$red-8: rgba(255, 77, 79, 1);

$yellow-1: #faad14;
$reset-border: rgba(248, 104, 6, 0.5);
$reset-background: #fff8f3;
$clear-all: #f2644e;
$date-range: #f1f3fc;
$date-rangeBorder: rgba(46, 83, 234, 0.5);
$blue: rgba(46, 83, 234, 1);

$orange-1: #fcb07d;
$orange-1: rgba(255, 92, 0, 0.85);
$orange-2: rgba(255, 239, 229, 1);

$darkBlue: #030921;
$darkBlue1: #02071c;
$ligtDarkBlue: #2c303f;
$lightBlue: #1b2037;
$darkBule2: #02071d;
$darkBlue3: #23283d;
$darkBlue4: #21273cb3;
$darkBlue5: #1c2135;
$darkBlue6:#04101c ;
$darkBlue7:#0f3160e3;
$darkBlue8:#283050;
$darkBlueOpacity9:#2fa0eb1f;
$lightGreyOpacity: rgb(255 255 255 / 10%);
$lightGreyOpacity1: rgb(255 255 255 / 6%);
$lightGreyOpacity2: rgb(255 255 255 / 20%);
$lightGreyOpacity3: rgb(255 255 255 / 45%);
$lightGreyOpacity4: rgb(255 255 255 / 85%);
$lightGreyOpacity5: rgb(255 255 255 / 15%);
$lightGreyOpacity6:#49494952;
$darkBlueOpacity:rgb(2 12 45 / 91%);
$lightGreyColor7:rgba(255, 255, 255, 0.03);
$disableGrey:#4f4f517a;


$button-outline: rgba(46, 83, 234, 1);
$disabled-bg:rgba(0, 0, 0, 0.04)
