@import '../../../styles/colorVariable';
@import '../../../styles/global-settings.scss';

.qrCodeLogsWrapper {
    .custom-apply {
        color: #153ad1 !important;
        background: #ffffff !important;
    }
    .custom-apply:hover {
        background: #fff !important;
    }
    .timeCapsuleTableWrapper__subItems {
        color: $volcano-5;
    }
    .ant-drawer-content-wrapper {
        width: 360px !important;
    }
    .print-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 60px;
        button {
            background-color: $primary-6 !important;
            border-radius: 4px;
            width: 40%;
            margin-top: 50px;
            padding: 20px;
            span {
                color: $neutral-1;
            }
        }
    }
    img {
        width: 200px;
        margin: 0 auto;
        display: flex;
    }
    label {
        color: $black-2;
        margin-bottom: 10px;
    }
    .ant-input {
        border-radius: 4px;
        background-color: $neutral-3 !important;
        padding: 8px;
    }
    .column__rePrint {
        cursor: pointer;
    }
    .print-btn{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 60px;
        button{
           background-color: $primary-6;
           border-radius: 4px;
           width: 40%;
           padding: 20px;
           span{
            color:$neutral-1;
           }
        }
        
    }
    label{
        color: $black-2;
        margin-bottom: 10px;
    }
    .ant-input{
        border-radius: 4px;
        background-color: $neutral-3!important;
        padding: 8px;
    }
    .column__rePrint{
        cursor: pointer;
    }
}
.qrCodeLogs__noData {
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: auto;
    transform: translate(-50%, -50%);
}

.customButton.custom-new.connections {
    width: 70% !important;
    margin-right: 0px !important;
}

.bulk-importbtn .EmptyDataComponent__buttons__customButton {
    justify-content: center;
}
.connectionWrapper__button {
    .custom-new.connections {
        width: 100% !important;
    }
}

.validateModal {
    width: 40% !important;
    .ant-modal-header {
        padding: 24px;
        background-color: $header-background;
    }
    .ant-modal-title {
        font-weight: 500;
        font-size: 16px;
    }
    .validatedModalText {
        padding: 24px !important;
        // max-width: 92%;
        // color: $success-green-text;
    }
    .validatedModalErrorText {
        padding: 24px !important;
        // max-width: 92%;
        // color: $red-8;
    }
}

.qrCodeLogs.attributeWrapper .ant-table-body,
.ant-input-affix-wrapper .ant-table-body {
    overflow-x: unset !important;
}
.qrCodeLogs {
    ::-webkit-scrollbar {
        width: 4px;
        height: 10px !important;
    }
}

.attributeWrapper__attributeList {
    overflow: unset !important;
}
.connections {
    .ant-modal-title {
        visibility: hidden;
    }
    .ant-modal-header {
        background-color: #fff !important;
    }
}

.validateTagModal.validateModal.confirmationModal.connections {
    width: 509px !important;
}
.validateModal.connections .validatedModalErrorText {
    padding: 0px 0 15px 0 !important;
}
.popoverWidth {
    max-width: 400px;
    word-wrap: break-word;
}

.printqr__subHeading {
    margin-top: 90px;
    label{
        display: flex;
       margin-top:50px;

    }
}
.nameColumn {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 190px;
    .viewName {
        margin-left: 0px;
        display: flex;
    }
    .otp-btn {
        background-color: $red-3;
        border-radius: 20px;
        color: $neutral-1;
        margin-left: 10px;
        padding: 0;
        width: 50px;
        height: 23px;
        border: 0;
        span {
            line-height: 24px;
        }
    }
    span {
        @include textfontstyle;
    }
    span.ant-avatar.ant-avatar-circle.mr-10 {
        background-color: $volcano-4;
        color: $neutral-1;
        margin-right: 10px;
    }
    .name {
        color: $primary-4;
    }
}

.nameStatus {
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
}