@import '../../../../styles/global-settings.scss';

.userCreationTypeWrapper {
    .userCreationType {
        width: 65%;
        margin: auto;
        padding: 28px 0;
        &__content {
            width: 100%;
            display: flex;
            flex-direction: column-reverse;
            @include textfontstyle;
            .ant-typography {
                padding: 20px;
                text-align: center;
                @include flexprob;
            }
        }
        &__item {
            padding: 16px 15px;
            @include themify($themes) {
                background: themed('modalBackgroundColor');
                border-color: themed('borderCardColor') !important;
            }
            .ant-card-body {
                padding: 0px;
                display: flex;
                @include themify($themes) {
                    background: themed('modalBackgroundColor');
                    border-color: themed('borderCardColor') !important;
                }
            }
        }
        &__icon {
            display: flex;
            align-items: center;
            padding-right: 17px;
            svg {
                height: 33px;
                width: 30px;

                @include themify($themes) {
                    stroke: themed('stroke') !important;
                }
            }
        }
        &__text {
            color: $title-45;
            @include themify($themes) {
                color: themed('textColor');
            }
            .ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
                border-color: $primary-6;
                background-color: $neutral-1;
            }
            .ant-radio-wrapper .ant-radio-inner::after {
                background-color: $primary-6;
            }
            span {
                color: $primary-6;
            }
        }
        .disabled {
            height: auto !important;
        }
    }
    &__footerWrapper {
        border-top: 1px solid $neutral-5;
        @include themify($themes) {
            border-color: themed('borderDarkColor') !important;
        }
        padding: 25px 0;
    }
    &__footerContent {
        width: 50%;
        margin: auto;
        @include flexprob;
        justify-content: space-between;
        gap: 8px;
    }
    .inviteUser {
        width: 90%;
        margin: auto;
        padding: 24px 0;
        height: 355px;
        color: $title-85;
        display: flex;
        flex-direction: column;
        gap: 10px;
        .ant-select .ant-select-selection-item {
            @include textfontstyle;
            line-height: 20px;
            color: $title-85;
            font-weight: 400;
            font-size: 12px;
        }
    }
    .addUserManually {
        .addUserManually__content__wrapper {
            height: 370px;
            overflow-y: scroll;
            border-top: 0.5px solid $border;
        }
        &__imageUpload {
            @include flexprob;
            flex-direction: column;
            color: $disabled-text;
            gap: 8px;
            margin: 20px 0px;
        }
        &__wrapper {
            width: 90%;
            margin: auto;
            padding: 20px 0;
        }
        .userCreationTypeWrapper__footerContent {
            width: 40% !important;
            .show-field {
                margin-left: 20%;
                color: $primary-3;
                cursor: pointer;
            }
        }
    }

    /* Hide the arrows on Firefox number input fields */
    .hide-sorting::-webkit-inner-spin-button,
    .hide-sorting::-webkit-outer-spin-button {
        -webkit-appearance: none;
        appearance: none;
        margin: 0;
    }

    .hide-sorting[type='number'] {
        -moz-appearance: textfield;
    }
}
.imageUpload__container {
    position: relative;
    width: 50%;
    .ant-col.ant-form-item-label {
        text-align: center !important;
    }
    input[type='file'] {
        display: none;
    }
    .custom-file-upload {
        border: 1px dashed $neutral-5;
        display: inline-block;
        height: 104px;
        width: 104px;
        margin: 0 auto;
        cursor: pointer;
        border-radius: 50%;
        display: flex;
        text-align: center;
        justify-content: center;
        background: $neutral-7;
        @include themify($themes) {
            background: themed('bodyCardBackgroundColor') !important;
            border-color: themed('borderDarkColor') !important;
        }
        .upload-inner {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 8px;
            color: $title-85;
            @include themify($themes) {
                color: themed('headingColor');
            }
        }
    }
    .image__container {
        opacity: 1;
        display: block;
        height: auto;
        transition: 0.5s ease;
        backface-visibility: hidden;
        width: 104px !important;
        height: 104px;
        margin: 0 auto;
        border-radius: 50%;
    }
    .remove__container {
        transition: 0.5s ease;
        opacity: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        text-align: center;
    }
    .remove {
        font-size: 16px;
        padding: 16px 32px;
    }
    :hover .image__container {
        opacity: 0.3;
    }
    :hover .remove__container {
        opacity: 1;
    }
}
.disabled {
    .userCreationType__text span {
        color: grey;
    }
    cursor: not-allowed;
}

.ant-select-dropdown.CustomOverlay {
    width: 315px !important;
}

span.anticon.anticon-sync {
    cursor: pointer;
}
