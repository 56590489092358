@import '../../../../styles/colorVariable';
.tableImplementation {
    &__header {
        justify-content: space-between;
        margin-left: 8px;  
    }
    &__assignText {
        margin-left: 8px;
    }
    &__searchBox {
        margin-left: 8px;
    }
    &__search {
        width: 209px;
        height: 32px;
        background: $gray-3;
        border: none;
        border-radius: 8px;
        input.ant-input {
            background: $gray-3;
            height: 16px;
            margin-top: -4px;
        }
        span {
            color: $title-45;
        }
    }
    &__backIcon{
        color: $gray-8;
        cursor: pointer;
    }
    &__editBtn{
        width: 90px;
        float: inline-end;
        margin-right: 2px;
        color: $primary-3;
    }
    .ant-divider-horizontal {
        margin: 16px 0px;
    }
}

.tableImplementationTable{
    margin-top: 8px;
    .ant-table-wrapper {
        margin-left: 4px;
    }
}
.tableImplementationFooter {
    position: absolute;
    bottom: 20px;
    width: 100%;
    left: 0;
    &__btns {
        justify-content: center;
    }
}
.cancelBtn {
    padding-right: 4px;
}
.saveBtn {
    padding-left: 4px;
}
.tableWithFooter {
    margin-top: 8px;
}
.tableImplementation__unassignedPagination {
    background: $neutral-1;
    box-shadow: 0px -11px 24px rgba(0, 0, 0, 0.13);
    width: 100%;
    padding: 12px 24px 16px 24px;
    z-index: 1;
    text-align: end;
    position: absolute;
    bottom: 70px;
}
.tableImplementation__assignedPagination {
    background: $neutral-1;
    box-shadow: 0px -11px 24px rgba(0, 0, 0, 0.13);
    width: 100%;
    padding: 12px 24px 16px 24px;
    z-index: 1;
    text-align: end;
    position: absolute;
    bottom: 4px;
}
.validateTable {
    .ant-table-body {
        height: calc(100vh - 410px);
    }
}