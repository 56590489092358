@import '../../../styles/global-settings.scss';

.userProfile {
    &__tabsComponent {
        .ant-tabs {
            .ant-tabs-nav {
                padding-left: 24px;
                background: $neutral-1;
                margin: 0;
                @include themify($themes) {
                    background: themed('bodyBackgroundColor');
                }
            }
            .ant-tabs-content {
                .ant-tabs-tabpane {
                    overflow-y: scroll;
                   height: calc(100vh - 198px);
                }
            }
            .ant-tabs-tab-btn {
                @include themify($themes) {
                    color: themed('headingColor');
                }
            }
        }
    }
    .ant-tabs-tab-active .ant-tabs-tab-btn {
        @include themify($themes) {
            color: themed('hoverColor') !important;
        }
    }
}
