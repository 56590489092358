@import '../../../styles/global-settings.scss';

.uomConfigurator__data,
.ant-input-affix-wrapper {
    gap: 10px;
    padding: 12px;
    @include themify($themes) {
        background-color: themed('bodyBackgroundColor') !important;
        border: 1px solid themed('borderDarkColor') !important;   
    }
    .ant-card {
        .ant-card-body {
            padding: 12px 24px 24px 24px;
        }
    }
    .uomConfigurator__uomListPagination {
        padding-top: 12px;
    }
    .uomConfigurator__uomList {
        padding-top: 12px;
        overflow-y: scroll;
    }

    .ant-card .ant-card-body {
        padding: 12px 24px;
    }

    .uomConfigurator__rowHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0px;
    }

    .uomConfigurator__button {
        width: 151px;
        height: 32px;
    }

    .uomConfigurator__search {
        width: 300px !important;
        height: 40px !important;
        border: none !important;
        border-radius: 8px !important;
        background: $neutral-6 !important;
        @include themify($themes) {
            background: themed('bodyBackgroundColor') !important;
            border: 1px solid themed('borderDarkColor') !important;
        }
        .anticon-search, .anticon-close-circle{
            svg {
                @include themify($themes) {
                    color: themed('svgColor') !important;
                    stroke: themed('svgStrokeColor') !important;
                }      
            }
            path {
                @include themify($themes) {
                    fill: themed('svgColor') !important;
                }
            } 
        }
        .ant-select-selector,
        .ant-input-outlined:focus,
        .ant-input-outlined:hover {
            background: $neutral-6 !important;
            border: none !important;
            width: 300px !important;
        }
        .ant-select-selector::before {
            content: url('/assets/icons/searchIcon.svg');
            font-size: 5px !important;
            margin-left: 4px !important;
        }
        span {
            color: $title-45 !important;
        }
        span.ant-select-selection-placeholder {
            margin-left: 20px !important;
        }
        .ant-select-arrow {
            display: none !important;
        }
        &:hover input.ant-input,
        &:hover .ant-select-selector {
            background: $neutral-6 !important;
            border: none !important;
            width: 300px !important;
            @include themify($themes) {
                background: themed('bodyBackgroundColor') !important;
            }
        }

        /* Focus effect */
        &:focus-within input.ant-input,
        &:focus-within .ant-select-selector {
            background: $neutral-6 !important;
            border: none !important;
            width: 300px !important;
            @include themify($themes) {
                background: themed('bodyBackgroundColor') !important;
            }
        }
        input.ant-input {
            background: $gray-3;
            height: 16px;
        }
    }
    .ant-card {
        height: 75vh;
        margin: auto;
    }
    .ant-card-body {
        height: 100%;
        padding: 12px 24px !important;
    }
    .ant-table-body {
        overflow-x: hidden !important;
    }
    .ant-empty-description {
        span {
            max-width: 220px;
            display: block;
        }
    }
}
