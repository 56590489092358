@import '../../../../styles/global-settings.scss';
.chart-properties-scatter-drawer{
    .ant-collapse-header{
       display: flex;
       flex-direction: row-reverse;
    }
    .panel-header {
        display: flex;
        justify-content: space-between;
        margin-right: 10px;
    }
    .axis-field {
        margin-bottom: 10px;
    }
   
        .button-group {
            margin-top: 20px;
            display: flex;
            gap: 10px;
            justify-content: space-between;
        }
        .input-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        
        .input-group {
            margin-right: 10px;
        }
        .statistical-functions-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .ant-collapse{
            border: 1px solid $neutral-5 !important;
            border-radius: 8px !important;
        }
}


