@import '../../../../styles/global-settings.scss';

.customDropdownWithTab {
    .customTabDropdownItems {
        color: $gray-8;
    }

    .customDropdownWithTabWrapper {
        width: 100% !important;
    }
    .ant-select-multiple .ant-select-selector {
        border-radius: 2px !important;
    }

    .ant-select-dropdown {
        width: 100% !important;
    }
    &__tabsWrapper {
        @include textfontstyle;
        .ant-tabs-nav {
            margin-left: 14px;
        }
        .ant-tabs-nav {
            margin: 0px !important;
        }
        .ant-tabs .ant-tabs-tab {
            margin: 0 0 0 10px;
        }
    }
    &__tabsClearWrapper {
        display: flex;
        justify-content: space-between;
        justify-content: center;
        align-items: center;
    }
}

.customTabDropdownItemsWrapper {
    height: 150px !important;
    overflow-y: scroll;
    background: $white;
    padding-top: 5px;
    @include themify($themes) {
        background: themed('bodyCardBackgroundColor') !important;
 }
}

.ant-input-outlined.customDropdownWithTab__search {
    gap: 3px;
    padding: 6px !important;
    &__search {
        width: 100%;
        height: 40px;
        border: none;
        input.ant-input,
        .ant-select-selector {
            background: $neutral-6 !important;
            border: none !important;
            padding: 4px;
            width: 100%;
            height: 40px;
        }
        .ant-select-selector::before {
            content: url('/assets/icons/searchIcon.svg');
            font-size: 5px;
            margin-left: 4px;
        }
        span {
            color: $title-45;
        }
        span.ant-select-selection-placeholder {
            margin-left: 20px;
        }
        .ant-select-arrow {
            display: none;
        }
    }
}
