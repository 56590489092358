@import '../../../../styles/global-settings.scss';
@import '../../../../styles/colorVariable';

.create-table-template {
    padding: 10px;
    .ant-card-body {
        @include themify($themes) {
            background-color: themed('bodyBackgroundColor') !important;
            border-radius: 7px;
        }
        .ant-table-wrapper .ant-table-thead > tr > td {
            @include themify($themes) {
                background: themed('tableHeader');
                border-bottom: 1px solid themed('borderDarkColor') !important;
            }
        }
    }

    .wrapper {
        padding-top: 5px;
        padding-bottom: 10px;
    }

    .header {
        .anticon svg {
            svg {
                @include themify($themes) {
                    color: themed('svgColor') !important;
                    stroke: themed('svgStrokeColor') !important;
                }
            }
            path {
                @include themify($themes) {
                    fill: themed('svgColor') !important;
                }
            }
        }
        .anticon-arrow-left {
            margin-right: 8px;
            color: rgba(0, 0, 0, 0.45);
            cursor: pointer;
        }
        align-items: center;
        width: 205px;
        height: 24px;
    }
    .name-description {
        .ant-form {
            display: flex;
            margin-top: 10px;
        }
        .name {
            margin-right: 8px;
            width: 50%;
        }
        .description {
            margin-right: 8px;
            width: 50%;
        }
        .type {
            width: 30%;
        }
        .formIds{
            width: 30%;
            margin-right: 8px;

        }
    }
    .form {
        .EditTableCreateTable {
            &__Wrapper {
                &__button {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;

                    .custom-add-button {
                        background-color: #ffffff;
                        color: $primary-7;
                        border: 1px solid $primary-7;
                        @include themify($themes) {
                            background: themed('bodyBackgroundColor');
                            border: 1px solid themed('borderDarkColor') !important;
                        }

                        &:hover,
                        &:focus {
                            background-color: white;
                            border: 1px solid $primary-7;
                            @include themify($themes) {
                                background: themed('bodyBackgroundColor');
                                border: 1px solid themed('hoverColor') !important;
                            }
                        }

                        &:active {
                            background-color: $primary-7;
                            color: white;
                            border: 1px solid $primary-7;
                        }

                        .addIcon {
                            color: $primary-7;
                        }
                    }
                }
                &__Table {
                    height: 43vh;
                }
                &__Footer {
                    @include flexprob;
                    background: $neutral-1;
                    width: 100%;
                    padding: 12px 24px 0px 24px;
                    z-index: 1;
                    gap: 8px;
                    border-top: 0.5px solid $border;
                    @include themify($themes) {
                        background-color: themed(
                            'bodyBackgroundColor'
                        ) !important;
                        border-top: 1px solid themed('borderDarkColor') !important;
                    }
                }
                &__FooterWrapper {
                    width: 110px;
                }
            }

            .deleteicon {
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .editable-cell {
                position: relative;
            }

            .editable-cell-value-wrap {
                padding: 5px 12px;
                cursor: pointer;
            }

            .editable-row:hover .editable-cell-value-wrap {
                padding: 4px 11px;
                border: 1px solid #d9d9d9;
                border-radius: 2px;
            }
        }
    }
}
.ant-popconfirm-title {
    @include themify($themes) {
        color: themed('headingColor') !important;
    }
}
.ant-table-thead > tr > th.columnName,
.ant-table-thead > tr > th.dataType,
.ant-table-thead > tr > th.uom {
    padding-left: 27px; // Adjust the padding to move the header to the right
}
.column__action {
    text-align: center !important;
}
.icon-pair-container {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;

.icon-label {
        @include themify($themes) {
            color: themed('headingColor') !important;
        } 
    }
.icon-content{
    svg {
        @include themify($themes) {
            color: themed('tableSvgColor') !important;
            stroke: themed('svgTableStrokeColor') !important;
        }
    }
    path {
        @include themify($themes) {
            fill: themed('tableSvgColor') !important;
        }
    }
    }
}

.icon-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: 150px;
    padding: 16px;
    border: 2px solid #ccc;
    border-radius: 8px;
    cursor: pointer;
    transition: border-color 0.3s ease;
    box-sizing: border-box;

    &.selected {
        border-color: blue;
    }

    .icon-content {
        margin-bottom: 8px;
        /* Adjust icon size if needed */
        svg {
            width: 50px;
            height: 50px;
        }
    }

    .icon-label {
        font-size: 14px;
        color: #333;
        text-align: center;
    }
}
.ant-drawer .ant-drawer-header {
    width: 100%;
}

.addTableDrawer__button {
    position: absolute;
    bottom: 30px;
    width: 100%;
    left: 0;

    .addTableDrawer__footerButtons {
        justify-content: center;
        col {
            margin-right: 10px;
        }

        &__cancelButton {
            padding-left: 4px;
        }
        &__saveButton {
            padding-right: 4px;
        }
    }
}
