@import '../../../../styles/global-settings.scss';

.nodeOverviewWrapper,
.ant-input-affix-wrapper {
    gap: 10px;
    padding: 0px;

    &__tableEmpty {
        @include flexprob;
        height: 100%;
        flex-direction: column;
        gap: 32px;
    }
    .ant-card {
        height: calc(100vh - 250px);
        margin: auto;
    }
    .ant-card-body {
        height: 100%;
        padding: 12px 24px !important;
        @include themify($themes) {
            background: themed('bodyBackgroundColor') !important;
     }
    }

    &__usersListPagination {
        padding-top: 12px;
    }
    &__usersList {
        padding-top: 12px;
        height: calc(100vh - 305px);
        overflow-y: scroll;
    }
}
.createRolesWrapper__search {
    width: 300px !important;
    height: 40px !important;
    border: none !important;
    border-radius: 8px !important;
    background: $neutral-6 !important;
}
.nodeOverviewWrapper__addModelBtn {
    width: 104px;
    margin-left: auto;
    margin-right: 0;
}
.nodeOverviewWrapper__header {
    display: grid;
    grid-template-columns: 1fr 133px 124px;
    grid-column-gap: 8px;
    align-items: center;
    &-button {
        display: flex;
        gap: 8px;
        padding-top: 4px;
    }
}

.ant-select-dropdown.ant-cascader-dropdown.ant-select-dropdown-placement-bottomLeft {
    width: 300px !important;
}

// for modal
.resetOtpModal {
    width: 647px !important;
    .ant-modal-content {
        padding: 0px !important;
    }
    .ant-modal-content .ant-modal-body {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 52px 0px 22px 0px;
        flex-direction: column;
        gap: 20px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        span.text-center.fw-400.fs-20 {
            padding: 0 32px;
        }
        .otp-copy {
            width: 520px;
        }
        .otp-copyicon {
            position: absolute;
            top: 57%;
            right: 11%;
        }
    }
    .ant-modal-footer {
        @include textfontstyle;
        text-align: center;
        margin-top: 0px !important;
        padding: 24px 0px;
    }
    .read-permission {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 10px;
    }
}

.add-user .ant-modal {
    width: 720px !important;
    .ant-modal-content {
        width: 720px;
    }
    .userCreationTypeWrapper .addUserManually__wrapper {
        .ant-select-selector {
            width: 98%;
        }
    }
}
.ant-checkbox-checked .ant-checkbox-inner {
    background-color: #2e53ea !important;
    border-color: #2e53ea !important;
    border: 1px solid #2e53ea !important;
}
.ant-checkbox-checked:hover .ant-checkbox-inner {
    background-color: #5c79ef !important;
    border-color: #5c79ef !important;
    border: 1px solid #5c79ef !important;
}
.nodeOverviewTable {
    margin-top: 12px;
}
.modelInfoWrapper {
    gap: 10px;
    padding: 12px;
    .ant-card {
        height: calc(100vh - 264px);
        margin: auto;
    }
    .ant-card-body {
        height: 100%;
        padding: 12px 24px !important;
    }
    &__editDetails {
        height: calc(100vh - 390px);
        overflow-y: scroll;
    }
    &__viewDetails {
        height: calc(100vh - 330px);
        overflow-y: scroll;
    }
    &__backIcon {
        cursor: pointer;
    }
    &__backText {
        font-size: 16px;
        font-weight: 500;
        margin-left: 8px;
    }
    &__form {
        margin-top: 4%;
        padding: 0 36%;
    }
    &__input {
        border-radius: 2px;
    }
    &__button {
        position: absolute;
        bottom: 30px;
        width: 100%;
        left: 0;
    }
    &__footerButtons {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    &__saveBtn {
        padding-left: 4px;
        width: 109px;
    }
    &__cancelBtn {
        padding-right: 4px;
        width: 109px;
    }
    &__imageUploadContainer {
        position: relative;
        .ant-col.ant-form-item-label {
            text-align: center !important;
        }
        input[type='file'] {
            display: none;
        }
        .custom-file-upload {
            border: 1px solid $neutral-5;
            display: inline-block;
            height: 128px;
            width: 128px;
            margin: 0 auto;
            cursor: pointer;
            border-radius: 8px;
            display: flex;
            text-align: center;
            justify-content: center;
            background: $neutral-7;
            .upload-inner {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 8px;
                color: $title-85;
            }
        }
        .image__container {
            opacity: 1;
            display: block;
            height: auto;
            transition: 0.5s ease;
            backface-visibility: hidden;
            width: 104px !important;
            height: 104px;
            margin: 0 auto;
            border-radius: 50%;
        }
        .remove__container {
            transition: 0.5s ease;
            opacity: 0;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            text-align: center;
        }
        .remove {
            font-size: 16px;
            padding: 16px 32px;
        }
        :hover .image__container {
            opacity: 0.3;
        }
        :hover .remove__container {
            opacity: 1;
        }
    }
}
