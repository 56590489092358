@import '../../../../styles/global-settings.scss';

.airToFuelRatioWrapper {
    width: 100%;
    height: 155px;
    margin-bottom: 16px;
    color: $gray-4;
    font-weight: 500;
    .ant-card-body {
        width: 100%;
        height: 100%;
        .airToFuelRatioContainer {
            @include flexprob;
            padding-top: 20px;
            width: 100%;
            height: 100%;
            &__airToFuel {
                width: 50%;
                height: 100%;
                @include flexprob;
                flex-direction: column;
                gap: 20%;
                &__chart {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: flex-start;
                    div:nth-child(1) {
                        width: 90%;
                        height: 100%;
                        @include flexprob;
                        overflow: visible !important;
                        .highcharts-credits {
                            display: none;
                        }
                    }
                    .highcharts-container
                        .highcharts-data-labels
                        .highcharts-label
                        text
                        .highcharts-text-outline {
                        text-align: center;
                        font-size: 20px;
                        font-weight: 800;
                        line-height: 24px;
                        color: $title-85;
                    }
                }
                &__chartLabel {
                    @include flexprob;
                    color: $gray-4;
                    text-align: center;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 12px;
                    letter-spacing: 0.56px;
                    @include themify($themes) {
                        color: themed('headingColor') !important;
                    }
                }
            }
            &__fuelToSteam {
                width: 50%;
                height: 100%;
                @include flexprob;
                flex-direction: column;
                gap: 20%;
                &__chart {
                    width: 90%;
                    height: 100%;
                    @include flexprob;
                    div:nth-child(1) {
                        width: 100%;
                        @include flexprob;
                        height: 100%;
                        overflow: visible !important;
                        .highcharts-credits {
                            display: none;
                        }
                    }
                    .customGauge-label {
                        text-align: center;
                        font-size: 20px;
                        font-weight: 800;
                        line-height: 24px;
                        color: $title-85;
                    }
                    .highcharts-container
                        .highcharts-data-labels
                        .highcharts-label
                        text
                        .highcharts-text-outline {
                        text-align: center;
                        font-size: 20px;
                        font-weight: 800;
                        line-height: 24px;
                        color: $title-85;
                    }
                }
                &__chartLabel {
                    @include flexprob;
                    color: $gray-4;
                    text-align: center;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 12px;
                    letter-spacing: 0.56px;
                    @include themify($themes) {
                        color: themed('headingColor') !important;
                    }
                }
            }
        }
    }
}
