@import '../../../../../styles/global-settings.scss';

.formulaCreation {
    display: flex;
    gap: 10px;
    height: 100%;
    &__leftPanel {
        height: 100%;
        overflow-y: scroll;
        width: 242px;
    }
    &__rightPanel-rightOperators {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        gap: 8px;
        position: relative;
        .clear-all {
            position: absolute;
            right: 0;
            @include flexprob;
            background-color: transparent;
            border: none;
            cursor: pointer;
            &__text {
                color: $red-5;
                padding: 1px;
            }
        }
    }
    &__rightPanel {
        width: calc(100% - 230px);
        display: flex;
        flex-direction: column;
    }
    &__rightPanel-rightInput {
        border-radius: 8px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        height: 65%;
        background: #fcfcfd;
        width: 100%;
        @include flexprob;
        @include themify($themes) {
            background: themed('bodyBackgroundColor');  
            color: themed('headingColor') !important;
    }
    }
    &__rightPanel-rightPreview {
        height: 25%;
        background-color: transparent;
        margin-top: 1%;
        width: 100%;
        display: flex;
        .rightPreviewContent {
            padding: 1%;
            height: 100%;
            background-color: aliceblue;
            width: 100%;
            @include themify($themes) {
                background: themed('bodyBackgroundColor');  
                color: themed('headingColor') !important;
                border: 1px solid themed('borderDarkColor') !important;  
        }
            span {
                word-wrap: break-word;
            }
        }
    }
}
.clearAllContainer {
    position: absolute;
    right: 0;
    bottom: 20px;
    width: 100%;
    .clearAllOverlay {
        position: absolute;
        inset: 420px auto auto 1158.85px !important;
        .ant-popover-content {
            .ant-popover-inner {
                padding: 0;
            }
        }
    }
}
