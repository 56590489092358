@import '../../../../styles/global-settings.scss';
.adapter.ant-select-dropdown {
    width: 29% !important;
}

.adapterList__content.addMapping {
    overflow-y: scroll;
    height: calc(100vh - 400px);
}
.adapterList__footer.addMapping {
    padding: 25px 40px;
}
.mappingFieldsTable th.ant-table-cell:last-child {
    background-color: rgba(240, 244, 255, 1);
    border-left: 1px solid rgba(217, 217, 217, 1);
}
.mappingFieldsTable td.ant-table-cell:last-child {
    border-left: 1px solid rgba(217, 217, 217, 1);
}
.testMapping-btn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
}
input#braboTableName {
    width: 320px;
}
.clearAll {
    justify-content: end;
    align-items: center;
    display: flex;
    .ant-btn {
        color: red;
        border: 1px solid red;
    }
}

.testMapping {
    display: flex;
    justify-content: center;
}
.testMappingContainer {
    padding: 50px;
}
input.ant-input.fieldInput {
    border: none !important;
}
.fieldInput {
    padding: 0px !important;
}
input.ant-input.fieldOutput {
    border: none !important;
}
.fieldOutput {
    padding: 0px !important;
    background-color: white !important;
    color: black !important;
}

.mappedOutputField thead.ant-table-thead th.ant-table-cell {
    background: rgba(240, 244, 255, 1);
}

.mappedOutputField,
thead {
    border: 1px solid rgba(217, 217, 217, 1);
}

.braboTableOption .ant-select-item-option-content {
    display: flex;
    flex-direction: column !important;
    justify-content: flex-start !important;
    align-items: flex-start !important;
}
.viewEdit {
    display: flex;
    justify-content: flex-end;
}

.mappingStep.braboTableName {
    .ant-select-arrow {
        display: none !important;
    }
}
.operationFormCard {
    width: 100%;
    padding: 24px;
    @include themify($themes) {
        background-color: themed('bodyBackgroundColor') !important;
        border-color: themed('borderDarkColor') !important;
    }
}
.addOperationTag {
    display: flex;
    float: inline-end;
    cursor: pointer;
}
.addOperationDisabledTag {
    display: flex;
    float: inline-end;
}
.operationFormCardLabel {
    padding-bottom: 8px;
    @include themify($themes) {
        color: themed('headingColor') !important;
    }
}
.operationInputCol {
    display: flex;
    padding: 0px 24px !important;
    .ant-form-item {
        width: 100%;
    }
    .ant-form-item-label {
        width: 50%;
    }
    .ant-form-item-control {
        width: 90%;
    }
    .ant-form-item-row {
        flex-direction: row !important;
    }
    .ant-row {
        flex-flow: nowrap;
    }
    .ant-picker {
        width: 100%;
    }
}
.operationLastCol {
    display: flex;
    padding: 0px 0px 0px 4px !important;
    gap: 8px;
    .ant-input-number {
        width: 50px !important;
    }
}
.operationCheckboxCol {
    display: flex;
    padding: 0px 0px 0px 24px !important;
}
.labelCol {
    padding-top: 6px;
    @include themify($themes) {
        color: themed('textColor') !important;
    }
}
.operationFrequencyValue {
    .ant-input-number-input {
        @include themify($themes) {
            color: themed('textColor') !important;
            background-color: themed('bodyBackgroundColor') !important;
        }
    }
    .ant-input-number-handler-up {
        @include themify($themes) {
            background-color: themed('bodyBackgroundColor') !important;
            border: 1px solid themed('borderDarkColor') !important;
        }
        svg {
            @include themify($themes) {
                color: themed('svgColor') !important;
                stroke: themed('svgColor') !important;
            }
        }
        path {
            @include themify($themes) {
                fill: themed('svgColor') !important;
            }
        }
    }
    .ant-input-number-handler-down {
        @include themify($themes) {
            background-color: themed('bodyBackgroundColor') !important;
            border: 1px solid themed('borderDarkColor') !important;
        }
        svg {
            @include themify($themes) {
                color: themed('svgColor') !important;
                stroke: themed('svgColor') !important;
            }
        }
        path {
            @include themify($themes) {
                fill: themed('svgColor') !important;
            }
        }
    }
}
.datePicker {
    @include themify($themes) {
        border-color: themed('borderDarkColor') !important;
    }
    .ant-picker-input input::placeholder {
        @include themify($themes) {
            color: themed('textColor') !important;
        }
    }
}
.occurencesInput{
    @include themify($themes) {
        background-color: themed('bodyBackgroundColor') !important;
        color: themed('textColor') !important;

    }
}

.disabledCheckbox {
    .ant-checkbox-inner {
        background-color: $gray-1 !important;
        border: 1px solid $neutral-5 !important;
    }
}
.saveBtn{
    width: 86% !important;
}