@import '../../../styles/colorVariable';
@import '../../../styles/global-settings.scss';

.qrCodeConfigWrapper{
    .custom-apply {
        color: #153ad1 !important;
        background: #ffffff !important;
    }
    .custom-apply:hover {
        background: #fff !important;
    }
    .timeCapsuleTableWrapper__subItems{
    color:$volcano-5;
    }
    .ant-drawer-content-wrapper{
        width: 360px!important;
    }
    .ant-table-body {
        overflow-x: unset !important;
    }
    .ant-select {
        width: 100%;
    }
    .ant-select-selector {
        width: 100%;
        border-radius: 2px;
    }
    .ant-picker {
        width: 100%;
        border-radius: 2px;
    }
    .ant-input {
        width: 100%;
        border-radius: 2px;
    }
    &__search {
        width: 100%;
        height: 40px;
        border: none !important;
        border-radius: 8px !important;
        background: #f6f7f9 !important;
        input.ant-input {
            background: $gray-3;
        }
        span {
            color: $title-45;
        }
    }
}
.qrCodeConfigWrapper__filterHeader {
    padding: 0px 0px 8px 0px;
    gap: 16px;
}
.qrCodeConfig__noData {
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: auto;
    transform: translate(-50%, -50%);
}
.qrCodeConfigWrapper__rowHeader {
    display: grid;
    grid-template-columns: 2fr 2fr 3fr 1fr 1fr 170px;
    grid-column-gap: 8px;
    align-items: center;
}

.customButton.custom-new.connections {
    width: 70% !important;
    margin-right: 0px !important;
}

.bulk-importbtn .EmptyDataComponent__buttons__customButton {
    justify-content: center;
}
.connectionWrapper__button {
    .custom-new.connections {
        width: 100% !important;
    }
}

.validateModal {
    width: 40% !important;
    .ant-modal-header {
        padding: 24px;
        background-color: $header-background;
    }
    .ant-modal-title {
        font-weight: 500;
        font-size: 16px;
    }
    .validatedModalText {
        padding: 24px !important;
    }
    .validatedModalErrorText {
        padding: 24px !important;
    }
}

.qrCodeConfig.attributeWrapper .ant-table-body,
.ant-input-affix-wrapper .ant-table-body {
    overflow-x: unset !important;
}
.qrCodeConfig {
    ::-webkit-scrollbar {
        width: 4px;
        height: 10px !important;
    }
}

.attributeWrapper__attributeList {
    overflow: unset !important;
}
.connections {
    .ant-modal-title {
        visibility: hidden;
    }
    .ant-modal-header {
        background-color: #fff !important;
    }
}

.validateTagModal.validateModal.confirmationModal.connections {
    width: 509px !important;
}
.validateModal.connections .validatedModalErrorText {
    padding: 0px 0 15px 0 !important;
}
.popoverWidth {
    max-width: 400px;
    word-wrap: break-word;
}
.closeOutlinedIcon {
    justify-content: end;
}