@import '../../../../styles/global-settings.scss';

.ant-modal-root .ant-modal-mask {
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(7.5px);
}
.confirmationModal.save {
    width: 509px !important;
}
.confirmationModal.delete {
    width: 593px !important;
}
.confirmationModal {
    width: 509px !important;
    .ant-modal-content {
        padding: 0px !important;
        @include themify($themes) {
            background: themed('modalBackgroundColor') !important;
            border: 1px solid themed('borderDarkColor') !important;
            border-color: themed('borderDarkColor') !important;
            .ant-modal-body span {
                @include themify($themes) {
                    color: themed('headingColor') !important;
                }
            }
        }
        .ant-modal-body {
            @include flexprob;
            padding: 52px 0px 22px 0px;
            flex-direction: column;
            gap: 20px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            span {
                @include textfontstyle;
                color: $title-85;
                text-align: center;
                width: 355px;
                @include themify($themes) {
                    color: themed('headingColor') !important;
                }
            }
        }
        .ant-modal-close {
            top: 26px !important;
        }
        .ant-modal-footer {
            @include textfontstyle;
            text-align: center;
            margin-top: 0px !important;
            padding: 24px 0px;
            @include themify($themes) {
                border-color: themed('borderDarkColor') !important;
                border: 1px solid themed('borderDarkColor') !important;
            }

            .ant-btn-primary {
                padding: 4px 0px;
                width: 109px;
            }
            .ant-btn-default {
                width: 109px;
            }
        }
    }
    .ant-modal-close-x{
        @include themify($themes) {
            color: themed('svgColor') !important;
        }
    }
}

.ant-switch .ant-switch-inner {
    width: 44px !important;
}

.ant-switch .ant-switch-loading-icon.anticon {
    width: auto !important;
    line-height: 0px !important;
}
.confirmationModal.blaDeactivation .ant-modal-content .ant-modal-body span {
    width: 400px;
}
span.noActiveDevice {
    display: block;
    width: 400px;
    font-size: 18px;
}
.confirmationModal.calendarCancelModal .ant-modal-footer .ant-btn-primary {
    border-color: $red-3;
    color: $red-3;
    background-color: $neutral-3;
}
.switchSpan {
    padding-left: 10px;
}
.confirmationModal.attributeImplementationModal
    .ant-modal-content
    .ant-modal-body{
        color: $black-7;
        @include themify($themes) {
            color: themed('headingColor') !important;
        }
        span {
            width: 360px;
    }
  
}
.confirmationModal.timeCapsulAssignModal
    .ant-modal-content
    .ant-modal-body
    span {
    width: 432px;
}
.confirmationModal.tableImplementationModal
    .ant-modal-content
    .ant-modal-body
    span {
    width: 360px;
}
.confirmationModal.modelDeletion .ant-modal-content .ant-modal-body span {
    width: 400px;
    
}
.confirmationModal.modelDeletion .ant-modal-footer .ant-btn-primary {
    border-color: $red-3;
    color: $red-3;
    background-color: $neutral-3;
}