@import '../../../../styles/fontStyles';
@import '../../../../styles/mixins';
@import '../../../../styles//colorVariable';
@import '../../../../styles/global-settings.scss';
.tagMapping {
    width: 100%;
    background: white;
    @include themify($themes) {
        background: themed('bodyBackgroundColor') !important;
 }
    &__headerWrapper {
        padding: 21px 24px;
    }
    &__tabsWrapper {
        @include textfontstyle;
        margin-top: 16px;
    }
    .ant-tabs-nav-wrap {
        margin-left: 40%;
        @include themify($themes) {
            background: themed('bodyBackgroundColor') !important;
     }
    }
    .ant-tabs-nav {
        margin: 0px 0px 0px 24px;
    }
    .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: $primary-6;
    }
    .ant-tabs-ink-bar {
        background-color: $primary-6;
    }
    .ant-tabs-tab:hover {
        color: $primary-6;
    }
}
.leftSection {
    display: flex;
    flex-direction: column;

    height: calc(100vh - 258px);

    gap: 4px;
    padding: 4px;
    &__assetGroupCard {
        flex: 1;
        max-height: 35%;
        @include themify($themes) {
            background: themed('bodyBackgroundColor') !important;
            color: themed('headingColor') !important;
            border: 1px solid themed('borderDarkColor') !important;
     }
        .ant-card-body {
            padding: 0px;
        }
    }
    &__tagsCard {
        flex: 1;
        height: 60%;
        max-height: 60%;
        overflow: hidden;
        @include themify($themes) {
            background: themed('bodyBackgroundColor') !important;
            color: themed('headingColor') !important;
            border: 1px solid themed('borderDarkColor') !important;
     }
        .ant-card-body {
            padding: 0px !important;
            .customPaginationStatic {
                overflow: auto;
            }
        }
    }
}
.tags {
    &__header {
        padding: 12px 12px 0px 12px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    &__blaDeviceDropdown {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0 12px;
    }
    &__searchBox {
        margin-top: 12px;
        height: 32px;
        width: 98%;
        margin-left: 8px;
        .ant-input {
            height: 22px;
            bottom: 8px;
            width: 80%;
        }
        .ant-input-suffix svg {
            color: black;
            height: 16px;
            width: 16px;
            color: $disabled-text;
            svg {
                @include themify($themes) {
                    color: themed('svgColor') !important;
                    stroke: themed('svgColor') !important;
                }
            }
            path {
                @include themify($themes) {
                    fill: themed('svgColor') !important;
                }
            }
        }
        span.ant-input-suffix {
            margin-left: 28px;
            margin-top: 4px;
        }
    }
    &__selectBla {
        width: 50%;
        .ant-select-arrow {
            padding-bottom: 6px;
        }
    }
    &__dataTypeDropdown {
        width: 168px;
        .ant-select-selector {
            height: 28px !important;
            border-radius: 2px;
        }
        span.ant-select-selection-placeholder {
            font-weight: 400 !important;
            padding-bottom: 4px !important;
        }
    }
}
.ant-divider.ant-divider-horizontal.tags__divider {
    margin: 12px 0;
}
.tagOverview {
    margin-top: 4px;
    .ant-table-body {
        height: 63px !important;
        overflow: scroll !important;
    }
    ::-webkit-scrollbar {
        width: 10px;
        height: 4px;
    }
}

.tagMappingLeftCards {
    height: 100%;
}
.tagTable {
    margin-top: 4px;
    ::-webkit-scrollbar {
        width: 10px;
        height: 4px;
    }
}
.deviceSearchBox {
    margin-bottom: 8px;
    width: 200px;
}
.mapTagsBtn {
    border-color: $orange-1;
    color: $orange-1;
    background-color: $reset-background;
}
.filterList {
    display: flex;
    flex-direction: row;
}
.editIcon {
    cursor: pointer;
    margin-top: 4px;
}
.editableRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.tagTable {
    &__search {
        input.ant-input {
            height: 16px;
            margin-top: -4px;
        }
        width: 209px !important;
        height: 32px !important;
        border: none !important;
        border-radius: 8px !important;
        .ant-select-selector,
        .ant-input-outlined:focus,
        .ant-input-outlined {
            border-color: #5c79ef !important;
        }
        .ant-input-outlined:hover {
            background: $neutral-6 !important;
            border: none !important;
            width: 300px !important;
        }
        .ant-select-selector::before {
            content: url('/assets/icons/searchIcon.svg');
            font-size: 5px !important;
            margin-left: 4px !important;
        }
        span {
            color: $title-45 !important;
        }
        span.ant-select-selection-placeholder {
            margin-left: 20px !important;
        }
        .ant-select-arrow {
            display: none !important;
        }
        &:hover input.ant-input,
        &:hover .ant-select-selector {
            border: none !important;
            width: 300px !important;
        }

        /* Focus effect */
        &:focus-within input.ant-input,
        &:focus-within .ant-select-selector {
            border: none !important;
            width: 300px !important;
        }
    }
}
.filter {
    margin-top: 6px;
    cursor: pointer;
}
.tagTable .ant-table-body {
    height: calc(100vh - 350px);
}
.searchBox {
    display: flex;
    flex-direction: column;
}
.ant-select-selector {
    height: 28px;
    border-radius: 2px;
}
.inputSearchBox{
    height: 24px;
    border-radius: 2px;
}