@import './../../../../styles/global-settings.scss';

.react-flow__node-default,
.react-flow__node-input,
.react-flow__node-output {
    padding: 3px !important;
    background: #fff8f3 !important;
    color: black !important;
    font-size: 16px !important;
    line-height: 20px !important;
    border-color: #f86806 !important;
}
.react-flow__minimap svg {
    @include themify($themes) {
        background: themed('bodyBackgroundColor') !important;
 }
}
.react-flow__attribution {
    @include themify($themes) {
        background: themed('bodyBackgroundColor') !important;
 }
}
 .react-flow__attribution a{
    @include themify($themes) {
        color: themed('headingColor') !important;
 }
}
.react-flow__controls-button{
    @include themify($themes) {
        background: themed('bodyBackgroundColor') !important;
        border: 1px solid themed('borderDarkColor') !important;
 }
 svg {
    @include themify($themes) {
        color: themed('svgColor') !important;
        stroke: themed('svgColor') !important;
    }      
}
path {
    @include themify($themes) {
        fill: themed('svgColor') !important;
    }
}
}
.react-flow__handle {
    background-color: $progress-border !important;
    border-color: white;
}
.react-flow__pane{
    cursor: default !important;
}
.react-flow__edge{
    cursor: default !important;
}
.modellingTab {
    .modellingContainer {
        height: calc(100vh - 243px);
        border: 1px solid #f0f0f0;
        position: relative;
        overflow: hidden;

        .canvas-tree {
            height: 100%;
            width: 100%;
            background-color: #ffffff;
            margin: 8px;
            box-sizing: border-box;
        }
    }

    .buttonTabContainer {
        display: flex;
        flex-direction: row-reverse;
        height: 40px;
        background: #ffffff;
        box-shadow: inset 0px -1px 0px #f0f0f0;
        padding: 0 12px 0 0 !important;

        .buttonContainer {
            margin: 4px 0 4px 8px;
            width: 136px;
            height: 32px;
            background: #ffffff;
            border: 1px solid #d9d9d9;
            box-sizing: border-box;
            box-shadow: 0 2px 0 rgba(0, 0, 0, 0.016);
            border-radius: 2px;
        }

        Button {
            display: flex;
            align-items: center;
            color: rgba(0, 0, 0, 0.45);
        }

        .savebtn {
            display: flex;
            align-items: center;
            width: 84px;
            height: 32px;
            margin: 4px 0 4px 8px;
            border: 1px solid #d9d9d9;
            box-sizing: border-box;
            box-shadow: 0 2px 0 rgba(0, 0, 0, 0.016);
            border-radius: 2px;
            color: white;
        }
    }

    .title-color-light-gray {
        color: rgba(0, 0, 0, 0.65);
    }
}

.antDrawerAssetModel {
    position: absolute;

    .ant-drawer-content-wrapper {
        width: 351px !important;
    }

    .ant-drawer-body {
        padding: 0;
    }

    .ant-form-item {
        margin: 0 0 8px;
    }

    .anticon svg,
    output {
        display: inline-block;
        margin-bottom: 4px;
    }

    .ant-collapse {
        background: white;
    }

    .ant-collapse-header {
        background: #fafafa;
    }

    .ant-drawer-header {
        background: #fafafa;
    }
}
.modellingDrawer {
    position: absolute;
    width: 720px;
    height: 689px;
    right: 0px;
    bottom: 0px;
    background: $neutral-1;
    box-shadow: -9px 0px 22px rgba(0, 0, 0, 0.1);
    border-radius: 8px 0px 0px 8px;
    &__primaryModel {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0 12px;
        color: $gray-8;
        margin-top: 4px;
    }
    &__divider {
        margin: 8px 0;
        width: calc(100% + 48px);
        margin-left: -24px;
    }
    &__form {
        height: calc(100vh - 405px);
        max-height: calc(100vh - 406px);
    }
    &__inputField {
        border-radius: 2px;
    }
    &__icon {
        color: $title-45;
        float: right;
            svg {
        @include themify($themes) {
            color: themed('svgColor') !important;
            stroke: themed('svgColor') !important;
        }      
    }
    path {
        @include themify($themes) {
            fill: themed('svgColor') !important;
        }
    }
    }
    &__heading {
        span {
            @include textfontstyle;
            color: $title-85;
            @include themify($themes) {
                color: themed('headingColor') !important;
         } 
        }
    }
    &__button {
        position: absolute;
        bottom: 30px;
        width: 100%;
        left: 0;
    }
    &__footerButtons {
        justify-content: center;
        col {
            margin-right: 10px;
        }
    }
    &__createBtn {
        padding-left: 4px;
    }
    &__cancelButton {
        padding-right: 4px;
    }
}
.reactFlow__nodes {
    height: calc(100vh - 245px);
    width: 100%;
}
.orientationIcon {
    display: flex;
    flex-direction: row;
    margin-left: 12px;
}
.verticalOrientation {
    height: 30px;
    width: 30px;
    margin-top: 12px;
    cursor: pointer;
    svg{    
        @include themify($themes) {
        color: themed('svgColor') !important;
    }}
    path{
        @include themify($themes) {
            fill: themed('bacgroundBlack') !important;
            stroke: themed('svgColor') !important;
        }  
    }
}
.verticalActiveOrientation {
    height: 34px;
    width: 30px;
    cursor: pointer;
    margin-top: 8px;
    svg{    
        @include themify($themes) {
        color: themed('hoverColor') !important;
    }}
    path{
        @include themify($themes) {
            fill: themed('bacgroundBlack') !important;
            stroke: themed('hoverColor') !important;
        }  
    }
}
.horizontalOrientation {
    height: 30px;
    width: 30px;
    margin-top: 12px;
    cursor: pointer;
    svg{    
        @include themify($themes) {
        color: themed('svgColor') !important;
    }}
    path{
        @include themify($themes) {
            fill: themed('bacgroundBlack') !important;
            stroke: themed('svgColor') !important;
        }  
    }
}
.horizontalActiveOrientation{
    height: 30px;
    width: 30px;
    cursor: pointer;
    margin-top: 12px;
    svg{    
        @include themify($themes) {
        color: themed('hoverColor') !important;
    }}
    path{
        @include themify($themes) {
            fill: themed('bacgroundBlack') !important;
            stroke: themed('hoverColor') !important;
        }  
    }
}
.reactflow {
    height: calc(100vh - 245px);
    width: 100%;
}
.hideField {
    display: none;
}
.pushToCloud {
    color: $gray-8;
    margin-top: 4px;
    font-size: 14px;
    font-weight: 400;
}
.scrollable-content {
    height: calc(100vh - 434px);
    max-height: calc(100vh - 435px);
    overflow-y: auto;
    overflow-x: hidden;
}
