@import '../../../../../styles/global-settings.scss';
.customPopOverContent {
    width: 100%;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    &__container {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    &__title {
        padding: 24px;
        span {
            font-size: 16px;
            font-weight: 500;
        }
    }
    &__subText {
        padding: 24px;
        span {
            font-size: 12px;
            font-weight: 400;
            color: #00000073;
            @include themify($themes) {
                color: themed('headingColor') !important;
         }
        }
    }
    .ant-input-affix-wrapper {
        height: auto;
        margin: 16px;
        border: 1px solid #d9d9d9;
        width: 88%;
        textarea {
            width: 205px;
            min-height: 96px;
            padding: 0px !important;
        }
    }
    &__footerButtons {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 64px;
        gap: 16px;
    }
}
