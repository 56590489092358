@import '../../../../styles/colorVariable';

.newRowBtn {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    &__btn {
        margin-right: 10px;
    }
}

.editable-row .ant-table-cell .ant-form-item-has-error {
    border: 1px solid $red-3;
    border-radius: 2px;
}
.ant-table-thead > tr > th.editableColumn {
    padding-left: 26px;
}
.tableRowDetails {
    .ant-modal-content {
        width: 600px;
    }
}

.validateTable {
    .ant-table {
        overflow: hidden;
    }
}
.inputValue{
    .ant-input-affix-wrapper.ant-input-affix-wrapper {
        height: 33px !important;
        padding: 0 !important;
    }
}