@import '../../../styles/colorVariable';
@import '../../../styles/global-settings.scss';

.viewAttributeWrapper {
    gap: 10px;
    padding: 12px;
    height: calc(100vh - 145px);
    .viewUomCard {
        height: 100%;
        @include themify($themes) {
            background: themed('bodyBackgroundColor') !important;
        }
    }
    &__headerWrapper {
        padding: 20px;
    }
    &__createRolesList {
        padding-top: 12px;
        height: calc(100vh - 367px);
    }
    &__createRoleContent2 {
        padding: 20px;
    }

    &__heading {
        display: flex;
    }

    &__headingName {
        @include textfontstyle;
        color: $title-85;
    }

    &__backIcon {
        width: 30px;
        height: 32px;
        padding: 5px 0 0 8px;
    }

    &__createRowHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 8px 20px 10px 20px;
    }

    &__button {
        .ant-btn {
            padding: 0px;
        }
        width: 131px;
        height: 32px;
    }

    &__search {
        width: 300px;
        height: 40px;
        background: $gray-3;
        border: none;
        border-radius: 8px;
        input.ant-input {
            background: $gray-3;
        }
        span {
            color: $title-45;
        }
    }
    &__createRolesContent {
        display: flex;
        width: 100%;
        min-height: calc(100vh - 417px);
    }
    &__createRolesDropdownContent {
        width: 30%;
        border-right: 0.5px solid $border;
        padding: 10px 30px 10px 30px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        position: fixed;
    }
    &__createRolesTableContent {
        width: 70%;
        padding: 10px 30px 10px 30px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-left: auto;
    }
    &__createRolesDropdownCollapse {
        display: flex;
        flex-direction: column;
        gap: 10px;
        overflow-y: scroll;
        height: 430px;
    }
    &__buttonGroup {
        border: 1px solid $primary-4;
        color: $primary-4;
        border-radius: 4px;
        width: 100%;
    }
    &__buttonGroupIcon {
        color: $primary-4;
        size: 12px;
    }

    &__createRolesTableHeader {
        color: $title-45;
        font-weight: bold;
        display: flex;
    }
    &__createRolesSwitch {
        width: 28px;
    }
    &__createRolesTableContentRow {
        width: 100%;
    }
    &__createRolesTypographySwitch {
        width: 50%;
        text-align: center;
    }
    &__createRolesTypography {
        width: 100%;
        text-align: left;
    }
    &__createRolesSwitch {
        width: 50%;
        align-items: center;
    }
    &__createRoleFooter {
        @include flexprob;
        background: $neutral-1;
        width: 100%;
        padding: 31px 24px 31px 24px;
        z-index: 1;
        gap: 8px;
        border-top: 0.5px solid $border;
    }
    &__footerButtonWrapper {
        width: 110px;
    }
    &__createRoleList {
        background-color: $neutral-7;
        padding: 0px 12px;
        .ant-list-item {
            padding: 12px 0px;
        }
    }
    &__scrollContent {
        height: calc(100vh - 330px);
        overflow-y: scroll;
    }
    &__saveButton {
        margin-bottom: 0px;
        width: 100% !important;
    }
}

.viewAttributeWrapper__header {
    display: grid;
    grid-template-columns: 1fr 133px 124px;
    grid-column-gap: 8px;
    align-items: center;
}

.mandatoryClass {
    color: $red-3;
}

.view__loader {
    width: 100vw;
}

.uomEditFooter {
    padding: 31px 24px 31px 24px !important;
}
