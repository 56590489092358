@import '../../../../styles/global-settings.scss';

.ant-modal-root .ant-modal-mask {
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(7.5px);
}
.successfulModal.deviceActivated {
    .ant-modal-content .ant-modal-body span {
        width: 60%;
        text-align: center;
    }
}
.successfulModal {
    width: 509px !important;
    .ant-modal-content {
        padding: 0px !important;
        .ant-modal-body {
            @include flexprob;
            padding: 52px 0px 68px 0px;
            flex-direction: column;
            gap: 20px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            span {
                @include textfontstyle;
                color: $title-85;
                @include themify($themes) {
                    color: themed('headingColor') !important;
                }
            }
        }
        .ant-modal-close {
            top: 26px !important;
        }
        .ant-modal-footer {
            @include textfontstyle;
            text-align: center;
            margin-top: 0px !important;
            padding: 24px 0px;
            @include themify($themes) {
                border-color: themed('borderDarkColor') !important;
                border: 1px solid themed('borderDarkColor') !important;
            }
        }
    }
    .ant-modal-close-x{
        @include themify($themes) {
            color: themed('svgColor') !important;
        }
    }
}
