@import '../../../../styles/colorVariable';
@import '../../../../styles/global-settings.scss';

.groupDrawer__wrapper {
    height: 100%;
    .groupDrawer {
        &__Input {
            border-radius: 2px;
        }
        &__noData {
            @include flexprob;
            height: 39dvh;
        }
        &__buttons {
            display: flex;
            flex-direction: row;
            padding: 30px 20px;
            margin-top: 50px;
        }
        &__listItem {
            list-style: none;

            .ant-checkbox {
                margin-left: 18px;
            }
            margin-top: 10px;
        }

        &__selectAll {
            margin-bottom: 8px;
        }

        &__subItems {
            background: $neutral-7;
            @include themify($themes) {
                background: themed('bodyCardBackgroundColor') !important;
                color: themed('textColor') !important;
                border: 1px solid themed('borderDarkColor') !important;
                border-radius: 8px;
            }
            width: 330px;
            height: 56px;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 18px;
            padding: 20px 2px;
            .ant-checkbox {
                padding: 0px 10px;
                margin-right: 10px;
            }
        }
        &__labelContainer {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        &__label {
            width: 100px;
        }
        &__editLink {
            color: $primary-6;
            cursor: pointer;
            margin-left: 168px;
            width: 50px;
        }
        &__search {
            height: 34px;
            background-color: $neutral-6;
            border-color: $neutral-6;
            padding: 12px 0px;
            .ant-input {
                background-color: $neutral-6;
            }
            .ant-input-prefix svg {
                color: black;
                height: 18px;
                width: 18px;
                color: $disabled-text;
            }
            svg {
                @include themify($themes) {
                    color: themed('svgColor') !important;
                    stroke: themed('svgColor') !important;
                }
            }
            svg {
                @include themify($themes) {
                    fill: themed('svgColor') !important;
                }
            }
        }
    }
    .ant-input-affix-wrapper.groupDrawer {
        background: $neutral-6;
        height: 32px;

        input.ant-input {
            background: $neutral-6;
            height: 22px;
            bottom: 5px;
        }
        span {
            color: $title-45;
            height: 20px;
        }
    }

    .list-container {
        height: calc(100vh - 460px);
        overflow-y: auto;
        .ant-divider-horizontal {
            margin: 16px 0;
        }
        .ant-tree-list-holder {
            @include themify($themes) {
                background: themed('bodyCardBackgroundColor') !important;
                color: themed('svgColor') !important;
            }
        }
    }
    .button {
        position: absolute;
        bottom: 30px;
        width: 100%;
        left: 0;
    }
    .footerButtons {
        justify-content: center;
        col {
            margin-right: 10px;
        }
    }
    .custom-save {
        margin-left: 10px;
    }
    .empty-description {
        color: $title-45;
    }
    .editIcon {
        margin-right: 5px;
    }

    .checkBox-group {
        display: block;
        margin-top: 20px;
    }
}
.kpiHeading {
    font-size: 16px;
    font-weight: 500;
    margin-left: 12px;
}
.kpiCollapsePanel {
    .ant-collapse-header {
        background-color: white;
    }
    .ant-collapse-content.ant-collapse-content-active {
        background-color: $neutral-3;
    }
    .ant-collapse-content-box {
        padding: 0 0 !important;
    }
    .ant-collapse-item {
        margin-top: 12px;
    }
}
.ant-checkbox-group.kpiItemGroup {
    width: 100% !important;
}
.kpiListItem {
    width: 100% !important;
    .ant-divider-horizontal {
        margin-bottom: 0 !important;
    }
}
.noResultFoundText {
    @include flexprob;
    margin-top: 24%;
    @include themify($themes) {
        color: themed('svgColor') !important;
    }
}

.ant-tree-treenode.ant-tree-treenode-disabled span {
    @include themify($themes) {
        color: #606162 !important;
    }
}

.ant-tree
    .ant-tree-checkbox.ant-tree-checkbox-checked.ant-tree-checkbox-disabled {
    @include themify($themes) {
        .ant-tree-checkbox-inner {
            background-color: blue !important;
            border-color: blue !important;
        }
        &:after {
            border-color: wheat;
        }
    }
}

.ant-tree
    .ant-tree-checkbox-disabled.ant-tree-checkbox-indeterminate
    .ant-tree-checkbox-inner::after {
    @include themify($themes) {
        background-color: blue !important;
    }
}

.ant-collapse .ant-collapse-item .ant-collapse-header {
    @include themify($themes) {
        background: themed('tableHeader') !important;
        color: themed('svgColor') !important;
        border-radius: 0 !important;
    }
}

.kpiCollapse{
    width: 100%;
    margin-top: 10px;
}
