@import '../../../../../../styles/global-settings.scss';
.functionsListing {
    height: 80%;
    margin-top: 5px;
    width: 100%;
    overflow-y: scroll;
    &__container {
        display: flex;
        flex-direction: column;
        &__title {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 8px;
            hr {
                width: 100%;
                background-color: $title-35;
            }
            span {
                color: $title-35;
                font-size: 10px;
                line-height: 22px;
                font-weight: 500;
                @include themify($themes) {
                    color: themed('textColor');
                }
            }
        }
        &__buttons {
            display: flex;
            flex-wrap: wrap;
            gap: 4px;
            .function-button {
                padding: 4px 8px;
                border-radius: 2px;
                border: 1px solid rgba(66, 101, 204, 0.2);

                background-color: rgba(66, 101, 204, 0.03);
                span {
                    color: rgba(66, 101, 204, 1);
                }
            }
        }
    }
}
.emptyFunctionListing {
    height: 80%;
    margin-top: 5px;
    width: 100%;
    display: flex;
    align-items: center;
    .EmptyDataComponent {
        width: 100%;
    }
}
.function-tooltip {
    display: flex;
    flex-direction: column;
}
