@import '../../../../styles/global-settings.scss';

.kpiGridViewWrapper {
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 15px 12px;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: wrap;
    @include themify($themes) {
        border: 1px solid themed('borderDarkColor') !important;
 } 
    &__cardGridContainer {
        width: 10%;
    }
    &__cardGrid {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
        width: 100%;
    }
    .ant-picker-outlined {
        @include themify($themes) {
            background: themed('bodyBackgroundColor') !important;
            border: 1px solid themed('borderDarkColor') !important;
            color: themed('headingColor') !important;
     } 
    }
    &__toggle {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 4px;
    }
    &__activeToggle {
        color: rgba(0, 0, 0, 1);
        @include themify($themes) {
            color: themed('headingColor') !important;
     }
    }
    &__inactiveToggle {
        color: rgba(0, 0, 0, 0.25);
        @include themify($themes) {
            color: themed('headingColor') !important;
     }
    }
    &__heading {
        font-family: Inter;
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: 0em;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        @include themify($themes) {
            color: themed('headingColor') !important;
     }
    }
    &__state {
        color: #926800;
        background-color: #ffe4a1;
        font-family: Inter;
        font-size: 8px;
        font-weight: 400;
        line-height: 10px;
        letter-spacing: 0em;
        text-align: center;
        padding: 3px;
        border-radius: 4px;
        width: 100%;
    }
    &__kpi {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 6px;
        @include themify($themes) {
            color: themed('headingColor') !important;
     }
    }
    &__attribute {
        display: flex;
        justify-content: flex-start;
        align-items: baseline;
        gap: 6px;
        @include themify($themes) {
            color: themed('headingColor') !important;
     }
    }
    &__dropdownWrapper {
        display: flex;
        gap: 15px;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
    }
    &__copyWrapper {
        display: flex;
        gap: 15px;
        justify-content: flex-end;
        align-items: center;
        flex-wrap: wrap;
    }
    &__headingKpi {
        font-family: Inter;
        font-size: 12px;
        font-weight: 500;
        line-height: 12px;
        letter-spacing: 0em;
    }
    &__icon {
        // height: 10px;
        // width: 10px;
        font-family: Inter;
        font-size: 12px;
        font-weight: 400;
        line-height: 4px;
        letter-spacing: 0em;
    }
    &__headingAttribute {
        font-family: Inter;
        font-size: 12px;
        font-weight: 500;
        line-height: 12px;
        letter-spacing: 0em;
    }
    &__viewMore {
        font-family: Inter;
        font-size: 8px;
        font-weight: 500;
        line-height: 10px;
        letter-spacing: 0em;
        color: #2f54eb;
        cursor: pointer;
    }
    &__copyToClipBoard,
    .ant-btn-default:hover,
    .ant-btn-default:focus {
        background-color: white;
        color: rgba(0, 0, 0, 0.85) !important;
        border: 1px solid rgba(0, 0, 0, 0.85) !important;
        border-radius: 6px;
        @include themify($themes) {
            background: themed('bodyBackgroundColor') !important;
            color: themed('headingColor') !important;
            border: 1px solid themed('borderDarkColor') !important;
     }
    }
    &____validateButton {
        background: #2f54eb;
    }
}
