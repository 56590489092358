@import '../../../styles/colorVariable';
@import '../../../styles/global-settings.scss';

.operationWrapper__noData {
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: auto;
    transform: translate(-50%, -50%);
}

.customButton.custom-new.connections {
    width: 70% !important;
    margin-right: 0px !important;
}

.bulk-importbtn .EmptyDataComponent__buttons__customButton {
    justify-content: center;
}
.operationWrapper__button {
    display: flex;
    justify-content: end;
    .custom-new.connections {
        width: 100% !important;
    }
}

.operationWrapper__addButton {
    display: flex;
    justify-content: end;
    margin-left: 30px;
    .custom-new.connections {
        width: 100% !important;
    }
}

.operationWrapper__textButton {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 10px;
    cursor: pointer;
    span:hover {
        color: $primary-9;
    }
    @include themify($themes) {
        color: themed('headingColor') !important;
    }
}

.validateModal {
    width: 40% !important;
    .ant-modal-header {
        padding: 24px;
        background-color: $header-background;
    }
    .ant-modal-title {
        font-weight: 500;
        font-size: 16px;
    }
    .validatedModalText {
        padding: 24px !important;
        // max-width: 92%;
       //  color: $success-green-text;
    }
    .validatedModalErrorText {
        padding: 24px !important;
        // max-width: 92%;
        // color: $red-8;
    }
}

.operationWrapper.attributeWrapper .ant-table-body,
.ant-input-affix-wrapper .ant-table-body {
    overflow-x: unset !important;
}
.operationWrapper {
    ::-webkit-scrollbar {
        width: 4px;
        height: 10px !important;
    }
}

.attributeWrapper__attributeList {
    overflow: unset !important;
}

.attributeWrapper__filterHeader {
    padding: 0px 0px 8px 0px;
    gap: 10px;
    .ant-select {
        width: 100%;
    }
    .ant-select-selector {
        width: 100%;
        border-radius: 2px;
    }
    .ant-picker {
        width: 100%;
        border-radius: 2px;
    }
    .ant-input {
        width: 100%;
        border-radius: 2px;
    }
}

.attributeWrapper__filterAdvHeader {
    padding: 0px 0px 0px 0px;
    gap: 10px;
    .ant-select {
        width: 100%;
    }
    .ant-select-selector {
        width: 100%;
        border-radius: 2px;
    }
}
.monitoringDivToolTip .ant-tooltip-inner {
    height: 100px;
    width: 300px;
    overflow-y: scroll;
    color: $title-85;
}

.monitoringToolTip .ant-tooltip-inner {
    color: $title-85;
}

.cascaderDropdown {
    width: 200px !important;
    .ant-select-dropdown {
        width: 200px !important;
    }
}

.operationWrapper__button {
    width: 164px;
    float: inline-end;
    @include themify($themes) {
        color: themed('textColor') !important;
    }
}

.mappingMultiplePopover.ant-popover-placement-bottomLeft {
    width: 130px;
    .ant-popover-arrow {
        left: 0px !important;
        top: -11px !important;
    }
    .ant-popover-content {
        left: -13px !important;
        top: -11px !important;
    }
}
.mappingMultiplePopover.ant-popover-placement-topLeft {
    width: 130px;
    .ant-popover-arrow {
        left: 0px !important;
        bottom: -11px !important;
    }
    .ant-popover-content {
        left: -13px !important;
        top: 11px !important;
    }
}
.popoverWidth {
    max-width: 400px;
    word-wrap: break-word;
}
.moreSync {
    display: flex;
    gap: 10px;
}