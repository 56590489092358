@import '../../../../styles/colorVariable';
@import '../../../../styles/global-settings.scss';

.createRolesWrapper {
    .ant-input-affix-wrapper.ant-input-affix-wrapper {
        width: 204px !important;
    }
    gap: 10px;
    padding: 12px;
    @include themify($themes) {
        background: themed('bacgroundBlack') !important;
    }
    &__header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .ant-card .ant-card-body {
        @include themify($themes) {
            background: themed('bodyBackgroundColor') !important;
            border: 1px solid themed('borderDarkColor') !important;
            border-color: themed('borderDarkColor') !important;
            border-radius: 8px;
        }
    }
    &__headerWrapper {
        padding: 10px 10px 10px 15px;
        border-bottom: 0.5px solid $border;
        @include themify($themes) {
            border-bottom: 0.5px solid themed('borderDarkColor') !important;
        }
    }
    &__createRolesList {
        padding-top: 12px;
        height: calc(100vh - 367px);
    }
    &__createRoleContent2 {
        padding: 20px;
    }

    &__heading {
        display: flex;
    }
    &__backIcon {
        width: 30px;
        height: 32px;
        padding: 8px 0 0 8px;
        svg {
            @include themify($themes) {
                stroke: themed('stroke') !important;
            }
        }
    }

    &__createRowHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 8px 20px 10px 20px;
        border-bottom: 0.5px solid $border;
        @include themify($themes) {
            border-color: themed('borderDarkColor') !important;
            border: 0.5px solid themed('borderDarkColor') !important;
        }
    }

    .ant-collapse > .ant-collapse-item {
        margin: 10px 0px;
        background-color: rgba(0, 0, 0, 0.02);
        border: 1px solid #d9d9d9;

        border-radius: 8px;
    }

    .ant-collapse {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        color: rgba(0, 0, 0, 0.88);
        font-size: 14px;
        list-style: none;
        background-color: white !important;
        border: 0px !important;
        border-bottom: 0 !important;
        border-radius: 0px !important;
        box-shadow: none !important;
        @include themify($themes) {
            background: themed('bodyCardBackgroundColor') !important;
        }
    }

    &__button {
        .ant-btn {
            padding: 0px;
        }
        width: 131px;
        height: 32px;
    }

    &__search {
        width: 300px;
        height: 40px;
        background: $gray-3 !important;
        @include themify($themes) {
            background: themed('bodyCardBackgroundColor') !important;
            border-color: themed('borderDarkColor') !important;
            border: 1px solid themed('borderDarkColor') !important;
        }
        border: none;
        border-radius: 8px;

        input.ant-input {
            background: $gray-3 !important  ;
            @include themify($themes) {
                background: themed('bodyCardBackgroundColor') !important;
            }
        }
        span {
            color: $title-45;
        }
        svg {
            @include themify($themes) {
                color: themed('svgColor') !important;
                stroke: themed('svgColor') !important;
            }
        }
        svg {
            @include themify($themes) {
                fill: themed('svgColor') !important;
            }
        }
    }
    &__createRolesContent {
        display: flex;
        width: 100%;
        min-height: calc(100vh - 417px);
    }
    &__createRolesDropdownContent {
        width: 30%;
        border-right: 0.5px solid $border;
        padding: 10px 30px 10px 30px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        position: fixed;
    }

    &__createRolesTableContent {
        padding: 10px 30px 10px 30px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        height: calc(100vh - 450px);
        overflow-y: scroll;
    }
    &__createRolesDropdownCollapse {
        display: flex;
        flex-direction: column;
        gap: 10px;
        overflow-y: scroll;
        height: 236px;
    }
    &__buttonGroup {
        border: 1px solid $primary-4;
        color: $primary-4;
        border-radius: 4px;
        width: 100%;
    }
    &__buttonGroupIcon {
        color: $primary-4;
        size: 12px;
    }

    &__createRolesTableHeader {
        color: $title-45;
        font-weight: bold;
        display: flex;
    }
    &__createRolesSwitch {
        width: 28px;
    }
    &__createRolesTableContentRow {
        width: 100%;
    }
    &__createRolesTypographySwitch {
        width: 50%;
        text-align: center;
    }
    &__createRolesTypography {
        width: 100%;
        text-align: left;
    }
    &__createRolesSwitch {
        width: 50%;
        align-items: center;
    }
    &__createRoleFooter {
        @include flexprob;
        background: $neutral-1;
        width: 100%;
        padding: 12px 24px 16px 24px;
        z-index: 1;
        gap: 8px;
        border-top: 0.5px solid $border;
        @include themify($themes) {
            background: themed('bodyBackgroundColor') !important;
            border: 1px solid themed('borderDarkColor') !important;
            border-color: themed('borderDarkColor') !important;
        }
    }
    &__footerButtonWrapper {
        width: 110px;
    }
    &__createRoleList {
        background-color: $neutral-7;
        padding: 0px 12px;
        @include themify($themes) {
            background: themed('bodyCardBackgroundColor') !important;
            border-color: themed('borderDarkColor') !important;
            border: 1px solid themed('borderDarkColor') !important;
        }
        .ant-list-item {
            padding: 12px 0px;
        }
    }
    &__createRolesTableContentRowHeader {
        padding: 0px 12px;
    }
    &__createRoleContent3 {
        @include flexprob;
        flex-direction: column;
        height: 100%;
        width: 100%;
    }
    &__createRoleInnerContent {
        display: flex;
        width: 60%;
        gap: 20px;
        margin: 10px;
    }
    &__createRoleInnerContentText {
        width: 70%;
        text-align: end;
    }
    &__scrollContent {
        height: calc(100vh - 358px);
    }
    .ant-typography-secondary {
        @include themify($themes) {
            color: themed('headingColor') !important;
        }
    }
    .ant-collapse-header {
        @include themify($themes) {
            color: themed('headingColor') !important;
        }
    }
    .ant-input-affix-wrapper {
        background: $gray-3 !important;
        width: 185px;
    }
}

// .f {
//     display: grid;
//     grid-template-columns: 1fr 133px 124px;
//     grid-column-gap: 8px;
//     align-items: center;
// }

.mandatoryClass {
    color: $red-3;
}
.createRoles {
    display: flex;
    flex-direction: row;
    &__card {
        height: calc(100vh - 358px);
        @include themify($themes) {
            background: themed('bodyCardBackgroundColor') !important;
        }
    }

    &__tabs {
        display: flex;
        justify-content: center;
        align-items: center;
        label.ant-radio-button-wrapper {
            @include themify($themes) {
                background: themed('bodyCardBackgroundColor') !important;
                border-color: themed('borderDarkColor') !important;
                border: 1px solid themed('borderDarkColor') !important;
                color: themed('svgColor') !important;
            }
        }
        label.ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
            @include themify($themes) {
                border-color: themed('brandColor') !important;
                border: 1px solid themed('brandColor') !important;
                color: themed('brandColor') !important;
            }
        }
    }
    &__addGroup {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    &__search {
        width: 174px;
        height: 32px;
        background: $gray-3;
        @include themify($themes) {
            background: themed('bodyCardBackgroundColor') !important;
            border-color: themed('borderDarkColor') !important;
            border: 1px solid themed('borderDarkColor') !important;
        }
        border: none;
        border-radius: 8px;
        input.ant-input {
            background: $gray-3;
        }
        span {
            color: $title-45;
        }
        svg {
            @include themify($themes) {
                color: themed('svgColor') !important;
                stroke: themed('svgColor') !important;
            }
        }
        svg {
            @include themify($themes) {
                fill: themed('svgColor') !important;
            }
        }
    }
    &__emptyComponent {
        display: flex;
        justify-content: center;
        align-items: center;
        height: calc(100vh - 400px);
    }
}
.addBtn {
    .customButton {
        background: transparent;

        box-shadow: none;
        border: none;
        span {
            color: $primary-4;
        }
        svg {
            stroke: $primary-4 !important;
        }
        &:hover {
            background-color: transparent !important;
        }
        @include themify($themes) {
            background-color: transparent !important;
        }
    }
}
.listItems {
    height: calc(100vh - 470px);
    overflow-y: scroll;
}
.resourceTypeHeading {
    @include themify($themes) {
        color: themed('svgColor') !important;
    }
}
.noResultFound {
    @include flexprob;
    margin-top: 24%;
    @include themify($themes) {
        color: themed('svgColor') !important;
    }
    font-size: 12px;
}
.editRoleBtn {
    justify-content: flex-end;
    display: flex;
}
.editBtn {
    width: 35%;
}
.customButton.custom-edit {
    background: transparent;
    border-color: blue;

    span {
        color: blue;
    }
}
.viewRolesCard {
    @include themify($themes) {
        background: themed('bodyCardBackgroundColor') !important;

    }
}
