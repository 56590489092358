@import '../../../styles/colorVariable';
@import '../../../styles/global-settings.scss';

.providerForm {
    .ant-form-item-label {
        width: 35%;
        text-align: left;
    }
    // height: calc(100vh - 310px);
    // overflow: auto;
    // overflow-x: hidden;
    &__card {
        height: calc(100vh - 420px);
        overflow: auto;
    }

    .ant-select.ant-select-in-form-item {
        width: 100%;
    }
    .ant-form-item-label >label::after{
        display: none;
    }
    label.ant-form-item-required:after {
        display: none;
    }
    .providerForm__container {
        margin: 0 auto;
        // height: calc(100vh - 200px);
        // overflow-y: scroll;
    }

    &__footer{
      padding-bottom: 24px;
      @include themify($themes) {
        background: themed('bodyBackgroundColor') !important;
 }
    }
    &__btn {
        justify-content: center;
        display: flex;
    }

    .checkbox-ssl {
        padding: 24px 0;
    }

    .providerForm__logo {
        text-align: center;
        padding: 20px 0px 20px 0;
        img{
            width: 199px;
            object-fit: contain;
            max-width: 100%;
            height: auto;
        }
    }
    .checkbox{
        margin-left: 13px;
    }
    span.error-asterisk {
        display: inline-block;
        margin-inline-end: 6px;
        color: #ff4d4f;
        font-size: 16px;
        font-family: SimSun, sans-serif !important;
        line-height: 1;
        position: absolute;
        left: 1%;
        top: 43%;
    }
    .ant-input-affix-wrapper.ant-input-affix-wrapper {
        height: 32px !important;
        padding: 0 10px;
    }
}

div#label {
    border: 1px solid $border;
    border-radius: 5px;
    padding-left: 4px;
}
.ant-input-outlined.labelInput {
    border: none !important;
    width: 100%;
}
.ant-input-outlined:focus.ant-input-outlined:focus-within.labelInput {
    box-shadow: none !important;
}
.ant-input-affix-wrapper.ant-input-affix-wrapper {
    height: 40px !important;
}
.adapterConfirmPassord {
    color: $red-4;
}

.disabled {
    // background: $disabled-bg;
    cursor: not-allowed;
    height: 32px;
}
