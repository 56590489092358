@import '../../../../styles/colorVariable';
@import '../../../../styles/global-settings.scss';

.adapterList {
    .ant-card-body {
        padding: 0px !important;
    }
    &__backIcon {
        cursor: pointer;
        svg {
            @include themify($themes) {
                color: themed('svgColor') !important;
                stroke: themed('svgColor') !important;
            }      
        }
        path {
            @include themify($themes) {
                fill: themed('svgColor') !important;
            }
        }
    }
    &__header {
        display: flex;
        gap: 20px;
        padding: 27px;
        align-items: center;
        border-bottom: 1px solid $border;
        @include themify($themes) {
            border-bottom: 1px solid themed('borderDarkColor') !important;
            }
    }
    &__title {
        color: $title-85;
        @include themify($themes) {
            color: themed('headingColor') !important;
     }
    }
    &__content {
        overflow-y: scroll;
        height: calc(100vh - 345px);
    }
    &__viewContent {
        overflow-y: scroll;
        height: calc(100vh - 295px);
        padding: 24px;
    }
    &__footer {
        display: flex;
        padding: 5px 40px;
        justify-content: center;
        border-top: 1px solid $border;
    }
    .adapterList__buttons {
        gap: 20px;
        width: 30%;
        @include flexprob;
        margin: auto;
    }
    &__content {
        padding: 24px;
    }
.ant-input.fieldInput{
    @include themify($themes) {
        border: 1px solid themed('borderDarkColor') !important;
}
}
.ant-input.fieldOutput{
    @include themify($themes) {
        border: 1px solid themed('borderDarkColor') !important;
}
}
}
.adapterCard__container {
    @include flexprob;
    flex-wrap: wrap;
    .ant-card-body {
        width: 40%;
    }
}
.attributeWrapper.adapterList .ant-card,
.ant-input-affix-wrapper .ant-card {
    height: auto !important;
    margin: 0px !important;
}

.adapter__group.ant-radio-group {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 30px;
}

.adapter__card {
    .adpater__cardContent {
        display: flex;
        padding: 20px;
    }
    .selected {
        border: 1px solid rgba(46, 83, 234, 1);
    }
    .nameSelected {
        color: rgba(46, 83, 234, 1);
    }
    .adapter__cardDetails {
        width: 95%;
        .desc {
            @include themify($themes) {
                color: themed('headingColor') !important;
                }
        }
    }
    .circle.normal-circle {
        display: flex;
        align-items: center;
        gap: 20px;
        color: $title-85;
        img {
            @include flexprob;
            background: $neutral-1;
            border-radius: 50%;
            box-sizing: border-box;
            height: 64px;
            width: 64px;
            border: 1px solid $border;
            padding: 10px;
        }
        .desc {
            padding-top: 15px;
            color: $title-65;
        }
        .tile {
            flex-wrap: wrap;
            @include themify($themes) {
                color: themed('headingColor') !important;
                }
        }
        .tilenameSelected{
            @include themify($themes) {
                color: themed('hoverColor') !important;
                }  
        }
    }
}

.ant-card.ant-card-bordered.ant-card-hoverable.adapter__card.disabled {
    cursor: not-allowed;
}

.validatedModalTextError {
    padding: 24px !important;
    /* max-width: 92%; */
    // color: $red-8;
}
.okBtn {
    width: 109px;
    background-color: $blue;
}
.buttonInfo {
    padding-top: 30px;
}
