@import '../../../styles/colorVariable';
@import'../../../styles/global-settings.scss';
.assetsGroup {
    padding: 12px 0px 0px 12px;
    height: calc(100vh - 150px);
    @include themify($themes) {
        background: themed('bodyBackgroundColor') !important;
 }
    .ant-card {
        height: 100%;
        margin: auto;
        border-radius: 0%;
        margin-right: 2px;
    }
    .ant-card-body {
        height: 100%;
        padding: 0px;
        height: 100%;
    }
    &__search {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 10px 25px 12px;
        align-items: center;
    }
    &__searchHeading {
        font-size: 16px;
        font-weight: 400;
    }
    &__notFound {
        margin-left: 5px;
    }
    &__searchBox {
        height: 32px;
        background-color: $neutral-6;
        border-color: $neutral-6;
        .ant-input {
            background-color: $neutral-6;
            height: 22px;
            bottom: 8px;
            width: 80%;
        }
        .ant-input-prefix svg {
            color: black;
            height: 16px;
            width: 16px;
            color: $disabled-text;
        }
    }
    .ant-input-affix-wrapper {
        width: 90%;
        margin: 10px 16px;
    }
    .ant-divider {
        margin: 0;
    }
    &__selectModel {
        padding: 12px 16px;
    }
    &__tree {
        padding: 0 12px;
        height: 82%;
        overflow-y: scroll;
    }
    &__searchBoxCloseIcon {
        cursor: pointer;
    }
}
.assetsNode {
    margin-right: 5px;
    vertical-align: middle;
}
